import ApiService from "../../services/ApiService";
import {PagingAndSortingParam, pagingQuery} from "../ApiUtil";
import {Content} from "../ContentApi";

class RoomApi {
    static getRoomTypes(param: PagingAndSortingParam) {
        const queryParams = pagingQuery(param)
        const path = `/rooms/room-types${queryParams}`
        return ApiService.get(path, true)
    }

    static getRoomType(roomTypeId: number) {
        const path = `/rooms/room-types/${roomTypeId}`
        return ApiService.get(path, true)
    }

    static getRoomTypeNames() {
        const path = `/rooms/room-types/names`
        return ApiService.get(path, true)
    }

    static deleteRoomType(roomTypeId: number) {
        const path = `/rooms/room-types/${roomTypeId}`
        return ApiService.delete(path, true)
    }

    static getRooms(param: PagingAndSortingParam, roomTypeId?: number) {
        let queryParams = pagingQuery(param)

        if (roomTypeId) {
            queryParams += `&roomTypeId=${roomTypeId}`
        }

        const path = `/rooms${queryParams}`;
        return ApiService.get(path, true)
    }

    static getRoomDetail(roomId: number) {
        const path = `/rooms/${roomId}`
        return ApiService.get(path, true)
    }

    static createRoomType(
        accommodationId: number,
        typeName: string,
        typeCategory: string,
        standardNumberOfPeople: string,
        maxNumberOfPeople: string,
        additionalFeeAdult: string,
        additionalFeeChild: string,
        additionalFeeBaby: string,
        roomSize: string,
        numberOfLivingRoom: string,
        numberOfRoom: string,
        numberOfBathroom: string,
        description: string,
        priceIds: number[],
        basicFacilityIds: number[],
        paidFacilityIds: number[]
    ) {
        const path = `/rooms/room-types`
        const body = {
            accommodationId,
            name: typeName,
            type: typeCategory,
            standardNumberOfPeople,
            maxNumberOfPeople,
            additionalFeeAdult,
            additionalFeeChild,
            additionalFeeBaby,
            roomSize,
            numberOfLivingRoom,
            numberOfRoom,
            numberOfBathroom,
            description,
            priceIds,
            basicFacilityIds,
            paidFacilityIds,
        }
        return ApiService.post(path, body, true)
    }

    static updateRoomType(
        roomTypeId: number,
        typeName: string,
        typeCategory: string,
        standardNumberOfPeople: string,
        maxNumberOfPeople: string,
        additionalFeeAdult: string,
        additionalFeeChild: string,
        additionalFeeBaby: string,
        roomSize: string,
        numberOfLivingRoom: string,
        numberOfRoom: string,
        numberOfBathroom: string,
        description: string,
        priceIds: number[],
        basicFacilityIds: number[],
        paidFacilityIds: number[]
    ) {
        const path = `/rooms/room-types/${roomTypeId}`
        const body = {
            name: typeName,
            type: typeCategory,
            standardNumberOfPeople,
            maxNumberOfPeople,
            additionalFeeAdult,
            additionalFeeChild,
            additionalFeeBaby,
            roomSize,
            numberOfLivingRoom,
            numberOfRoom,
            numberOfBathroom,
            description,
            priceIds,
            basicFacilityIds,
            paidFacilityIds,
        }
        return ApiService.patch(path, body, true)
    }

    static createRoom(
        accommodationId: number,
        roomName: string,
        roomTypeId: number,
        isDisplay: boolean) {
        const path = `/rooms`
        const body = {
            accommodationId,
            name: roomName,
            roomTypeId,
            displayYn: isDisplay ? 'Y' : 'N',
        }
        return ApiService.post(path, body, true)
    }

    static updateRoom(
        roomId: number,
        roomName: string,
        roomTypeId: number,
        isDisplay: boolean,
        contents: Content[],
    ) {
        const path = `/rooms/${roomId}`
        const body = {
            roomName,
            roomTypeId,
            displayYn: isDisplay ? 'Y' : 'N',
            roomContents: contents,
        }
        return ApiService.patch(path, body, true)
    }

    static deleteRoom(roomId: number) {
        const path = `/rooms/${roomId}`
        return ApiService.delete(path, true)
    }
}

export default RoomApi
