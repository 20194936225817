import {Typography} from "antd";
import {Color, TextStyle} from "../../constants/style";
import React from "react";

const TextLiner = ({enabled, textStyle, value}: {
    enabled: boolean,
    textStyle?: React.CSSProperties,
    value: string
}) => {
    textStyle = textStyle || {...TextStyle, fontSize: 18, fontWeight: 500}
    return (
        <Typography.Text style={{...textStyle, background: enabled ? Color.liner : ''}}>
            {value}
        </Typography.Text>
    )
}

export default TextLiner
