import React, {useEffect, useRef, useState} from "react";
import {RcFile} from "antd/es/upload";
import {Button, message, notification, Upload, UploadFile, UploadProps} from "antd";
import {PresignedImageType, requestPreSignedUrl} from "../../redux/Content";
import ContentApi from "../../apis/ContentApi";
import {Color, TextStyle} from "../../constants/style";
import {PlusOutlined} from "@ant-design/icons";
import {useDispatch} from "react-redux";

const PresignedUrlImageRegistration = ({imageType, registerImagePath, onCompletedUpload}: {
    imageType: PresignedImageType,
    registerImagePath: (imagePath: string) => Promise<{ response: { ok: boolean } }>,
    onCompletedUpload: () => void,
}) => {
    const dispatch = useDispatch()

    const [preSignedUrl, setPreSignedUrl] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([])

    useEffect(() => {
        if (preSignedUrl) {
            handleFileUpload(preSignedUrl)
        }
    }, [preSignedUrl])

    const uploadRef = useRef(null);
    const validateImageType = (file: RcFile) => file && (
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg');
    const handleBeforeUpload: UploadProps['beforeUpload'] = async (file, newFileList) => {
        if (!validateImageType(file)) {
            message.error(`${file.name} is not PNG file`);
            return false
        }

        // @ts-ignore
        const preSignedUrl = await dispatch(requestPreSignedUrl(imageType, file.name))
        // @ts-ignore
        setPreSignedUrl(preSignedUrl)
    }

    const handleFileChanged: UploadProps['onChange'] = ({file}) => {
        if (file.status === 'removed') {
            setFileList([])
        } else if (file.status === 'uploading') {
            setFileList([file])
        } else if (file.status === 'done') {
            onCompletedUpload()
        }
    };

    const handleFileUpload = async (preSignedUrl: string) => {
        const result = await ContentApi.upload(preSignedUrl, fileList[0].originFileObj as File)

        if (result.ok) {
            const path = new URL(preSignedUrl).pathname
            // const {response} = await ContentApi.createAccommodationContent('IMAGE', accommodationId, path)
            const {response} = await registerImagePath(path)
            if (response.ok && uploadRef && uploadRef.current) {
                // @ts-ignore
                uploadRef.current.onSuccess(null, fileList[0]);
            } else {
                message.error('파일 업로드에 실패했습니다.')
                setFileList([])
            }
        } else {
            message.error('파일 업로드에 실패했습니다.')
            setFileList([])
        }
    };

    return (
        <Upload
            ref={uploadRef}
            accept={"image/*"}
            fileList={fileList}
            customRequest={() => null}
            itemRender={() => null}
            beforeUpload={handleBeforeUpload}
            onChange={handleFileChanged}
        >
            <Button
                style={{
                    marginTop: 40,
                    height: 40,
                    backgroundColor: Color.y40,
                    borderColor: Color.y40,
                    borderRadius: 8,
                    justifyContent: 'center',
                    alignItems: 'center',
                    ...TextStyle,
                    fontSize: 18,
                    fontWeight: 700,
                    color: Color.darkPrimary,
                }}
                icon={<PlusOutlined/>}
            >이미지 추가</Button>
        </Upload>
    )
}

export default PresignedUrlImageRegistration
