import {combineReducers} from 'redux'

import * as User from './User'
import * as Content from './Content'
import * as Accommodation from './Accommodation'
import * as Loading from './Loading'
import * as Room from './Room'
import * as Reservation from './Reservation'
import * as Auth from './Auth'

const reducers = combineReducers({
    User: User.reducer,
    Content: Content.reducer,
    Accommodation: Accommodation.reducer,
    Loading: Loading.reducer,
    Room: Room.reducer,
    Reservation: Reservation.reducer,
    Auth: Auth.reducer,
});

export {
    reducers,
    User,
    Content,
    Accommodation,
    Loading,
    Room,
    Reservation,
    Auth,
};