import {API_DOMAIN, FRONT_URL, LOGIN_URL} from "../../constants/environment";


class NavigationService {
    static moveToLoginPage() {
        window.location.href = LOGIN_URL
    }

    static moveToNaverLoginPage() {
        window.location.href = `${API_DOMAIN}/oauth2/authorization/naver`
    }

    static moveToKakaoLoginPage() {
        window.location.href = `${API_DOMAIN}/oauth2/authorization/kakao`
    }

    static moveToMain() {
        window.location.href = FRONT_URL
    }

    static moveTo(path: string, navigateFunc?: any) {
        window.location.href = `${FRONT_URL}${path}`;
    }
}

export default NavigationService
