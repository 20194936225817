import {Button, Space} from "antd";
import {CSSProperties} from "react";
import {Color, TextStyle} from "../../constants/style";

interface Tab {
    key: string,
    label: string,
}

const RoundTabs = ({containerStyle, tabs, enabledTabKey, onChangeTab}: {
    containerStyle?: CSSProperties,
    tabs: Tab[],
    enabledTabKey: string,
    onChangeTab: (key: string) => void,
}) => {
    containerStyle = containerStyle || {marginTop: 20, marginBottom: 20}
    const enableTabStyle = (type: string) => enabledTabKey === type ? innerTopTabStyle : {
        ...innerTopTabStyle,
        backgroundColor: Color.white,
        borderColor: '#E5E5E7',
    }

    return (
        <Space style={containerStyle}>
            {
                tabs.map((tab, index) => (
                    <Button
                        key={tab.key}
                        style={enableTabStyle(tab.key)}
                        onClick={() => onChangeTab(tab.key)}
                    >{tab.label}</Button>
                ))
            }
        </Space>
    )
}

const innerTopTabStyle: CSSProperties = {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.y40,
    borderColor: Color.y40,
    borderRadius: 40,
    ...TextStyle,
    fontWeight: 500,
    fontSize: 16,
    color: Color.darkPrimary,
}

export default RoundTabs
