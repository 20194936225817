// @ts-nocheck

export const env = (map: any) => map[process.env.REACT_APP_PROFILE]

const _LOCAL_API_DOMAIN = 'http://localhost:8080'
const _DEV_API_DOMAIN = 'https://dev-api.staytwice.com'
const _PROD_API_DOMAIN = 'https://api.staytwice.com'

const _API_DOMAIN = {
    local: _LOCAL_API_DOMAIN,
    dev: _DEV_API_DOMAIN,
    prod: _PROD_API_DOMAIN,
}

const _API_URL = {
    local: `${_LOCAL_API_DOMAIN}/manager`,
    dev: `${_DEV_API_DOMAIN}/manager`,
    prod: `${_PROD_API_DOMAIN}/manager`,
};

const _FRONT_URL = {
    local: 'http://localhost:3000',
    dev: 'https://dev-manager.staytwice.com',
    prod: '',
}

export const FRONT_URL = (function () {
    return env(_FRONT_URL)
})()

export const API_DOMAIN = (function () {
    return env(_API_DOMAIN)
})()

export const API_URL = (function () {
    return env(_API_URL)
})()

export const LOGIN_URL = (function () {
    return FRONT_URL + '/login'
})()
