import {Col, Row} from "antd";
import React from "react";
import {isMobile} from 'react-device-detect';

const CenterGrid = ({...props}) => {
    const innerStyle = isMobile ? {
        paddingTop: '5rem',
        paddingLeft: '0.8rem',
        paddingRight: '0.8rem',
        ...props.innerStyle
    } : {
        paddingTop: '5rem',
        paddingLeft: '2.5rem',
        paddingRight: '2.5rem',
        ...props.innerStyle
    }
    return (
        <Row>
            <Col xs={0} xl={4}></Col>
            <Col xs={24} xl={16}>
                <div style={innerStyle}>
                    {props.children}
                </div>
            </Col>
            <Col xs={0} xl={4}></Col>
        </Row>
    )
}

export default CenterGrid;
