// @ts-nocheck

const AUTH_TOKEN_COOKIE_NAME = 'AuthToken'

class CookieService {

    static initAccessToken() {
        document.cookie = `${AUTH_TOKEN_COOKIE_NAME}=; Max-Age=-99999999;`;
    }

    static getAccessToken() {
        return this.get(AUTH_TOKEN_COOKIE_NAME);
    }

    static setAccessToken(token: string) {
        this.set('AuthToken', token, 600)
    }

    static setRefreshToken(refreshToken: string) {
        this.set('RefreshToken', refreshToken, 3600)
    }

    static set(name, value, maxAgeInSeconds) {
        const expires = maxAgeInSeconds ? `; Max-Age=${maxAgeInSeconds}` : '';
        document.cookie = `${name}=${value}${expires}; path=/; secure; SameSite=Lax`;
    }

    static get(name: string) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);

        if (!parts || parts.length !== 2) {
            return null;
        }

        const part = parts.pop();

        if (!part) {
            return null;
        }

        return part.split(';').shift() || null;
    }
}

export default CookieService;
