import ScreenGrid from "../../../components/ScreenGrid";
import ScreenOuter from "../../../components/ScreenOuter";
import {Button, Space, Tabs, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import React, {useEffect, useState} from "react";
import RoomInfo from "./RoomInfo";
import RoomTypeInfo from "./RoomTypeInfo";
import {useDispatch, useSelector} from "react-redux";
import {requestRooms, requestRoomTypeList} from "../../../redux/Room";
import {PagingAndSortingParam} from "../../../apis/ApiUtil";
import {PlusOutlined} from "@ant-design/icons";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import withPolicies from "../../../components/withPolicies";

const RoomMainScreen = () => {
    const dispatch = useDispatch();

    // @ts-ignore
    const roomTypes = useSelector(state => state.Room.get('roomTypes'));
    // @ts-ignore
    const rooms = useSelector(state => state.Room.get('rooms'));

    // @ts-ignore
    const roomTotalPages = useSelector(state => state.Room.get('roomTotalPages'));
    // @ts-ignore
    const roomTotalElements = useSelector(state => state.Room.get('roomTotalElements'));
    // @ts-ignore
    const roomCurrentPage = useSelector(state => state.Room.get('roomCurrentPage'));

    const [tabs, setTabs] = useState<{ id: number, name: string }[]>([])
    const [enabledTab, setEnabledTab] = useState('ALL')

    useEffect(() => {
        // @ts-ignore
        dispatch(requestRoomTypeList());
        // @ts-ignore
        dispatch(requestRooms())
    }, []);

    useEffect(() => {
        // @ts-ignore
        setTabs(roomTypes.map(roomType => ({
            id: roomType.id,
            name: roomType.name,
        })))
    }, [roomTypes])


    useEffect(() => {
        requestRoomsApi()
    }, [enabledTab])

    const onChangePage = (page: number, size: number) => requestRoomsApi({page, size})

    const requestRoomsApi = (pagingAndSortingParam?: PagingAndSortingParam) => {
        let roomTypeId: number = 0;
        const tab = tabs.filter(tab => tab.name === enabledTab).pop()
        if (tab) {
            roomTypeId = tab.id
        }

        if (!pagingAndSortingParam) {
            pagingAndSortingParam = {page: 0, size: 5}
        }

        // @ts-ignore
        dispatch(requestRooms(roomTypeId, pagingAndSortingParam));
    }

    return (
        <ScreenOuter>
            <ScreenGrid>
                <Header/>
                <TopTabs
                    RoomInfo={
                        <RoomInfo
                            roomPage={{
                                totalPages: roomTotalPages,
                                totalElements: roomTotalElements,
                                currentPage: roomCurrentPage,
                            }}
                            rooms={rooms}
                            roomTypes={roomTypes}
                            tabs={tabs}
                            enabledTab={enabledTab}
                            onChangeEnabledTab={(name: string) => setEnabledTab(name)}
                            onChangePage={onChangePage}
                        />}
                    RoomTypeInfo={<RoomTypeInfo roomTypes={roomTypes}/>}
                />
            </ScreenGrid>
        </ScreenOuter>
    )
}

const Header = () => {
    return (
        <Space style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={2} style={{...TextStyle}}>객실 관리</Typography.Title>
            <Button
                icon={<PlusOutlined/>}
                style={{
                    width: 135,
                    height: 50,
                    backgroundColor: Color.black5,
                    borderColor: Color.black5,
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...TextStyle,
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: 16,
                }}
                // @ts-ignore
                onClick={() => NavigationService.moveTo(PageRoutes.REGISTER_ROOM)}
            >새 객실 추가</Button>
        </Space>
    )
}

const TopTabs = ({RoomInfo, RoomTypeInfo}: {
    RoomInfo: React.ReactNode,
    RoomTypeInfo: React.ReactNode,
}) => {
    const TabLabel = ({label}: { label: string }) => <div style={{width: 120, textAlign: 'center'}}>{label}</div>
    const tabs: any = [
        {
            key: "0",
            label: <TabLabel label={'객실'}/>,
            children: RoomInfo,
        },
        {
            key: "1",
            label: <TabLabel label={'객실 타입'}/>,
            children: RoomTypeInfo,
        },
    ]

    return (
        <Tabs
            style={{marginTop: 20}}
            tabBarStyle={{...TextStyle, fontWeight: 500, fontSize: 18, color: Color.darkPrimary}}
            defaultActiveKey={"0"}
            items={tabs}
        />
    )
}

export default withPolicies(RoomMainScreen)
