import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {configureStore} from "@reduxjs/toolkit";
import logger from 'redux-logger'

import {reducers} from "./redux";
import {ConfigProvider} from "antd";

import ko from 'antd/locale/ko_KR'
import dayjs from 'dayjs';
import 'dayjs/locale/ko'

const store = configureStore({
    reducer: reducers,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(logger),
    devTools: process.env.NODE_ENV !== 'production',
})
const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

dayjs.locale('ko')


root.render(
    <Provider store={store}>
        <ConfigProvider locale={ko}>
            <App/>
        </ConfigProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
