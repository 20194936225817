import ApiService from "../../services/ApiService";


class AuthApi {

    static login(params: any) {
        const path = '/auth/login';
        const body = {
            username: params.username,
            password: params.password,
        };

        return ApiService.post(path, body, false);
    }

    static signUp(params: any) {
        const path = '/auth/sign-up';
        const body = {
            username: params.username,
            password: params.password,
        };

        return ApiService.post(path, body, false);
    }
}

export default AuthApi;
