const setLocalStorage = (key: string, value: any) => {
    localStorage.setItem(key, JSON.stringify(value));
};

const getLocalStorage = (key: string) => {
    const item = localStorage.getItem(key)

    if (item && item !== '') {
        return JSON.parse(item)
    }

    return null
}

class LocalStorageService {

    static saveAccommodationName(accommodationName: string) {
        setLocalStorage('accommodation-name', accommodationName)
    }

    static findAccommodationName(): string {
        return getLocalStorage('accommodation-name')
    }

    static saveSearchReservationParam(startDate: string, endDate: string, createdDate: string, nameOrPhoneNumber: string) {
        setLocalStorage('search-reservation-param', JSON.stringify({
            startDate,
            endDate,
            createdDate,
            nameOrPhoneNumber
        }))
    }

    static findSearchReservationParam() {
        const param = getLocalStorage('search-reservation-param')
        return param ? JSON.parse(param) : {}
    }
}

export default LocalStorageService
