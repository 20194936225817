import React, {CSSProperties} from "react";
import {UserOutlined} from "@ant-design/icons";

import {TextStyle} from "../../constants/style";
import NavigationService from "../../services/NavigationService";
import {PageRoutes} from "../../App";
import NoBorderButton from "../NoBorderButton";

const RightMenu = ({mode, containerStyle,}: { mode?: any, containerStyle?: CSSProperties }) => {
    containerStyle = containerStyle || {}
    const style = {
        width: 100,
        ...TextStyle,
        fontSize: 20,
        fontWeight: 700,
        ...containerStyle,
    }
    return (
        <NoBorderButton
            style={style}
            value={'내 정보'}
            icon={<UserOutlined style={{float: 'left', marginTop: 4, marginRight: 0}}/>}
            onClick={() => NavigationService.moveTo(PageRoutes.MY_PAGE)}
        />
    )
};

export default RightMenu;