import React, {useEffect} from "react";

import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {Button, Col, Input, message, Modal, notification, Radio, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import AccommodationPrice from "./AccommodationPrice";
import {requestAccommodations} from "../../../redux/Accommodation";
import {useDispatch, useSelector} from "react-redux";
import AccommodationBasicFacility from "./AccommodationBasicFacility";
import AccommodationPaidFacility from "./AccommodationPaidFacility";
import {requestDeleteRoomType, requestRoomTypeDetail} from "../../../redux/Room";
import {moneyValue} from "../../../utils/string";
import {useLocation, useNavigate} from "react-router-dom";
import TextLiner from "../../../components/TextLiner";
import NoBorderButton from "../../../components/NoBorderButton";
import {PageRoutes} from "../../../App";
import NavigationService from "../../../services/NavigationService";
import withPolicies from "../../../components/withPolicies";


const RoomTypeScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modal, contextHolder] = Modal.useModal()

    const location = useLocation()
    const roomTypeId = location?.state?.id

    // @ts-ignore
    const accommodationBasicFacilities = useSelector(state => state.Accommodation.get('basicFacilities'))
    // @ts-ignore
    const accommodationPaidFacilities = useSelector(state => state.Accommodation.get('paidFacilities'))

    // @ts-ignore
    const roomTypeDetail = useSelector(state => state.Room.get('roomTypeDetail'))

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
        // @ts-ignore
        dispatch(requestRoomTypeDetail(roomTypeId))
    }, [])

    function makeSelectedIds(basicFacilities: any[]) {
        if (!basicFacilities) {
            return []
        }

        return basicFacilities.map((basicFacility: any) => basicFacility.id)
    }

    const selectedBasicFacilityIds = makeSelectedIds(roomTypeDetail?.basicFacilities)
    const selectedPaidFacilityIds = makeSelectedIds(roomTypeDetail?.paidFacilities)

    const topButtonStyle = {
        backgroundColor: Color.r5, color: Color.r40, width: 66, height: 50, borderRadius: 16, fontWeight: 700,
    }

    const onPressDeleteRoomTypeDetail = async () => {
        modal.confirm({
            title: '객실 타입을 삭제하시겠습니까?',
            okText: '삭제',
            cancelText: '취소',
            onOk: deleteRoomTypeDetail,
        })
    }

    const deleteRoomTypeDetail = async () => {
        // @ts-ignore
        const result = await dispatch(requestDeleteRoomType(roomTypeId))

        if (result.success) {
            notification.success({
                message: '객실 타입 삭제에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ROOMS),
            })
        } else {
            modal.error({
                title: '객실 타입을 삭제할 수 없습니다.',
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    const onPressUpdateRoomTypeDetail = () => {
        navigate(PageRoutes.EDIT_ROOM_TYPE, {
            state: {
                roomTypeDetail,
                accommodationBasicFacilities,
                accommodationPaidFacilities,
            }
        })
    }

    return (
        <ScreenOuter>
            <ScreenGrid>
                <Header/>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Space style={{position: 'absolute', right: 0, alignItems: 'center', justifyItems: 'center'}}>
                            <NoBorderButton style={topButtonStyle} value={'삭제'} onClick={onPressDeleteRoomTypeDetail}/>
                            <NoBorderButton style={{...topButtonStyle, color: Color.darkPrimary}} value={'수정'}
                                            onClick={onPressUpdateRoomTypeDetail}/>
                        </Space>
                    </Col>
                </Row>

                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                타입 이름
                            </Typography.Text>
                            <Input
                                style={CommonStyle.inputStyle}
                                placeholder={'예시. 풀빌라+복층 A'}
                                value={roomTypeDetail?.name || ''}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                카테고리
                            </Typography.Text>
                            <Radio.Group disabled value={roomTypeDetail?.type}>
                                <Radio value={'PENSION'}>
                                    <TextLiner
                                        enabled={roomTypeDetail?.type === 'PENSION'}
                                        value={'펜션'}/>
                                </Radio>
                                <Radio value={'POOLVILLA'}>
                                    <TextLiner
                                        enabled={roomTypeDetail?.type === 'POOLVILLA'}
                                        value={'풀빌라'}/>
                                </Radio>
                                <Radio value={'CONDO'}>
                                    <TextLiner
                                        enabled={roomTypeDetail?.type === 'CONDO'}
                                        value={'콘도'}/>
                                </Radio>
                                <Radio value={'CARAVAN'}>
                                    <TextLiner
                                        enabled={roomTypeDetail?.type === 'CARAVAN'}
                                        value={'카라반'}/>
                                </Radio>
                                <Radio value={'GLAMPING'}>
                                    <TextLiner
                                        enabled={roomTypeDetail?.type === 'GLAMPING'}
                                        value={'글램핑'}/>
                                </Radio>
                                <Radio value={'CAMPING'}>
                                    <TextLiner
                                        enabled={roomTypeDetail?.type === 'CAMPING'}
                                        value={'캠핑'}/>
                                </Radio>
                            </Radio.Group>
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            인원
                        </Typography.Text>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                기준 인원 (추가요금 없이 입실 가능한 인원)
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={roomTypeDetail?.standardNumberOfPeople}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                최대 인원 (최대 입실 가능한 인원)
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={roomTypeDetail?.maxNumberOfPeople}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            추가 인원 요금
                        </Typography.Text>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                성인
                            </Typography.Text>
                            <Input
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={moneyValue(roomTypeDetail?.additionalFeeAdult)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                아동/청소년
                            </Typography.Text>
                            <Input
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={moneyValue(roomTypeDetail?.additionalFeeChild)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                유아
                            </Typography.Text>
                            <Input
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={moneyValue(roomTypeDetail?.additionalFeeBaby)}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            객실 구성
                        </Typography.Text>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                평형 (ex, 30평)
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={roomTypeDetail?.roomSize}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                거실 수
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={roomTypeDetail?.numberOfLivingRoom}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                방 수
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={roomTypeDetail?.numberOfRoom}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                욕실 수
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.inputStyle, marginTop: 0}}
                                value={roomTypeDetail?.numberOfBathroom}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                객실 설명
                            </Typography.Text>
                            <Input.TextArea
                                disabled
                                style={{...CommonStyle.inputStyle, height: 120}}
                                value={roomTypeDetail?.description}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                숙박 요금
                            </Typography.Text>
                            <AccommodationPrice dropdownDisabled prices={roomTypeDetail?.prices || []}/>
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                기본 시설
                            </Typography.Text>
                            <AccommodationBasicFacility
                                selectedBasicFacilityIds={selectedBasicFacilityIds}
                                basicFacilities={accommodationBasicFacilities}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                유료 시설
                            </Typography.Text>
                            <AccommodationPaidFacility
                                selectedPaidFacilityIds={selectedPaidFacilityIds}
                                paidFacilities={accommodationPaidFacilities}
                            />
                        </Space>
                    </Col>
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const Header = ({}) => {
    return (
        <Space direction={'vertical'} style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={2} style={{...TextStyle}}>객실 타입 상세</Typography.Title>
        </Space>
    )
}

export default withPolicies(RoomTypeScreen)
