import React, {useEffect} from "react";
import ScreenOuter from "../../components/ScreenOuter";
import ScreenGrid from "../../components/ScreenGrid";
import {Button, Col, Image, Input, Modal, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../constants/style";
import {LeftOutlined} from "@ant-design/icons";
import NavigationService from "../../services/NavigationService";
import {PageRoutes} from "../../App";
import {useNavigate} from "react-router-dom";
import {CommonStyle} from "../Accommodation/AccommodationScreen/style";
import RegisterButton from "../../components/RegisterButton";
import NoBorderButton from "../../components/NoBorderButton";
import {useDispatch, useSelector} from "react-redux";
import {requestUserMeApi} from "../../redux/User";
import CookieService from "../../services/CookieService";
import withPolicies from "../../components/withPolicies";
import {MANAGER_CONTACT} from "../../constants/content";

const MyPageScreen = ({}) => {
    const [modal, contextHolder] = Modal.useModal()
    const dispatch = useDispatch()

    useEffect(() => {
        // @ts-ignore
        dispatch(requestUserMeApi())
    }, [])

    // @ts-ignore
    const user = useSelector(state => state.User.get('user'))

    const requestLogout = () => {
        CookieService.initAccessToken()
        NavigationService.moveToLoginPage()
    }

    return (
        <div style={{maxWidth: '100%', overflow: 'hidden', width: '100%', height: '100%'}}>
            <Header/>
            <Row>
                <Col xs={2} xl={2}></Col>
                <Col xs={20} xl={20}>
                    <Row style={{marginTop: 40}}>
                        <Col xs={24} xl={24}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                계정
                            </Typography.Text>
                        </Col>
                        <Col xs={24} xl={12} style={{marginTop: 40}}>
                            <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                                <Typography.Text style={CommonStyle.innerLabelStyle}>
                                    이름
                                </Typography.Text>
                                <Input
                                    style={{...CommonStyle.inputStyle, marginTop: 0}}
                                    value={user?.name}
                                />
                            </Space>
                        </Col>
                        <Col xs={24} xl={12} style={{marginTop: 40}}>
                            <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                                <Typography.Text style={CommonStyle.innerLabelStyle}>
                                    이메일
                                </Typography.Text>
                                <Input
                                    style={{...CommonStyle.inputStyle, marginTop: 0}}
                                    value={user?.username}
                                />
                            </Space>
                        </Col>
                    </Row>
                    {/*<Row style={{...CommonStyle.registerButtonContainerStyle, marginTop: 50}}>*/}
                    {/*    <RegisterButton customStyle={{marginRight: 30}} onClick={() => alert('click')}/>*/}
                    {/*</Row>*/}
                    <Row style={{marginTop: 150, justifyContent: 'flex-end'}}>
                        <Space style={{width: 140, justifyContent: 'space-between'}}>
                            <NoBorderButton
                                style={{
                                    ...TextStyle,
                                    fontSize: 16,
                                    fontWeight: 500,
                                    color: Color.darkTertiary
                                }}
                                onClick={() => modal.warning({
                                    title: '탈퇴 방법 안내',
                                    content: `회원 탈퇴를 위해서는 고객센터${MANAGER_CONTACT}로 문의해주세요.`
                                })}
                                value={'회원탈퇴'}
                            />
                            <NoBorderButton
                                style={{
                                    ...TextStyle,
                                    fontSize: 16,
                                    fontWeight: 500,
                                }}
                                onClick={() => modal.confirm({
                                    title: '로그아웃 하시겠습니까?',
                                    cancelText: '취소',
                                    okText: '로그아웃',
                                    onOk: requestLogout,
                                })}
                                value={'로그아웃'}
                            />
                        </Space>
                    </Row>
                </Col>
                <Col xs={2} xl={2}></Col>
            </Row>
            {contextHolder}
        </div>
    )
}

const Header = ({}) => {
    const navigate = useNavigate()
    const goBack = () => navigate(-1)

    return (
        <Row>
            <Col xs={6} xl={2}>
                <Space style={{width: '100%', height: 80, marginLeft: 40}}>
                    <LeftOutlined onClick={goBack}/>
                </Space>
            </Col>
            <Col xs={18} xl={22}>
                <Space style={{
                    width: '100%',
                    height: 80,
                    alignItems: 'center',
                    justifyItems: 'center',
                }}>
                    <Typography.Text style={{...TextStyle, fontSize: 22, fontWeight: 700}}>
                        내 정보
                    </Typography.Text>
                </Space>
            </Col>
        </Row>
    )
}

export default MyPageScreen
