import ApiService from "../../services/ApiService";


export interface Content {
    type: 'IMAGE' | 'VIDEO',
    filePath: string,
}

class ContentApi {
    static preSignedUrl(fileType: 'BIZ_REGISTRATION_FILE' | 'ACCOMMODATION_IMAGES', fileName: string) {
        const path = `/contents/pre-signed-url`
        const body = {
            fileType,
            fileName,
        }

        return ApiService.post(path, body, true);
    }

    static upload(preSignedUrl: string, file: File) {
        return fetch(preSignedUrl, {body: file, mode: "cors", method: "PUT"})
    }

    static createAccommodationContent(type: 'IMAGE' | 'VIDEO', accommodationId: number, filePath: string) {
        const path = `/contents/accommodations/${accommodationId}`
        const body = {
            type: type,
            filePath: filePath,
        }
        return ApiService.post(path, body, true)
    }

    static createRoomContents(roomId: number, contents: Content[]) {
        const path = `/contents/rooms/${roomId}`
        const body = {
            contents: contents,
        }
        return ApiService.post(path, body, true)
    }

    static getAccommodationContents(accommodationId: number) {
        const path = `/contents/accommodations/${accommodationId}`
        return ApiService.get(path, true)
    }

    static deleteContent(contentId: number) {
        const path = `/contents/${contentId}`
        return ApiService.delete(path, true)
    }
}

export default ContentApi
