import {Button} from "antd";
import {Color, TextStyle} from "../../constants/style";
import {CSSProperties, ReactNode} from "react";
import {EditOutlined} from "@ant-design/icons";

const RegisterButton = (
    {
        disabled = false,
        onClick,
        icon = <EditOutlined/>,
        label = '수정하기',
        customStyle,
    }: {
        disabled?: boolean,
        onClick?: any,
        icon?: ReactNode,
        label?: string,
        customStyle?: CSSProperties,
    }) => {
    customStyle = customStyle || {}
    const buttonStyle: CSSProperties = {
        width: 167,
        height: 64,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: Color.y40,
        borderColor: Color.y40,
        boxShadow: '0 2px 12px #FFE866',
        borderRadius: 40,
        ...TextStyle,
        fontWeight: 700,
        fontSize: 18,
        color: Color.darkPrimary,
        ...customStyle,
    }
    const disabledButtonStyle = {
        ...buttonStyle,
        boxShadow: '0 0 0 #F1F1F3',
        borderColor: Color.black5,
        backgroundColor: Color.black5,
        color: Color.darkDisabled,
    }
    return (
        <Button
            disabled={disabled}
            onClick={onClick}
            style={disabled ? disabledButtonStyle : buttonStyle}
            icon={icon}
        >
            {label}
        </Button>
    )
}

export default RegisterButton
