// @ts-nocheck

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {requestAccommodations} from "../../../redux/Accommodation";

import ScreenOuter from "../../../components/ScreenOuter";
import withPolicies from "../../../components/withPolicies";
import ScreenGrid from "../../../components/ScreenGrid";
import {Button, Space, Tabs, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {ReservationList} from "./ReservationList";
import {requestReservations, SearchReservationParam} from "../../../redux/Reservation";
import {PagingAndSortingParam} from "../../../apis/ApiUtil";
import LocalStorageService from "../../../services/LocalStorageService";
import {MonthlyStatistics} from "./MonthlyStatistics";


const RESERVATION_PAGE_SIZE = 10

const ReservationScreen = () => {
    const dispatch = useDispatch()
    const accommodationName = useSelector(state => state.Accommodation.get('name'))

    useEffect(() => {
        dispatch(requestAccommodations())
        requestReservationApi(null, {})
    }, [])


    const onSearchReservationList = (page: number, size: number, param: SearchReservationParam) =>
        requestReservationApi({page, size}, param)

    const requestReservationApi = (pagingAndSortingParam?: PagingAndSortingParam, param: SearchReservationParam) => {
        if (!pagingAndSortingParam) {
            pagingAndSortingParam = {page: 0, size: RESERVATION_PAGE_SIZE}
        }

        LocalStorageService.saveSearchReservationParam(param.startDate, param.endDate, param.createdDate, param.nameOrPhoneNumber)
        dispatch(requestReservations(pagingAndSortingParam, param))
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Header/>
                <TopTabs
                    ReservationList={<ReservationList pageSize={RESERVATION_PAGE_SIZE}
                                                      onSearchReservationList={onSearchReservationList}/>}
                    MonthlyStatistics={<MonthlyStatistics/>}
                />
            </ScreenGrid>
        </ScreenOuter>
    );
}

const Header = ({}) => {
    return (
        <Space style={{width: '100%', alignItems: 'center', justifyContent: 'flex-start'}}>
            <Typography.Title level={2} style={{...TextStyle}}>예약 현황</Typography.Title>
        </Space>
    )
}

const TopTabs = ({ReservationList, MonthlyStatistics}: {
    ReservationList: React.ReactNode,
    MonthlyStatistics: React.ReactNode
}) => {
    const TabLabel = ({label}: { label: string }) => <div style={{width: 120, textAlign: 'center'}}>{label}</div>
    const tabs: any = [
        {
            key: "0",
            label: <TabLabel label={'예약 목록'}/>,
            children: ReservationList,
        },
        {
            key: "1",
            label: <TabLabel label={'월별 현황'}/>,
            children: MonthlyStatistics,
        },
    ]
    return (
        <Tabs
            style={{marginTop: 20}}
            tabBarStyle={{...TextStyle, fontWeight: 500, fontSize: 18, color: Color.darkPrimary}}
            defaultActiveKey={"0"}
            items={tabs}
        />
    )
}

export default withPolicies(ReservationScreen)
