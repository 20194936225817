import {Button, Col, Divider, Image, Pagination, PaginationProps, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import React, {CSSProperties} from "react";
import {RightOutlined} from "@ant-design/icons";
import {FALLBACK_IMAGE} from "../../../constants/content";
import {useNavigate} from "react-router-dom";
import NoBorderButton from "../../../components/NoBorderButton";

const RoomInfo = ({roomPage, rooms, roomTypes, tabs, enabledTab, onChangeEnabledTab, onChangePage}: {
    roomPage: {
        totalPages: number,
        totalElements: number,
        currentPage: number,
    },
    rooms: any[],
    roomTypes: any[],
    tabs: any[],
    enabledTab: string,
    onChangeEnabledTab: (name: string) => void,
    onChangePage: (page: number, size: number) => void,
}) => {
    if (roomTypes.length < 1) {
        return <RegisterRoomTypeButton/>
    }

    return (
        <Space direction={'vertical'} style={{width: '100%'}}>
            <Row>
                <Col xs={24} xl={24}>
                    <RoomTypeTabs
                        tabs={tabs}
                        enabledTab={enabledTab}
                        onChangeEnabledTab={onChangeEnabledTab}
                    />
                </Col>
            </Row>
            <Row style={{marginTop: 48}}>
                {rooms.length < 1 ? (
                    <RegisterRoomButton/>
                ) : (
                    <RoomList page={roomPage} rooms={rooms} onChangePage={onChangePage}/>
                )}
            </Row>
        </Space>
    );
}

const RoomList = ({page, rooms, onChangePage}: {
    page: {
        totalPages: number,
        totalElements: number,
        currentPage: number,
    },
    rooms: any[],
    onChangePage: (page: number, size: number) => void,
}) => {
    const naviagte = useNavigate()
    const innerTextStyle = {
        ...TextStyle,
        fontSize: 18,
        fontWeight: 400,
        color: Color.darkPrimary,
    }
    const VerticalDivider = () => (
        <div style={{width: 1, height: 16, backgroundColor: Color.b10}}></div>
    )

    return (
        <>
            {rooms.map((room, index) => (
                <Space key={index} direction={'vertical'} style={{width: '100%', height: 'auto'}}>
                    <Space>
                        <NoBorderButton
                            style={{
                                ...TextStyle,
                                fontSize: 20,
                                fontWeight: 700,
                            }}
                            onClick={() => naviagte(PageRoutes.dynamicPath('/rooms', room.id), {state: room})}
                            value={room?.name}
                            rightIcon={<RightOutlined/>}
                        />
                    </Space>
                    <Space style={{marginTop: 16}}>
                        <NoBorderButton
                            style={{
                                ...TextStyle,
                                fontSize: 18,
                                fontWeight: 400,
                                color: Color.b40,
                            }}
                            onClick={() => naviagte(PageRoutes.dynamicPath("/rooms/room-types", room.roomType.id),
                                {state: room.roomType})}
                            value={room?.roomType?.name || ''}
                        />
                        <VerticalDivider/>
                        <Typography.Text
                            style={innerTextStyle}>{room.roomType.standardNumberOfPeople}~{room.roomType.maxNumberOfPeople}명</Typography.Text>
                        <VerticalDivider/>
                        <Typography.Text style={innerTextStyle}>
                            거실 {room.roomType.numberOfLivingRoom}, 방 {room.roomType.numberOfRoom},
                            욕실 {room.roomType.numberOfBathroom}</Typography.Text>
                    </Space>
                    <Space style={{maxWidth: '100%', overflowX: 'auto', overflowY: 'hidden', marginTop: 16}}>
                        {room.roomContents.map((roomContent: any, index: number) => {
                            return roomContent.type === 'IMAGE' ? (
                                <Image
                                    key={index}
                                    preview
                                    src={roomContent.url}
                                    width={174}
                                    height={120}
                                    style={{borderRadius: 8, flexShrink: 0}}
                                    fallback={FALLBACK_IMAGE}
                                />
                            ) : (
                                // TODO: 동영상
                                <></>
                            )
                        })}
                    </Space>
                    <Divider
                        style={{width: '100%', marginTop: 24, marginBottom: 24, backgroundColor: Color.lightGrey}}/>
                </Space>
            ))}
            <Pagination
                showSizeChanger={false}
                defaultCurrent={1}
                pageSize={5}
                current={page.currentPage + 1}
                total={page.totalElements}
                onChange={(page, pageSize) => onChangePage(page - 1, pageSize)}
            />
        </>
    )
}

const RoomTypeTabs = ({tabs, enabledTab, onChangeEnabledTab}: {
    tabs: any[],
    enabledTab: string,
    onChangeEnabledTab: (tab: any) => void
}) => {
    const enableTabStyle = (type: string) => enabledTab === type ? innerTopTabStyle : {
        ...innerTopTabStyle,
        backgroundColor: Color.white,
        borderColor: '#E5E5E7',
    }

    return (
        <Space style={{marginTop: 20, marginBottom: 20}}>
            <Button
                style={enableTabStyle('ALL')}
                onClick={() => onChangeEnabledTab('ALL')}
            >전체</Button>
            {tabs.map(tab => (
                <Button
                    key={tab.id}
                    style={enableTabStyle(tab.name)}
                    onClick={() => onChangeEnabledTab(tab.name)}
                >{tab.name}</Button>
            ))}
        </Space>
    )
}

const RegisterRoomTypeButton = () => (
    <Space direction={'vertical'}
           style={{width: '100%', marginTop: 120, alignItems: 'center', justifyContent: 'center'}}>
        <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 400}}>객실 타입 등록 후 객실을 등록할 수
            있어요.</Typography.Text>

        <Button
            style={{
                marginTop: 16,
                display: 'flex',
                height: 50,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px 20px',
                gap: 4,
                backgroundColor: Color.y40,
                borderColor: Color.y40,
                borderRadius: 16,
                ...TextStyle,
                fontSize: 16,
                fontWeight: 700,
            }}
            onClick={() => NavigationService.moveTo(PageRoutes.REGISTER_ROOM_TYPE)}
        >객실 타입 등록하기</Button>
    </Space>
)

const RegisterRoomButton = () => (
    <Space direction={'vertical'}
           style={{width: '100%', marginTop: 120, alignItems: 'center', justifyContent: 'center'}}>
        <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 400}}>
            등록한 객실이 없어요.
        </Typography.Text>
        <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 400}}>
            지금 등록하세요!
        </Typography.Text>
        <Button
            style={{
                marginTop: 16,
                display: 'flex',
                height: 50,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px 20px',
                gap: 4,
                backgroundColor: Color.y40,
                borderColor: Color.y40,
                borderRadius: 16,
                ...TextStyle,
                fontSize: 16,
                fontWeight: 700,
            }}
            onClick={() => NavigationService.moveTo(PageRoutes.REGISTER_ROOM)}
        >객실 등록하기</Button>
    </Space>
)

const innerTopTabStyle: CSSProperties = {
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.y40,
    borderColor: Color.y40,
    borderRadius: 40,
    ...TextStyle,
    fontWeight: 500,
    fontSize: 16,
    color: Color.darkPrimary,
}

export default RoomInfo
