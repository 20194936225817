import ScreenOuter from "../../../components/ScreenOuter";
import {useDispatch, useSelector} from "react-redux";
import React, {CSSProperties, ReactNode, useEffect, useRef, useState} from "react";
import {
    requestAccommodations,
    requestDeletePaidFacility,
    requestDeleteSeason,
    requestDeletePrice,
    requestAccommodationContents, requestDeleteContent,
} from "../../../redux/Accommodation";
import {Navigate, useNavigate} from "react-router-dom";
import {
    Button,
    Col,
    Dropdown, Image,
    Input,
    MenuProps, message, Modal, notification,
    Row,
    Space,
    Table,
    Tabs,
    TimePicker,
    Typography, Upload, UploadFile, UploadProps
} from "antd";
import ScreenGrid from "../../../components/ScreenGrid";
import {Color, TextStyle} from "../../../constants/style";
import {
    CloseOutlined, DeleteFilled,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    PlusOutlined
} from "@ant-design/icons";
import RegisterButton from "../../../components/RegisterButton";
import dayjs from "dayjs";
import {ColumnsType} from "antd/es/table";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import RequiredFieldMark from "../../../components/RequiredFieldMark";

import {CommonStyle} from './style'
import {moneyValue} from "../../../utils/string";
import _ from "lodash";
import EmptySpace from "../../../components/EmptySpace";

import './AccommodationScreen.css'
import {RcFile} from "antd/es/upload";
import {requestPreSignedUrl} from "../../../redux/Content";
import ContentApi from "../../../apis/ContentApi";
import LocalStorageService from "../../../services/LocalStorageService";
import PresignedUrlImageRegistration from "../../../components/PresignedUrlImageRegistration";
import withPolicies from "../../../components/withPolicies";

const AccommodationScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationStatus = useSelector(state => state.Accommodation.get('status'))
    // @ts-ignore
    const accommodationAddress = useSelector(state => state.Accommodation.get('address'))
    // @ts-ignore
    const accommodationAddressDetail = useSelector(state => state.Accommodation.get('addressDetail'))
    // @ts-ignore
    const accommodationBizNumber = useSelector(state => state.Accommodation.get('bizNumber'))
    // @ts-ignore
    const accommodationBankName = useSelector(state => state.Accommodation.get('bankName'))
    // @ts-ignore
    const accommodationAccountNumber = useSelector(state => state.Accommodation.get('accountNumber'))
    // @ts-ignore
    const accommodationAccountHolder = useSelector(state => state.Accommodation.get('accountHolder'))
    // @ts-ignore
    const accommodationOfficePhoneNumber = useSelector(state => state.Accommodation.get('officePhoneNumber'))
    // @ts-ignore
    const accommodationDescription = useSelector(state => state.Accommodation.get('description'))
    // @ts-ignore
    const accommodationCheckInTime = useSelector(state => state.Accommodation.get('checkInTime'))
    // @ts-ignore
    const accommodationCheckOutTime = useSelector(state => state.Accommodation.get('checkOutTime'))
    // @ts-ignore
    const accommodationSeasons = useSelector(state => state.Accommodation.get('seasons'))
    // @ts-ignore
    const accommodationPrices = useSelector(state => state.Accommodation.get('prices'))
    // @ts-ignore
    const accommodationBasicFacilities = useSelector(state => state.Accommodation.get('basicFacilities'))
    // @ts-ignore
    const accommodationPaidFacilities = useSelector(state => state.Accommodation.get('paidFacilities'))
    // @ts-ignore
    const accommodationUsagePolicy = useSelector(state => state.Accommodation.get('usagePolicy'))
    // @ts-ignore
    const accommodationRefundPolicy = useSelector(state => state.Accommodation.get('refundPolicy'))
    // @ts-ignore
    const accommodationRefundPercentages = useSelector(state => state.Accommodation.get('refundPercentages'))

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    useEffect(() => {
        if (accommodationId) {
            // @ts-ignore
            dispatch(requestAccommodationContents(accommodationId))
        }

        if (accommodationName) {
            LocalStorageService.saveAccommodationName(accommodationName)
        }
    }, [accommodationId, accommodationName])

    if (accommodationStatus === 'PENDING') {
        return <Navigate to={PageRoutes.REGISTER_ACCOMMODATIONS}/>
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Header modal={modal}/>
                <TopTabs
                    DefaultInfo={
                        <DefaultInfo
                            disabled
                            accommodationName={accommodationName}
                            accommodationAddress={accommodationAddress}
                            accommodationAddressDetail={accommodationAddressDetail}
                            accommodationBizNumber={accommodationBizNumber}
                            accommodationBankName={accommodationBankName}
                            accommodationAccountNumber={accommodationAccountNumber}
                            accommodationAccountHolder={accommodationAccountHolder}
                            accommodationOfficeNumber={accommodationOfficePhoneNumber}
                            accommodationDescription={accommodationDescription}
                            onPressRegisterButton={() => NavigationService.moveTo(PageRoutes.EDIT_ACCOMMODATION_DEFAULT)}
                        />
                    }
                    ContentsInfo={
                        <ContentInfo accommodationId={accommodationId}/>
                    }
                    TimeInfo={
                        <TimeInfo
                            disabled
                            checkInTime={accommodationCheckInTime}
                            checkOutTime={accommodationCheckOutTime}
                            onPress={() => NavigationService.moveTo(PageRoutes.EDIT_ACCOMMODATION_TIME)}
                        />
                    }
                    PriceInfo={<PriceInfo accommodationId={accommodationId} seasons={accommodationSeasons}
                                          prices={accommodationPrices}/>}
                    FacilityInfo={
                        <FacilityInfo
                            accommodationId={accommodationId}
                            basicFacilities={accommodationBasicFacilities}
                            paidFacilities={accommodationPaidFacilities}
                        />
                    }
                    PolicyInfo={<PolicyInfo
                        usagePolicy={accommodationUsagePolicy}
                        refundPolicy={accommodationRefundPolicy}
                        refundPercentages={accommodationRefundPercentages}
                    />}
                />
            </ScreenGrid>
            {contextHolder}
        </ScreenOuter>
    )
}

const Header = ({modal}: { modal: any }) => {
    return (
        <Space style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={2} style={{...TextStyle}}>숙소 관리</Typography.Title>
            <Button
                icon={<PlusOutlined/>}
                style={{
                    width: 135,
                    height: 50,
                    backgroundColor: Color.black5,
                    borderColor: Color.black5,
                    borderRadius: 16,
                    alignItems: 'center',
                    justifyContent: 'center',
                    ...TextStyle,
                    fontStyle: 'normal',
                    fontWeight: 700,
                    fontSize: 16,
                }}
                // @ts-ignore
                onClick={() => modal.info({title: '새 숙소 추가 기능은 곧 제공될 예정입니다.'})}
            >새 숙소 추가</Button>
        </Space>
    )
}

export const DefaultInfo = (
    {
        disabled,
        accommodationName,
        accommodationAddress,
        accommodationAddressDetail,
        accommodationBizNumber,
        accommodationBankName,
        accommodationAccountNumber,
        accommodationAccountHolder,
        accommodationOfficeNumber,
        accommodationDescription,
        onChangeName = () => null,
        onChangeDescription = () => null,
        onPressRegisterButton,
    }: {
        disabled?: boolean,
        accommodationName: string,
        accommodationAddress: string,
        accommodationAddressDetail: string,
        accommodationBizNumber: string,
        accommodationBankName: string,
        accommodationAccountNumber: string,
        accommodationAccountHolder: string,
        accommodationOfficeNumber: string,
        accommodationDescription: string,
        onChangeName?: (value: string) => void,
        onChangeDescription?: (value: string) => void,
        onPressRegisterButton: () => void,
    }
) => {
    return (
        <>
            <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                <Typography.Text style={CommonStyle.labelStyle}>
                    숙소 이름 <RequiredFieldMark disabled={disabled}/>
                </Typography.Text>
                <Input
                    disabled={disabled}
                    style={disabled ? CommonStyle.inputStyle : CommonStyle.enabledInputStyle}
                    value={accommodationName}
                    onChange={(e) => onChangeName(e.target.value)}
                />
            </Space>

            <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                <Typography.Text style={CommonStyle.labelStyle}>
                    주소 <RequiredFieldMark disabled={disabled}/>
                </Typography.Text>
                <Input
                    disabled={true}
                    style={CommonStyle.inputStyle}
                    value={`${accommodationAddress} ${accommodationAddressDetail}`}
                />
            </Space>

            <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                <Typography.Text style={CommonStyle.labelStyle}>
                    사업자 번호 <RequiredFieldMark disabled={disabled}/>
                </Typography.Text>
                <Input disabled={true} style={CommonStyle.inputStyle}
                       value={accommodationBizNumber?.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3')}/>
            </Space>

            <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                <Typography.Text style={CommonStyle.labelStyle}>
                    입금 계좌 <RequiredFieldMark disabled={disabled}/>
                </Typography.Text>
                <Row>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                은행명
                            </Typography.Text>
                            <Dropdown
                                disabled
                                overlayStyle={CommonStyle.dropdownOverlayStyle}
                            >
                                <Button style={{
                                    ...CommonStyle.inputStyle,
                                    textAlign: 'left',
                                    marginTop: 0,
                                    width: '95%'
                                }}
                                        size='large'>
                                    {accommodationBankName || '은행 선택'}
                                    <DownOutlined style={{marginTop: 5, float: 'right'}}/>
                                </Button>
                            </Dropdown>
                        </Space>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                계좌번호
                            </Typography.Text>
                            <Input disabled={true}
                                   style={{
                                       ...CommonStyle.inputStyle,
                                       overflow: 'visible',
                                       height: 'auto',
                                       marginTop: 0
                                   }}
                                   value={accommodationAccountNumber}/>
                        </Space>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                예금주
                            </Typography.Text>
                            <Input disabled={true} style={{...CommonStyle.inputStyle, marginTop: 0}}
                                   value={accommodationAccountHolder}/>
                        </Space>
                    </Col>
                </Row>

                <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                    <Typography.Text style={CommonStyle.labelStyle}>
                        대표 전화번호 <RequiredFieldMark disabled={disabled}/>
                    </Typography.Text>
                    <Input disabled={true} style={CommonStyle.inputStyle}
                           value={accommodationOfficeNumber}/>
                </Space>

                <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                    <Typography.Text style={CommonStyle.labelStyle}>
                        숙소 소개
                    </Typography.Text>
                    <Input.TextArea
                        autoSize
                        disabled={disabled}
                        placeholder={'소개 문구를 입력해주세요.'}
                        style={disabled ? CommonStyle.inputStyle : CommonStyle.enabledInputStyle}
                        value={accommodationDescription}
                        onChange={(e) => onChangeDescription(e.target.value)}
                    />
                </Space>

                <Row style={CommonStyle.registerButtonContainerStyle}>
                    <RegisterButton onClick={onPressRegisterButton}/>
                </Row>
            </Space>
        </>
    )
}

export const ContentInfo = ({accommodationId}: {
    accommodationId: number,
}) => {
    const dispatch = useDispatch();
    const [modal, contextHolder] = Modal.useModal()

    // @ts-ignore
    const accommodationContents = useSelector(state => state.Accommodation.get('contents'))

    const [enabledTab, setEnabledTab] = useState('picture')
    const [images, setImages]: [images: string[], setImages: any] = useState([])

    useEffect(() => {
        if (accommodationContents) {
            setImages(accommodationContents
                .filter((content: any) => content.type === 'IMAGE'));
        }
    }, [accommodationContents])

    const enableTabStyle = (type: string) => enabledTab === type ? innerTopTabStyle : {
        ...innerTopTabStyle,
        backgroundColor: Color.white,
        borderColor: '#E5E5E7',
    }

    const pictureWidth = 280
    const pictureHeight = 193
    const pictureBoxStyle = {
        margin: 10,
        borderRadius: 16,
        backgroundColor: Color.white,
        borderColor: Color.white,
        width: pictureWidth,
        height: pictureHeight + 40,
    }

    const retrieveAccommodationContents = async () => {
        // @ts-ignore
        await dispatch(requestAccommodationContents(accommodationId))
    }

    const onDeletePicture = async (imageData: any) => {
        // @ts-ignore
        const result = await dispatch(requestDeleteContent(imageData.id))

        if (result.success) {
            notification.success({message: '사진이 삭제 되었습니다.'});
            retrieveAccommodationContents();
        } else {
            modal.error({
                title: `사진 삭제에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <>
            <Row>
                <Col xs={12} xl={12}>
                    <Space style={{marginTop: 40}}>
                        <Button
                            style={enableTabStyle('picture')}
                            onClick={() => setEnabledTab('picture')}
                        >사진</Button>
                        <Button
                            style={enableTabStyle('video')}
                            onClick={() => modal.info({title: '동영상 업로드 기능은 곧 제공될 예정입니다.'})}
                        >동영상</Button>
                    </Space>
                </Col>
                <Col xs={12} xl={12}>
                    <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                        <PresignedUrlImageRegistration
                            imageType={'ACCOMMODATION_IMAGES'}
                            registerImagePath={async (path: string) => await ContentApi.createAccommodationContent('IMAGE', accommodationId, path)}
                            onCompletedUpload={() => {
                                retrieveAccommodationContents()
                                notification.success({message: '사진이 성공적으로 업로드되었습니다.'})
                            }}
                        />
                    </Space>
                </Col>
            </Row>
            {enabledTab === 'picture' ? (
                <>
                    <Row>
                        {/*<Typography.Text*/}
                        {/*    style={{*/}
                        {/*        ...TextStyle,*/}
                        {/*        marginTop: 16,*/}
                        {/*        marginBottom: 16,*/}
                        {/*        fontSize: 16,*/}
                        {/*        fontWeight: 400,*/}
                        {/*        color: Color.darkTertiary*/}
                        {/*    }}>*/}
                        {/*    드래그&드롭하여 순서를 수정할 수 있습니다.*/}
                        {/*</Typography.Text>*/}
                        <Col xs={24} xl={24} style={{marginTop: 32}}>
                            {images.map((imageData: any, index: number) => {
                                return (
                                    <Space key={index} direction={'vertical'} style={{...pictureBoxStyle}}>
                                        <Image
                                            preview={true}
                                            src={imageData.url}
                                            width={pictureWidth}
                                            height={pictureHeight}
                                            style={{
                                                borderRadius: 16,
                                            }}
                                        />
                                        <Button
                                            style={{
                                                gap: 0,
                                                padding: 0,
                                                marginTop: 0,
                                                width: pictureWidth,
                                                height: 40,
                                                color: Color.white,
                                                backgroundColor: Color.darkPrimary,
                                                borderColor: Color.black5,
                                            }}
                                            icon={<DeleteFilled/>}
                                            onClick={() => modal.confirm({
                                                title: '사진을 삭제하시겠습니까?',
                                                okText: '삭제',
                                                cancelText: '취소',
                                                onOk: () => onDeletePicture(imageData),
                                            })}
                                        />
                                    </Space>
                                )
                            })}

                        </Col>
                    </Row>
                </>
            ) : (
                <>
                </>
            )}

            {contextHolder}
        </>
    )
}


export const TimeInfo = (
    {
        disabled,
        checkInTime,
        checkOutTime,
        onChangeCheckOutTime = () => null,
        onChangeCheckInTime = () => null,
        onPress
    }: {
        disabled?: boolean,
        checkInTime: string,
        checkOutTime: string,
        onChangeCheckInTime?: (dateString: string) => void,
        onChangeCheckOutTime?: (dateString: string) => void,
        onPress: () => void,
    }
) => {
    const timeFormat = 'HH:mm'
    return (
        <>
            <Row>
                <Col xs={24} xl={12}>
                    <Space direction={"vertical"} style={{width: '95%', marginTop: 40}}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            입실
                        </Typography.Text>
                        <TimePicker
                            disabled={disabled}
                            style={disabled ? CommonStyle.inputStyle : CommonStyle.enabledInputStyle}
                            value={dayjs(checkInTime, timeFormat)}
                            onChange={(value, dateString) => onChangeCheckInTime(dateString)}
                            format={timeFormat}
                        />
                    </Space>
                </Col>
                <Col xs={24} xl={12}>
                    <Space direction={"vertical"} style={{width: '95%', marginTop: 40}}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            퇴실
                        </Typography.Text>
                        <TimePicker
                            disabled={disabled}
                            style={disabled ? CommonStyle.inputStyle : CommonStyle.enabledInputStyle}
                            value={dayjs(checkOutTime, timeFormat)}
                            onChange={(value, dateString) => onChangeCheckOutTime(dateString)}
                            format={timeFormat}
                        />
                    </Space>
                </Col>
            </Row>
            <Row style={{...CommonStyle.registerButtonContainerStyle, marginTop: 80}}>
                <RegisterButton onClick={onPress}/>
            </Row>
        </>
    )
}

const innerTopTabStyle: CSSProperties = {
    width: 58,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.y40,
    borderColor: Color.y40,
    borderRadius: 40,
    ...TextStyle,
    fontWeight: 500,
    fontSize: 16,
    color: Color.darkPrimary,
}


const PriceInfo = ({accommodationId, seasons = [], prices = []}: {
    accommodationId: number,
    seasons: any,
    prices: any
}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modal, contextHolder] = Modal.useModal();
    const [enabledTab, setEnabledTab] = useState('season')
    const [priceName, setPriceName] = useState('')
    const [priceDataSources, setPriceDataSources] = useState([])

    interface SeasonDataType {
        key: string;
        id: number;
        seasonName: string;
        type: string;
        period: string;
        buttons: string[];
    }

    const buttonRenderer = (value: any, record: any) => {
        const deleteSeason = async () => {
            // @ts-ignore
            const result = await dispatch(requestDeleteSeason(accommodationId, record.id))

            if (result.success) {
                notification.success({
                    message: '시즌 정보 삭제에 성공했습니다!',
                    duration: 1,
                    onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
                })
            } else {
                modal.error({
                    title: `시즌 정보 삭제에 실패했습니다.`,
                    content: `${result?.error?.message || ''}`,
                    okText: '확인',
                })
            }
        }

        return (
            <>
                <Space>
                    {value.map((item: any, index: number) => {
                        return (
                            <Button
                                key={index}
                                icon={item.type === 'delete' ? <DeleteOutlined/> : <EditOutlined/>}
                                onClick={item.type === 'delete' ?
                                    () => modal.confirm({
                                        title: '시즌을 삭제하시겠습니까?',
                                        okText: '삭제',
                                        cancelText: '취소',
                                        onOk: deleteSeason
                                    }) :
                                    () => navigate(PageRoutes.EDIT_ACCOMMODATION_SEASON, {state: record})}
                            />
                        );
                    })}
                </Space>
            </>
        )
    }

    const seasonColumns: ColumnsType<SeasonDataType> = [
        {
            title: '시즌 이름',
            dataIndex: 'seasonName',
            key: 'seasonName',
        },
        {
            title: '타입',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '적용 기간',
            dataIndex: 'period',
            key: 'period',
        },
        {
            title: '',
            dataIndex: 'buttons',
            key: 'buttons',
            render: buttonRenderer,
        },
    ]

    const seasonDataSources: SeasonDataType[] = seasons.map((season: any) => {
        return {
            key: `season -${season.id}`,
            id: season.id,
            seasonName: season.name,
            type: season.type,
            period: `${season.startDate} ~ ${season.endDate}`,
            buttons: [{type: 'edit', value: season}, {type: 'delete', value: season}],
        }
    })

    interface PriceDataType {
        key: string;
        id: number;
        type: string;
        weekday: string;
        weekend: string;
        holiday: string;
    }

    const priceColumns: ColumnsType<PriceDataType> = [
        {
            title: '타입',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '주중(일~목)',
            dataIndex: 'weekday',
            key: 'weekday',
        },
        {
            title: '주말(금~토)',
            dataIndex: 'weekend',
            key: 'weekend',
        },
        {
            title: '공휴일 전',
            dataIndex: 'holiday',
            key: 'holiday',
        },
    ]

    const pricesGroupByName = _.groupBy(prices, (price: any) => price.name)

    useEffect(() => {
        if (prices && prices.length > 0) {
            setPriceName(prices[0].name)
        }
    }, [])

    useEffect(() => {
        if (pricesGroupByName[priceName]) {
            const prices = pricesGroupByName[priceName].map((price: any) => ({
                key: `
            price -${price.id}`,
                id: price.id,
                type: price.type,
                weekday: moneyValue(price.weekday),
                weekend: moneyValue(price.weekend),
                holiday: moneyValue(price.holiday),
            }));

            // @ts-ignore
            setPriceDataSources(prices)
        }
    }, [priceName])

    const enableTabStyle = (type: string) => enabledTab === type ? innerTopTabStyle : {
        ...innerTopTabStyle,
        backgroundColor: Color.white,
        borderColor: '#E5E5E7',
    }

    const onPressRegisterButton = enabledTab === 'season' ? () => NavigationService.moveTo(PageRoutes.REGISTER_ACCOMMODATION_SEASON) :
        () => NavigationService.moveTo(PageRoutes.REGISTER_ACCOMMODATION_PRICE)

    const handleMenuClick: MenuProps['onClick'] = (e) => setPriceName(e.key)
    const menuProps = {
        items: Object.keys(pricesGroupByName).map(priceName => ({label: priceName, key: priceName})),
        selectable: true,
        onClick: handleMenuClick
    };

    const onClickUpdateButton = () => navigate(PageRoutes.EDIT_ACCOMMODATION_PRICE, {
        state: {priceName, priceDataSources}
    })

    const deletePrice = async () => {
        // @ts-ignore
        const result = await dispatch(requestDeletePrice(accommodationId, priceName))

        if (result.success) {
            notification.success({
                message: '요금 정보 삭제에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        } else {
            modal.error({
                title: `요금 정보 삭제에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <>
            <Space style={{marginTop: 40}}>
                <Button
                    style={enableTabStyle('season')}
                    onClick={() => setEnabledTab('season')}
                >시즌</Button>
                <Button
                    style={enableTabStyle('price')}
                    onClick={() => setEnabledTab('price')}
                >요금</Button>
            </Space>
            {enabledTab === 'season' ? (
                <>
                    <Typography.Title level={4} style={{...TextStyle, fontSize: 18, fontWeight: 400,}}>
                        성수기, 준성수기, 비수기 등 시즌을 추가할 수 있습니다.
                    </Typography.Title>
                    <Table
                        style={{marginTop: 40}}
                        columns={seasonColumns}
                        dataSource={seasonDataSources}
                    />
                </>
            ) : (
                <>
                    <Typography.Title level={4} style={{...TextStyle, fontSize: 18, fontWeight: 400,}}>
                        이곳에 등록된 요금은 객실 관리 메뉴에서 원하는 객실의 요금으로 적용할 수 있습니다.
                    </Typography.Title>
                    <Row style={{marginTop: 24}}>
                        <Col xs={24} xl={12}>
                            <Dropdown
                                overlayStyle={CommonStyle.dropdownOverlayStyle}
                                menu={menuProps}
                            >
                                <Button style={{
                                    width: 232,
                                    height: 50,
                                    borderRadius: 16,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    ...TextStyle,
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                }} size='large'>
                                    {priceName || '요금 선택'}
                                    <DownOutlined/>
                                </Button>
                            </Dropdown>
                        </Col>
                        <Col xs={0} xl={12}>
                            <PriceModifyButtons
                                onClickUpdateButton={onClickUpdateButton}
                                onClickDeleteButton={() => modal.confirm({title: '요금을 삭제하시겠습니까?', onOk: deletePrice})}
                            />
                        </Col>
                        <Col xs={24} xl={0}>
                            <PriceModifyButtons
                                isMobile
                                onClickUpdateButton={onClickUpdateButton}
                                onClickDeleteButton={() => modal.confirm({title: '요금을 삭제하시겠습니까?', onOk: deletePrice})}
                            />
                        </Col>
                    </Row>
                    <Table
                        style={{marginTop: 40}}
                        columns={priceColumns}
                        dataSource={priceDataSources}
                    />
                </>
            )}
            <Row style={{...CommonStyle.registerButtonContainerStyle, marginTop: 80}}>
                <RegisterButton
                    onClick={onPressRegisterButton}
                    icon={<PlusOutlined/>}
                    label={enabledTab === 'season' ? '시즌 추가하기' : '요금 추가하기'}
                />
            </Row>

            {contextHolder}
        </>
    )
}

const PriceModifyButtons = ({isMobile = false, onClickUpdateButton, onClickDeleteButton}: {
    isMobile?: boolean,
    onClickUpdateButton: () => void,
    onClickDeleteButton: () => void
}) => {
    const style = isMobile ? {
        width: '100%',
        justifyContent: 'flex-start',
        marginTop: 20,
    } : {
        width: '100%',
        justifyContent: 'flex-end',
    }

    return (
        <Space style={style}>
            <Button
                style={{
                    width: 66,
                    height: 50,
                    backgroundColor: Color.black5,
                    borderColor: Color.black5,
                    borderRadius: 16,
                    ...TextStyle,
                    fontSize: 16,
                    fontWeight: 700,
                }}
                onClick={onClickUpdateButton}
            >
                수정
            </Button>
            <Button
                style={{
                    width: 66,
                    height: 50,
                    backgroundColor: Color.r5,
                    borderColor: Color.r5,
                    borderRadius: 16,
                    ...TextStyle,
                    color: Color.r40,
                    fontSize: 16,
                    fontWeight: 700,
                }}
                onClick={onClickDeleteButton}
            >
                삭제
            </Button>
        </Space>
    )
}

export type BasicFacilityType = '주변' | '침실' | '주방' | '욕실' | '냉/난방' | '엔터테인먼트' | '주차' | '기타'

export const FacilityLabel = ({removable = false, label, onPressDeleteButton = () => null}: {
    removable?: boolean,
    label: string,
    onPressDeleteButton?: any
}) => {
    return (
        <Space className={'inner-basic-type'} style={{
            marginRight: 8,
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 12,
            paddingBottom: 12,
            width: 'auto',
            minWidth: removable ? 120 : 100,
            height: 48,
            backgroundColor: Color.black3,
            borderColor: Color.black5,
            borderRadius: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: removable ? 'space-between' : 'center'
        }}>
            <Typography.Text
                style={{...TextStyle, fontSize: 18, fontWeight: 500,}}>{label}</Typography.Text>
            {removable && (
                <CloseOutlined onClick={onPressDeleteButton}/>
            )}
        </Space>
    )
}

const FacilityInfo = ({accommodationId, basicFacilities = [], paidFacilities = []}: {
    accommodationId: number,
    basicFacilities: any,
    paidFacilities: any
}) => {
    const dispatch = useDispatch()
    const [enabledTab, setEnabledTab] = useState('basic')

    const extendedInnerTopTabStyle = {
        ...innerTopTabStyle,
        width: 87,
        height: 40,
    }
    const enableTabStyle = (type: string) => enabledTab === type ? extendedInnerTopTabStyle : {
        ...extendedInnerTopTabStyle,
        backgroundColor: Color.white,
        borderColor: '#E5E5E7',
    }

    const BasicTypeComponent = ({type, labels}: { type: BasicFacilityType, labels: string[] }) => {
        return (
            <>
                <EmptySpace size={20}/>
                <Typography.Title level={4} style={{...TextStyle}}>{type}</Typography.Title>
                <Space className={'basic-type'} style={{width: '100%'}}>
                    {labels.map(label => <FacilityLabel label={label}/>)}
                </Space>
            </>
        )
    }

    const PaidTypeComponent = ({id, name, price, description, isMultiple, isDisplay}: {
        id: number,
        name: string,
        price: string,
        description: string,
        isMultiple: boolean,
        isDisplay: boolean
    }) => {
        const [modal, contextHolder] = Modal.useModal();

        const navigate = useNavigate()
        const onClickDelete = async () => {
            // @ts-ignore
            const result = await dispatch(requestDeletePaidFacility(accommodationId, id))

            if (result.success) {
                notification.success({
                    message: '유료 이용 시설 삭제에 성공했습니다!',
                    duration: 1,
                    onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
                })
            } else {
                modal.error({
                    title: `유료 이용 시설 삭제에 실패했습니다.`,
                    content: `${result?.error?.message || ''}`,
                    okText: '확인',
                })
            }
        }
        return (
            <>
                <Row>
                    <Col xs={24} xl={12}>
                        <Typography.Title level={3} style={{...TextStyle}}>{name}</Typography.Title>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Typography.Title level={3} style={{
                            ...TextStyle,
                            textAlign: 'right'
                        }}>{moneyValue(price)}원</Typography.Title>
                    </Col>
                    <Space direction={'vertical'}>
                        {isMultiple && (
                            <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 500}}>
                                2개 이상 주문 가능
                                {!isDisplay && (
                                    <Typography.Text
                                        style={{
                                            ...TextStyle,
                                            marginLeft: 10,
                                            background: Color.liner,
                                            padding: 3,
                                            fontSize: 16,
                                            fontWeight: 500
                                        }}
                                    >고객에게 비노출</Typography.Text>
                                )}
                            </Typography.Text>
                        )}

                    </Space>
                </Row>
                <Row>
                    <Input.TextArea
                        disabled
                        autoSize
                        style={{...CommonStyle.inputStyle, minHeight: 120}}
                        value={description}
                    />
                </Row>
                <Row>
                    <Space style={{marginTop: 10, width: '100%', justifyContent: 'flex-end'}}>
                        <Button icon={<EditOutlined/>}
                                onClick={() => navigate(PageRoutes.EDIT_ACCOMMODATION_PAID_FACILITY, {
                                    state: {id, name, price, description, isMultiple, isDisplay,}
                                })}/>
                        <Button
                            icon={<DeleteOutlined/>}
                            onClick={() => {
                                modal.confirm({title: '유료 이용 시설을 삭제하시겠습니까?', onOk: onClickDelete})
                            }}
                        />
                    </Space>
                </Row>
                <div style={{marginTop: 30, width: '100%', height: 1, backgroundColor: '#E5E5E7'}}/>

                {contextHolder}
            </>
        )
    }

    const onPressRegisterButton = enabledTab === 'basic' ?
        () => NavigationService.moveTo(PageRoutes.EDIT_ACCOMMODATION_BASIC_FACILITY) :
        () => NavigationService.moveTo(PageRoutes.REGISTER_ACCOMMODATION_PAID_FACILITY)

    const basicFacilityMap = _.groupBy(basicFacilities, (b) => b.type)

    return (
        <>
            <Space style={{marginTop: 40}}>
                <Button
                    style={enableTabStyle('basic')}
                    onClick={() => setEnabledTab('basic')}
                >기본 시설</Button>
                <Button
                    style={enableTabStyle('paid')}
                    onClick={() => setEnabledTab('paid')}
                >유료 시설</Button>
            </Space>

            {enabledTab === 'basic' ? (
                <>
                    <Typography.Title level={4} style={{...TextStyle, fontSize: 18, fontWeight: 400,}}>
                        기본으로 제공하는 이용시설을 등록하면, 객실을 등록할 때 객실 정보로 추가할 수 있어요.
                    </Typography.Title>
                    <BasicTypeComponent
                        type={'주변'}
                        labels={basicFacilityMap['주변']?.map(b => b.name) || []}
                    />
                    <BasicTypeComponent
                        type={'침실'}
                        labels={basicFacilityMap['침실']?.map(b => b.name) || []}
                    />
                    <BasicTypeComponent
                        type={'주방'}
                        labels={basicFacilityMap['주방']?.map(b => b.name) || []}
                    />
                    <BasicTypeComponent
                        type={'욕실'}
                        labels={basicFacilityMap['욕실']?.map(b => b.name) || []}
                    />
                    <BasicTypeComponent
                        type={'냉/난방'}
                        labels={basicFacilityMap['냉/난방']?.map(b => b.name) || []}
                    />
                    <BasicTypeComponent
                        type={'엔터테인먼트'}
                        labels={basicFacilityMap['엔터테인먼트']?.map(b => b.name) || []}
                    />
                    <BasicTypeComponent
                        type={'주차'}
                        labels={basicFacilityMap['주차']?.map(b => b.name) || []}
                    />
                    <BasicTypeComponent
                        type={'기타'}
                        labels={basicFacilityMap['기타']?.map(b => b.name) || []}
                    />
                </>
            ) : (
                <>
                    <Typography.Title level={4} style={{...TextStyle, fontSize: 18, fontWeight: 400,}}>
                        수영장, 바베큐 등 유료 이용시설을 등록하면, 객실을 등록할 때 판매 옵션으로 추가할 수 있어요.
                    </Typography.Title>
                    <EmptySpace size={40}/>
                    {paidFacilities.map((p: any) =>
                        <PaidTypeComponent
                            id={p.id}
                            name={p.name}
                            price={p.price}
                            description={p.description}
                            isMultiple={p.isMultiple}
                            isDisplay={p.isDisplay}
                        />
                    )}
                </>
            )}

            <Row style={{...CommonStyle.registerButtonContainerStyle, marginTop: 80}}>
                <RegisterButton
                    onClick={onPressRegisterButton}
                    icon={enabledTab === 'basic' ? <EditOutlined/> : <PlusOutlined/>}
                    label={enabledTab === 'basic' ? '수정하기' : '추가하기'}
                />
            </Row>
        </>
    )
}

const PolicyInfo = ({usagePolicy, refundPolicy, refundPercentages = []}: {
    usagePolicy: string,
    refundPolicy: string,
    refundPercentages: any
}) => {
    const [enabledTab, setEnabledTab] = useState('usage')

    const extendedInnerTopTabStyle = {
        ...innerTopTabStyle,
        width: 87,
        height: 40,
    }
    const enableTabStyle = (type: string) => enabledTab === type ? extendedInnerTopTabStyle : {
        ...extendedInnerTopTabStyle,
        backgroundColor: Color.white,
        borderColor: '#E5E5E7',
    }

    interface CancelFeeDataType {
        key: string;
        id: number;
        day: string;
        cancelFeePercentage: string;
        refundPercentage: string;
    }

    const cancelFeeColumns: ColumnsType<CancelFeeDataType> = [
        {
            title: '기준일',
            dataIndex: 'day',
            key: 'day',
        },
        {
            title: '취소 수수료',
            dataIndex: 'cancelFeePercentage',
            key: 'cancelFeePercentage',
        },
        {
            title: '환불율',
            dataIndex: 'refundPercentage',
            key: 'refundPercentage',
        },
    ]

    const cancelFeeDataSources: CancelFeeDataType[] = refundPercentages.map((p: any) => ({
        key: `
            refund - percentage -${p.id}`,
        id: p.id,
        day: p.day,
        cancelFeePercentage: `${p.cancelFeePercentage} % `,
        refundPercentage: `${p.refundPercentage} % `,
    }))

    const onPressRegisterButton = enabledTab === 'usage' ?
        () => NavigationService.moveTo(PageRoutes.EDIT_ACCOMMODATION_USAGE_POLICY) :
        () => NavigationService.moveTo(PageRoutes.EDIT_ACCOMMODATION_REFUND_POLICY)

    return (
        <>
            <Space style={{marginTop: 40}}>
                <Button
                    style={enableTabStyle('usage')}
                    onClick={() => setEnabledTab('usage')}
                >이용 정책</Button>
                <Button
                    style={enableTabStyle('refund')}
                    onClick={() => setEnabledTab('refund')}
                >환불 정책</Button>
            </Space>

            {enabledTab === 'usage' ? (
                <>
                    <Typography.Title level={4} style={{...TextStyle, fontSize: 18, fontWeight: 400,}}>
                        고객 사이트의 객실 정보, 약관 동의에서 고객에게 노출되는 정보입니다.
                    </Typography.Title>
                    <Row style={{marginTop: 20}}>
                        <Input.TextArea
                            autoSize
                            disabled
                            style={CommonStyle.inputStyle}
                            value={usagePolicy}
                        />
                    </Row>
                </>
            ) : (
                <>
                    <Typography.Title level={4} style={{...TextStyle, fontSize: 18, fontWeight: 400,}}>
                        고객 사이트의 객실 정보, 약관 동의에서 고객에게 노출되는 정보입니다.
                    </Typography.Title>
                    <Row style={{marginTop: 20}}>
                        <Input.TextArea
                            autoSize
                            disabled
                            style={CommonStyle.inputStyle}
                            value={refundPolicy}
                        />
                    </Row>
                    <Row>
                        <Space direction={'vertical'} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                취소 수수료
                            </Typography.Text>
                            <Table
                                style={{marginTop: 10}}
                                columns={cancelFeeColumns}
                                dataSource={cancelFeeDataSources}
                                pagination={false}
                            />
                        </Space>
                    </Row>
                </>
            )}

            <Row style={{...CommonStyle.registerButtonContainerStyle, marginTop: 80}}>
                <RegisterButton
                    onClick={onPressRegisterButton}
                    icon={<EditOutlined/>}
                    label={'수정하기'}
                />
            </Row>
        </>
    )
}

const TopTabs = ({DefaultInfo, ContentsInfo, TimeInfo, PriceInfo, FacilityInfo, PolicyInfo}: {
    DefaultInfo: React.ReactNode,
    ContentsInfo: ReactNode,
    TimeInfo: ReactNode,
    PriceInfo: ReactNode,
    FacilityInfo: ReactNode,
    PolicyInfo: ReactNode
}) => {
    const TabLabel = ({label}: { label: string }) => <div style={{width: 120, textAlign: 'center'}}>{label}</div>
    const tabs: any = [
        {
            key: "0",
            label: <TabLabel label={'기본 정보'}/>,
            children: DefaultInfo,
        },
        {
            key: "1",
            label: <TabLabel label={'사진/동영상'}/>,
            children: ContentsInfo,
        },
        {
            key: "2",
            label: <TabLabel label={'입실/퇴실'}/>,
            children: TimeInfo,
        },
        {
            key: "3",
            label: <TabLabel label={'숙박 요금'}/>,
            children: PriceInfo,
        },
        {
            key: "4",
            label: <TabLabel label={'이용시설'}/>,
            children: FacilityInfo,
        },
        {
            key: "5",
            label: <TabLabel label={'이용/환불 정책'}/>,
            children: PolicyInfo,
        },
    ]

    return (
        <Tabs
            style={{marginTop: 20}}
            tabBarStyle={{...TextStyle, fontWeight: 500, fontSize: 18, color: Color.darkPrimary}}
            defaultActiveKey={"0"}
            items={tabs}
        />
    )
}

export default withPolicies(AccommodationScreen)
