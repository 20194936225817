// @ts-nocheck
import {useLocation} from "react-router-dom";
import {Modal} from "antd";
import NavigationService from "../../../services/NavigationService";
import CookieService from "../../../services/CookieService";
import {useEffect, useState} from "react";

const TokenScreen = ({}) => {
    const [modal, contextHolder] = Modal.useModal()

    const location = useLocation()
    const params = new URLSearchParams(location.search)

    const [authToken] = useState(params.get('authToken'))
    const [refreshToken] = useState(params.get('refreshToken'))

    useEffect(() => {
        if (!authToken || !refreshToken) {
            modal.error({content: '잘못된 접근입니다!', okText: '확인', onOk: () => NavigationService.moveToMain()})
        } else {
            CookieService.setAccessToken(authToken)
            CookieService.setRefreshToken(refreshToken)

            NavigationService.moveToMain()
        }
    }, [])

    return (
        <>
            {contextHolder}
        </>
    )
}

export default TokenScreen
