import React, {useEffect, useState} from "react";
import ScreenOuter from "../../../components/ScreenOuter";
import {useDispatch, useSelector} from "react-redux";
import {requestAccommodations, requestUpdateAccommodation} from "../../../redux/Accommodation";
import {Navigate} from "react-router-dom";
import ScreenGrid from "../../../components/ScreenGrid";
import {message, notification, Space, Typography} from "antd";
import {TextStyle} from "../../../constants/style";
import {DefaultInfo, TimeInfo} from "../AccommodationScreen";
import {PageRoutes} from "../../../App";
import NavigationService from "../../../services/NavigationService";
import withPolicies from "../../../components/withPolicies";
import {MANAGER_CONTACT} from "../../../constants/content";

const EditAccommodationDefaultInfoScreen = () => {
    const dispatch = useDispatch()
    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationStatus = useSelector(state => state.Accommodation.get('status'))
    // @ts-ignore
    const accommodationAddress = useSelector(state => state.Accommodation.get('address'))
    // @ts-ignore
    const accommodationAddressDetail = useSelector(state => state.Accommodation.get('addressDetail'))
    // @ts-ignore
    const accommodationBizNumber = useSelector(state => state.Accommodation.get('bizNumber'))
    // @ts-ignore
    const accommodationBankName = useSelector(state => state.Accommodation.get('bankName'))
    // @ts-ignore
    const accommodationAccountNumber = useSelector(state => state.Accommodation.get('accountNumber'))
    // @ts-ignore
    const accommodationAccountHolder = useSelector(state => state.Accommodation.get('accountHolder'))
    // @ts-ignore
    const accommodationOfficePhoneNumber = useSelector(state => state.Accommodation.get('officePhoneNumber'))
    // @ts-ignore
    const accommodationDescription = useSelector(state => state.Accommodation.get('description'))
    // @ts-ignore
    const accommodationCheckInTime = useSelector(state => state.Accommodation.get('checkInTime'))
    // @ts-ignore
    const accommodationCheckOutTime = useSelector(state => state.Accommodation.get('checkOutTime'))

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [addressDetail, setAddressDetail] = useState('')
    const [description, setDescription] = useState('')

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    useEffect(() => {
        setName(accommodationName)
        setAddress(accommodationAddress)
        setAddressDetail(accommodationAddressDetail)
        setDescription(accommodationDescription)
    }, [accommodationName, accommodationAddress, accommodationAddressDetail, accommodationDescription])


    if (accommodationStatus === 'PENDING') {
        return <Navigate to={PageRoutes.REGISTER_ACCOMMODATIONS}/>
    }

    const onPressUpdate = async () => {
        if (!name) {
            message.error('숙소 이름을 입력해주세요.')
            return
        }

        // @ts-ignore
        const result = await dispatch(requestUpdateAccommodation(
            accommodationId,
            name,
            description,
            accommodationCheckInTime,
            accommodationCheckOutTime));

        if (result) {
            notification.success({
                message: '입실/퇴실 수정에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>기본 정보 수정</Typography.Title>
                </Space>

                <Typography.Text style={{...TextStyle}}>주소, 입금 계좌 정보, 대표 전화번호를 수정하시려면 고객센터{MANAGER_CONTACT}로 문의해주세요.</Typography.Text>

                <DefaultInfo
                    accommodationName={name}
                    accommodationAddress={address}
                    accommodationAddressDetail={addressDetail}
                    accommodationBizNumber={accommodationBizNumber}
                    accommodationBankName={accommodationBankName}
                    accommodationAccountNumber={accommodationAccountNumber}
                    accommodationAccountHolder={accommodationAccountHolder}
                    accommodationOfficeNumber={accommodationOfficePhoneNumber}
                    accommodationDescription={description}
                    onChangeName={value => setName(value)}
                    onChangeDescription={value => setDescription(value)}
                    onPressRegisterButton={onPressUpdate}
                />
            </ScreenGrid>
        </ScreenOuter>
    )
}

export default withPolicies(EditAccommodationDefaultInfoScreen)
