import {MenuProps} from "antd";

export const BankList: MenuProps['items'] = [
    {
        label: 'KB국민',
        key: 'KB국민',
    },
    {
        label: '신한',
        key: '신한',
    },
    {
        label: '우리',
        key: '우리',
    },
    {
        label: '토스뱅크',
        key: '토스뱅크',
    },
    {
        label: 'IBK기업',
        key: 'IBK기업',
    },
    {
        label: '하나',
        key: '하나',
    },
    {
        label: '농협',
        key: '농협',
    },
    {
        label: '카카오뱅크',
        key: '카카오뱅크',
    },
    {
        label: '새마을금고',
        key: '새마을금고',
    },
    {
        label: '부산은행',
        key: '부산은행',
    },
    {
        label: '케이뱅크',
        key: '케이뱅크',
    },
    {
        label: '신협',
        key: '신협',
    },
    {
        label: '우체국',
        key: '우체국',
    },
    {
        label: 'SC제일',
        key: 'SC제일',
    },
    {
        label: '경남',
        key: '경남',
    },
    {
        label: '광주',
        key: '광주',
    },
    {
        label: '수협',
        key: '수협',
    },
    {
        label: '전북은행',
        key: '전북은행',
    },
    {
        label: '제주',
        key: '제주',
    },
    {
        label: '씨티',
        key: '씨티',
    },
    {
        label: '저축은행',
        key: '저축은행',
    },
    {
        label: 'KDB산업',
        key: 'KDB산업',
    },
    {
        label: '산림조합',
        key: '산림조합',
    },
    {
        label: 'SBI저축은행',
        key: 'SBI저축은행',
    },
    {
        label: 'BOA',
        key: 'BOA',
    },
    {
        label: '중국',
        key: '중국',
    },
    {
        label: 'HSBC',
        key: 'HSBC',
    },
    {
        label: '중국공상',
        key: '중국공상',
    },
    {
        label: '도이치',
        key: '도이치',
    },
    {
        label: 'JP모건',
        key: 'JP모건',
    },
    {
        label: 'BNP파리바',
        key: 'BNP파리바',
    },
];
