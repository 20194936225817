import ApiService from "../../services/ApiService";

class AccommodationApi {
    static register(
        name: string,
        address: string,
        addressDetail: string,
        latitude: string,
        longitude: string,
        officePhoneNumber: string,
        bizNumber: string,
        bizRegistrationFilePath: string,
        bankName: string,
        accountNumber: string,
        accountHolder: string) {

        const path = '/accommodations'
        const body = {
            name,
            address,
            addressDetail,
            latitude,
            longitude,
            officePhoneNumber,
            bizNumber,
            bizRegistrationFilePath,
            bankName,
            accountNumber,
            accountHolder,
        }

        return ApiService.post(path, body, true)
    }

    static get() {
        const path = '/accommodations'
        return ApiService.get(path, true)
    }

    static update(accommodationId: number, name: string, description: string, checkInTime: string, checkOutTime: string) {
        const path = `/accommodations/${accommodationId}`
        const body = {
            name,
            description,
            checkInTime,
            checkOutTime,
        }
        return ApiService.patch(path, body, true)
    }

    static createSeason(accommodationId: number, name: string, type: string, startDate: string, endDate: string) {
        let typeName
        switch (type) {
            case '성수기':
                typeName = 'HIGH'
                break
            case '준성수기':
                typeName = 'MIDDLE'
                break
            case '비수기':
                typeName = 'LOW'
                break
            case '특별 요금 기간':
                typeName = 'SPECIAL'
                break
            case '할인 요금 기간':
                typeName = 'DISCOUNT'
                break
            default:
                typeName = 'BASIC'
        }

        const path = `/accommodations/${accommodationId}/seasons`
        const body = {
            name,
            type: typeName,
            startDate,
            endDate,
        }
        return ApiService.post(path, body, true)
    }

    static updateSeason(accommodationId: number, seasonId: number, name: string, type: string, startDate: string, endDate: string) {
        let typeName
        switch (type) {
            case '성수기':
                typeName = 'HIGH'
                break
            case '준성수기':
                typeName = 'MIDDLE'
                break
            case '비수기':
                typeName = 'LOW'
                break
            case '특별 요금 기간':
                typeName = 'SPECIAL'
                break
            case '할인 요금 기간':
                typeName = 'DISCOUNT'
                break
            default:
                typeName = 'BASIC'
        }

        const path = `/accommodations/${accommodationId}/seasons/${seasonId}`
        const body = {
            name,
            type: typeName,
            startDate,
            endDate,
        }
        return ApiService.patch(path, body, true)
    }

    static deleteSeason(accommodationId: number, seasonId: number) {
        const path = `/accommodations/${accommodationId}/seasons/${seasonId}`
        return ApiService.delete(path, true)
    }

    static createPrices(accommodationId: number, prices: []) {
        const path = `/accommodations/${accommodationId}/prices`
        const body = {
            prices: prices,
        }
        return ApiService.post(path, body, true)
    }

    static updatePrices(accommodationId: number, prices: []) {
        const path = `/accommodations/${accommodationId}/prices`
        const body = {prices}
        return ApiService.patch(path, body, true)
    }

    static deletePrices(accommodationId: number, priceName: string) {
        const path = `/accommodations/${accommodationId}/prices/delete`
        const body = {priceName}
        return ApiService.patch(path, body, true)
    }

    static createBasicFacilities(accommodationId: number, basicFacilities: []) {
        const path = `/accommodations/${accommodationId}/basic-facilities`
        const body = {
            basicFacilities: basicFacilities,
        }
        return ApiService.post(path, body, true)
    }

    static createPaidFacility(
        accommodationId: number,
        name: string,
        description: string,
        price: string,
        isMultiple: boolean,
        isDisplay: boolean
    ) {
        const path = `/accommodations/${accommodationId}/paid-facilities`
        const body = {
            name,
            description,
            price,
            multipleYn: isMultiple ? 'Y' : 'N',
            displayYn: isDisplay ? 'Y' : 'N',
        }
        return ApiService.post(path, body, true)
    }

    static updatePaidFacility(
        accommodationId: number,
        paidFacilityId: number,
        name: string,
        description: string,
        price: string,
        isMultiple: boolean,
        isDisplay: boolean
    ) {
        const path = `/accommodations/${accommodationId}/paid-facilities/${paidFacilityId}`
        const body = {
            name,
            description,
            price,
            multipleYn: isMultiple ? 'Y' : 'N',
            displayYn: isDisplay ? 'Y' : 'N',
        }
        return ApiService.patch(path, body, true)
    }

    static updateUsagePolicy(accommodationId: number, policy: string) {
        const path = `/accommodations/${accommodationId}/usage-policies`
        const body = {
            policy,
        }
        return ApiService.patch(path, body, true)
    }

    static updateRefundPolicy(accommodationId: number, updateRefundPolicy: any) {
        const path = `/accommodations/${accommodationId}/refund-policies`
        return ApiService.patch(path, updateRefundPolicy, true)
    }

    static deletePaidFacility(accommodationId: number, paidFacilityId: number) {
        const path = `/accommodations/${accommodationId}/paid-facilities/${paidFacilityId}`
        return ApiService.delete(path, true)
    }
}

export default AccommodationApi
