import React, {useEffect, useState} from "react";
import {Layout, Button, Drawer, Typography, Spin} from "antd";
import SideMenu from "./SideMenu";
import RightMenu from "./RightMenu";
import {MenuOutlined} from "@ant-design/icons";
import {useLocation} from "react-router-dom";

import './navbar.css'
import TopStatusBoard from "../TopStatusBoard";
import {Color, TextStyle} from "../../constants/style";
import {Content} from "antd/es/layout/layout";
import {useSelector} from "react-redux";
import EmptySpace from "../EmptySpace";
import LocalStorageService from "../../services/LocalStorageService";

const ScreenOuter = ({accommodationName, ...props}: { accommodationName?: String, children?: any }) => {
    if (!accommodationName) {
        accommodationName = LocalStorageService.findAccommodationName()
    }

    const [visible, setVisible] = useState(false);
    const isLoadingAccommodation = useSelector((state: any) => state.Loading.get('isLoadingAccommodation'))
    const isLoadingRoom = useSelector((state: any) => state.Loading.get('isLoadingRoom'))
    const isLoadingReservation = useSelector((state: any) => state.Loading.get('isLoadingReservation'))

    const {pathname: location} = useLocation();

    useEffect(() => {
        setVisible(false);
    }, [location]);

    const showDrawer = () => {
        setVisible(!visible);
    };

    if (isLoadingAccommodation || isLoadingRoom || isLoadingReservation) {
        return <Spin/>
    }

    return (
        <Layout className={"navBar"}>
            <Layout.Header className="nav-header">
                <div className="logo">
                    <Typography.Title level={3}
                                      style={TextStyle}>{accommodationName || 'Staytwice'}</Typography.Title>
                </div>
                <div className="navbar-menu">
                    {/*<div className="nav-status-board">*/}
                    {/*    <TopStatusBoard/>*/}
                    {/*</div>*/}
                    <Button className="menuButton" type="text" onClick={showDrawer}>
                        <MenuOutlined/>
                    </Button>
                    <div className="rightMenu">
                        <RightMenu mode={"horizontal"}/>
                    </div>

                    <Drawer
                        title={accommodationName || 'Staytwice'}
                        placement="right"
                        closable={true}
                        onClose={showDrawer}
                        visible={visible}
                        style={{zIndex: 99999}}
                        width={240}
                    >
                        <Layout style={{background: 'none'}}>
                            <SideMenu
                                containerStyle={mobileSideMenuContainerStyle}
                                menuStyle={mobileSideMenuInnerStyle}
                                mode={"inline"}
                            />
                        </Layout>
                        <EmptySpace/>
                        <RightMenu mode={"inline"}/>
                    </Drawer>
                </div>
            </Layout.Header>
            <Layout>
                <div className={'pc-side-menu'}>
                    <SideMenu
                        containerStyle={sideMenuContainerStyle}
                        menuStyle={sideMenuInnerStyle}
                        titleIndent={20}
                        mode={"inline"}
                    />
                </div>
                <Content style={{backgroundColor: Color.white}}>{props.children}</Content>
            </Layout>
            <EmptySpace/>
        </Layout>
    );
};

const sideMenuContainerStyle = {
    height: '100vh',
    backgroundColor: Color.b1,
    padding: 10,
}

const sideMenuInnerStyle = {
    paddingTop: 10,
    height: '100%',
    backgroundColor: Color.b1,
    borderInlineEnd: 0,
}

const mobileSideMenuContainerStyle = {
    background: Color.white,
    backgroundColor: Color.white,
    borderWidth: 0,
}

const mobileSideMenuInnerStyle = {
    width: '100%',
    background: Color.white,
    backgroundColor: Color.white,
    borderInlineEnd: 0,
}

export default ScreenOuter;
