import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {
    Button,
    Col,
    DatePicker,
    Dropdown,
    Input,
    MenuProps,
    message,
    Modal,
    notification,
    Row,
    Space,
    Typography
} from "antd";
import {TextStyle} from "../../../constants/style";
import {CommonStyle} from "../AccommodationScreen/style";
import RequiredFieldMark from "../../../components/RequiredFieldMark";
import {DownOutlined, EditOutlined} from "@ant-design/icons";
import dayjs from "dayjs";
import RegisterButton from "../../../components/RegisterButton";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {requestAccommodations, requestUpdateSeason} from "../../../redux/Accommodation";
import {useLocation} from "react-router-dom";
import {PageRoutes} from "../../../App";
import NavigationService from "../../../services/NavigationService";
import withPolicies from "../../../components/withPolicies";


const SeasonTypeList: MenuProps['items'] = [
    {
        label: '기본',
        key: '기본',
    },
    {
        label: '성수기',
        key: '성수기',
    },
    {
        label: '준성수기',
        key: '준성수기',
    },
    {
        label: '비수기',
        key: '비수기',
    },
    {
        label: '특별 요금 기간',
        key: '특별 요금 기간',
    },
    {
        label: '할인 요금 기간',
        key: '할인 요금 기간',
    },
]

const EditAccommodationSeasonScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()
    const location = useLocation()
    const periodArray = location.state?.period?.split('~')

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))


    // @ts-ignore
    const [seasonName, setSeasonName] = useState(location.state?.seasonName || '')
    // @ts-ignore
    const [typeName, setTypeName] = useState(location.state?.type || '')
    // @ts-ignore
    const [seasonStartDate, setSeasonStartDate] = useState('')
    // @ts-ignore
    const [seasonEndDate, setSeasonEndDate] = useState('')

    const format = (
        typeName === '성수기' ||
        typeName === '준성수기' ||
        typeName === '비수기') ? "MM-DD" : "YYYY-MM-DD"

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())

        if (periodArray.length > 0) {
            setSeasonStartDate(periodArray[0]?.trim())
            setSeasonEndDate(periodArray[1]?.trim())
        }
    }, [])

    const handleMenuClick: MenuProps['onClick'] = (e) => setTypeName(e.key)
    const menuProps = {
        items: SeasonTypeList,
        selectable: true,
        onClick: handleMenuClick
    };

    const onPressUpdateButton = async () => {
        // @ts-ignore
        const result = await dispatch(requestUpdateSeason(
            accommodationId,
            location.state?.id,
            seasonName,
            typeName,
            dayjs(seasonStartDate).format('YYYY-MM-DD'),
            dayjs(seasonEndDate).format('YYYY-MM-DD')))

        if (result.success) {
            notification.success({
                message: '시즌 수정에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        } else {
            modal.error({
                title: `시즌 수정에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>시즌 수정</Typography.Title>
                </Space>
                <Row>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                시즌 이름 <RequiredFieldMark/>
                            </Typography.Text>
                            <Input
                                style={CommonStyle.enabledInputStyle}
                                placeholder={'예시: 7월 여름 성수기'}
                                value={seasonName}
                                onChange={e => setSeasonName(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                타입 <RequiredFieldMark/>
                            </Typography.Text>
                            <Dropdown
                                overlayStyle={CommonStyle.dropdownOverlayStyle}
                                menu={menuProps}
                            >
                                <Button
                                    style={{
                                        ...CommonStyle.enabledInputStyle,
                                        textAlign: 'start',
                                    }}
                                    size='large'
                                >
                                    {typeName || '타입 선택'}
                                    <DownOutlined style={{position: 'absolute', right: 15, marginTop: 5}}/>
                                </Button>
                            </Dropdown>
                        </Space>
                    </Col>
                    <Col xs={0} xl={24}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                적용 기간 <RequiredFieldMark/>
                            </Typography.Text>
                            <DatePicker.RangePicker
                                format={format}
                                value={[dayjs(seasonStartDate) || null, dayjs(seasonEndDate) || null]}
                                style={{...CommonStyle.enabledInputStyle, maxWidth: 495}}
                                onChange={(_, formatStrings) => {
                                    setSeasonStartDate(formatStrings[0])
                                    setSeasonEndDate(formatStrings[1])
                                }}
                            />
                            <Typography.Text style={{...TextStyle, marginTop: 12, fontWeight: 400, fontSize: 16}}>
                                ※ 성수기, 준성수기, 비수기 타입의 적용 기간은 연도와 상관없이 매년 동일한 날짜에 적용됩니다.
                            </Typography.Text>
                        </Space>
                    </Col>
                    <Col xs={24} xl={0}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                적용 기간 <RequiredFieldMark/>
                            </Typography.Text>
                            <DatePicker
                                format={format}
                                allowClear={false}
                                value={seasonStartDate ? dayjs(seasonStartDate) : null}
                                disabledDate={date => dayjs(seasonEndDate).isBefore(date.format(format))}
                                style={CommonStyle.enabledInputStyle}
                                onChange={(_, dateString) => setSeasonStartDate(dateString)}
                            />
                            <DatePicker
                                format={format}
                                allowClear={false}
                                value={seasonEndDate ? dayjs(seasonEndDate) : null}
                                disabledDate={date => dayjs(seasonStartDate).isAfter(date.format(format))}
                                style={CommonStyle.enabledInputStyle}
                                onChange={(_, dateString) => setSeasonEndDate(dateString)}
                            />
                            <Typography.Text style={{...TextStyle, marginTop: 12, fontWeight: 400, fontSize: 16}}>
                                ※ 성수기, 준성수기, 비수기 타입의 적용 기간은 연도와 상관없이 매년 동일한 날짜에 적용됩니다.
                            </Typography.Text>
                        </Space>
                    </Col>
                </Row>
                <Row style={CommonStyle.registerButtonContainerStyle}>
                    <RegisterButton
                        disabled={(!seasonName || !typeName || !seasonStartDate || !seasonEndDate)}
                        icon={<EditOutlined/>}
                        label={'수정하기'}
                        onClick={onPressUpdateButton}
                    />
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

export default withPolicies(EditAccommodationSeasonScreen)
