import {Col, Input, message, Modal, notification, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {CommonStyle} from "../AccommodationScreen/style";
import React, {useEffect, useState} from "react";
import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {useDispatch, useSelector} from "react-redux";
import {requestAccommodations, requestUpdateRefundPolicy} from "../../../redux/Accommodation";
import _ from "lodash";
import RegisterButton from "../../../components/RegisterButton";
import {EditOutlined} from "@ant-design/icons";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import withPolicies from "../../../components/withPolicies";

const EditAccommodationRefundPolicyScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationRefundPolicy = useSelector(state => state.Accommodation.get('refundPolicy'))
    // @ts-ignore
    const accommodationRefundPercentages = useSelector(state => state.Accommodation.get('refundPercentages'))

    const [refundPolicy, setRefundPolicy] = useState('')

    useEffect(() => {
        if (accommodationRefundPolicy) {
            setRefundPolicy(accommodationRefundPolicy)
        }
    }, [accommodationRefundPolicy])

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    const refundPercentageMap: any = _.groupBy(accommodationRefundPercentages, r => r.day)
    Object.keys(refundPercentageMap)
        .forEach(day => {
            refundPercentageMap[day] = refundPercentageMap[day][0]
        })

    const [cancelFeePercentage1, setCancelFeePercentage1] = useState(0)
    const [cancelFeePercentage2, setCancelFeePercentage2] = useState(0)
    const [cancelFeePercentage3, setCancelFeePercentage3] = useState(0)
    const [cancelFeePercentage4, setCancelFeePercentage4] = useState(0)
    const [cancelFeePercentage5, setCancelFeePercentage5] = useState(0)
    const [cancelFeePercentage6, setCancelFeePercentage6] = useState(0)
    const [cancelFeePercentage7, setCancelFeePercentage7] = useState(0)
    const [cancelFeePercentage8, setCancelFeePercentage8] = useState(0)
    const [cancelFeePercentage9, setCancelFeePercentage9] = useState(0)
    const [cancelFeePercentage10, setCancelFeePercentage10] = useState(0)
    const [cancelFeePercentage11, setCancelFeePercentage11] = useState(0)
    const [cancelFeePercentage12, setCancelFeePercentage12] = useState(0)
    const [cancelFeePercentage13, setCancelFeePercentage13] = useState(0)
    const [cancelFeePercentage14, setCancelFeePercentage14] = useState(0)
    const [cancelFeePercentage15, setCancelFeePercentage15] = useState(0)
    const [cancelFeePercentage16, setCancelFeePercentage16] = useState(0)

    useEffect(() => {
        if (accommodationRefundPercentages && accommodationRefundPercentages.length >= 16) {
            setCancelFeePercentage1(refundPercentageMap['입실 당일'].cancelFeePercentage)
            setCancelFeePercentage2(refundPercentageMap['입실 1일 전'].cancelFeePercentage)
            setCancelFeePercentage3(refundPercentageMap['입실 2일 전'].cancelFeePercentage)
            setCancelFeePercentage4(refundPercentageMap['입실 3일 전'].cancelFeePercentage)
            setCancelFeePercentage5(refundPercentageMap['입실 4일 전'].cancelFeePercentage)
            setCancelFeePercentage6(refundPercentageMap['입실 5일 전'].cancelFeePercentage)
            setCancelFeePercentage7(refundPercentageMap['입실 6일 전'].cancelFeePercentage)
            setCancelFeePercentage8(refundPercentageMap['입실 7일 전'].cancelFeePercentage)
            setCancelFeePercentage9(refundPercentageMap['입실 8일 전'].cancelFeePercentage)
            setCancelFeePercentage10(refundPercentageMap['입실 9일 전'].cancelFeePercentage)
            setCancelFeePercentage11(refundPercentageMap['입실 10일 전'].cancelFeePercentage)
            setCancelFeePercentage12(refundPercentageMap['입실 11일 전'].cancelFeePercentage)
            setCancelFeePercentage13(refundPercentageMap['입실 12일 전'].cancelFeePercentage)
            setCancelFeePercentage14(refundPercentageMap['입실 13일 전'].cancelFeePercentage)
            setCancelFeePercentage15(refundPercentageMap['입실 14일 전'].cancelFeePercentage)
            setCancelFeePercentage16(refundPercentageMap['기본'].cancelFeePercentage)
        }
    }, [accommodationRefundPercentages])

    const calcRefundPercentage = (cancelFeePercentage: number = 0) => {
        cancelFeePercentage = cancelFeePercentage || 0
        let refundPercentage = 0

        try {
            refundPercentage = 100 - cancelFeePercentage
        } catch (e) {
            refundPercentage = 0
        }

        return refundPercentage
    }

    const onPressUpdate = async () => {
        const result = await dispatch(
            // @ts-ignore
            requestUpdateRefundPolicy(accommodationId, {
                policy: refundPolicy,
                refundPercentages: [
                    {day: '입실 당일', cancelFeePercentage: cancelFeePercentage1},
                    {day: '입실 1일 전', cancelFeePercentage: cancelFeePercentage2},
                    {day: '입실 2일 전', cancelFeePercentage: cancelFeePercentage3},
                    {day: '입실 3일 전', cancelFeePercentage: cancelFeePercentage4},
                    {day: '입실 4일 전', cancelFeePercentage: cancelFeePercentage5},
                    {day: '입실 5일 전', cancelFeePercentage: cancelFeePercentage6},
                    {day: '입실 6일 전', cancelFeePercentage: cancelFeePercentage7},
                    {day: '입실 7일 전', cancelFeePercentage: cancelFeePercentage8},
                    {day: '입실 8일 전', cancelFeePercentage: cancelFeePercentage9},
                    {day: '입실 9일 전', cancelFeePercentage: cancelFeePercentage10},
                    {day: '입실 10일 전', cancelFeePercentage: cancelFeePercentage11},
                    {day: '입실 11일 전', cancelFeePercentage: cancelFeePercentage12},
                    {day: '입실 12일 전', cancelFeePercentage: cancelFeePercentage13},
                    {day: '입실 13일 전', cancelFeePercentage: cancelFeePercentage14},
                    {day: '입실 14일 전', cancelFeePercentage: cancelFeePercentage15},
                    {day: '기본', cancelFeePercentage: cancelFeePercentage16},
                ],
            }))

        if (result.success) {
            notification.success({
                message: '환불 정책 수정에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        } else {
            modal.error({
                title: `환불 정책 수정에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>이용 정책 수정</Typography.Title>
                </Space>
                <Row style={{marginTop: 20}}>
                    <Col xs={24} xl={24}>
                        <Input.TextArea
                            autoSize
                            style={CommonStyle.enabledInputStyle}
                            value={refundPolicy}
                            onChange={e => setRefundPolicy(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: 40, width: '100%'}}>
                    <Typography.Text style={CommonStyle.labelStyle}>
                        취소 수수료
                    </Typography.Text>
                </Row>

                <RefundInput
                    label={'입실 당일'}
                    cancelFeePercentage={cancelFeePercentage1}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage1)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage1(fee || 0)}
                />
                <RefundInput
                    label={'입실 1일 전'}
                    cancelFeePercentage={cancelFeePercentage2}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage2)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage2(fee || 0)}
                />
                <RefundInput
                    label={'입실 2일 전'}
                    cancelFeePercentage={cancelFeePercentage3}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage3)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage3(fee || 0)}
                />
                <RefundInput
                    label={'입실 3일 전'}
                    cancelFeePercentage={cancelFeePercentage4}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage4)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage4(fee || 0)}
                />
                <RefundInput
                    label={'입실 4일 전'}
                    cancelFeePercentage={cancelFeePercentage5}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage5)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage5(fee || 0)}
                />
                <RefundInput
                    label={'입실 5일 전'}
                    cancelFeePercentage={cancelFeePercentage6}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage6)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage6(fee || 0)}
                />
                <RefundInput
                    label={'입실 6일 전'}
                    cancelFeePercentage={cancelFeePercentage7}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage7)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage7(fee || 0)}
                />
                <RefundInput
                    label={'입실 7일 전'}
                    cancelFeePercentage={cancelFeePercentage8}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage8)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage8(fee || 0)}
                />
                <RefundInput
                    label={'입실 8일 전'}
                    cancelFeePercentage={cancelFeePercentage9}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage9)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage9(fee || 0)}
                />
                <RefundInput
                    label={'입실 9일 전'}
                    cancelFeePercentage={cancelFeePercentage10}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage10)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage10(fee || 0)}
                />
                <RefundInput
                    label={'입실 10일 전'}
                    cancelFeePercentage={cancelFeePercentage11}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage11)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage11(fee || 0)}
                />
                <RefundInput
                    label={'입실 11일 전'}
                    cancelFeePercentage={cancelFeePercentage12}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage12)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage12(fee || 0)}
                />
                <RefundInput
                    label={'입실 12일 전'}
                    cancelFeePercentage={cancelFeePercentage13}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage13)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage13(fee || 0)}
                />
                <RefundInput
                    label={'입실 13일 전'}
                    cancelFeePercentage={cancelFeePercentage14}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage14)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage14(fee || 0)}
                />
                <RefundInput
                    label={'입실 14일 전'}
                    cancelFeePercentage={cancelFeePercentage15}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage15)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage15(fee || 0)}
                />
                <RefundInput
                    label={'기본'}
                    cancelFeePercentage={cancelFeePercentage16}
                    refundPercentage={calcRefundPercentage(cancelFeePercentage16)}
                    onChangeCancelFeePercentage={(fee: number) => setCancelFeePercentage16(fee || 0)}
                />
                <Row style={{...CommonStyle.registerButtonContainerStyle, marginTop: 80}}>
                    <RegisterButton
                        onClick={onPressUpdate}
                        icon={<EditOutlined/>}
                        label={'수정하기'}
                    />
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const RefundInput = (
    {
        label,
        cancelFeePercentage,
        refundPercentage,
        onChangeCancelFeePercentage,
    }: {
        label: string,
        cancelFeePercentage: number,
        refundPercentage: number,
        onChangeCancelFeePercentage: (percentage: number) => void,
    }) => {

    return (
        <Row style={{marginTop: 20, width: '100%'}}>
            <Col xs={24} xl={24}>
                <Typography.Title style={{...CommonStyle.labelStyle, fontSize: 16}}>
                    {label}
                </Typography.Title>
            </Col>
            <Col xs={24} xl={12} style={{marginTop: 6}}>
                <Space direction={"vertical"} style={{width: '100%', paddingRight: 10, marginTop: 3}}>
                    <Typography.Text style={{
                        ...CommonStyle.labelStyle,
                        fontSize: 16,
                        color: Color.darkTertiary
                    }}>
                        취소 수수료율 (%)
                    </Typography.Text>
                    <Input
                        style={{...CommonStyle.enabledInputStyle, marginTop: 3}}
                        placeholder={'0'}
                        type={'number'}
                        value={cancelFeePercentage}
                        onChange={e => {
                            if (parseInt(e.target.value) > 100 || parseInt(e.target.value) < 0) {
                                message.destroy('error-percentage')
                                message.error({key: 'error-percentage', content: '수수료율은 0~100 사이의 수만 입력할 수 있습니다.'})
                                return;
                            }

                            onChangeCancelFeePercentage(parseInt(e.target.value));
                        }}
                    />
                </Space>
            </Col>
            <Col xs={24} xl={12} style={{marginTop: 6}}>
                <Space direction={"vertical"} style={{width: '100%', paddingRight: 10, marginTop: 3}}>
                    <Typography.Text style={{
                        ...CommonStyle.labelStyle,
                        fontSize: 16,
                        color: Color.darkTertiary
                    }}>
                        환불율
                    </Typography.Text>
                    <Input
                        disabled
                        style={{...CommonStyle.inputStyle, marginTop: 3}}
                        placeholder={'0'}
                        value={refundPercentage}
                    />
                </Space>
            </Col>
        </Row>
    )
}

export default withPolicies(EditAccommodationRefundPolicyScreen)
