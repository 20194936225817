export const moneyValue = (value: string | number) => {
    if (value === 0) {
        return 0
    }

    if (!value) {
        return ''
    }

    return value.toString()
        .replaceAll(/\D+/g, '')
        .replaceAll(',', '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function isNumeric(str: string) {
    return /^\d+$/.test(str.replaceAll('-', ''));
}

export function formatPhoneNumber(phoneNumber: string) {
    phoneNumber = phoneNumber || ''
    const cleaned = ('' + phoneNumber).replace(/\D+/g, '')
    const match = cleaned.match(/^(\d{2,3})(\d{3,4})(\d{4})$/)

    if (match) {
        return `${match[1]}-${match[2]}-${match[3]}`
    }

    return cleaned
}
