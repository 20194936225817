import _ from "lodash";
import {Checkbox, Col, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {isMobile} from "react-device-detect";
import TextLiner from "../../../components/TextLiner";

const AccommodationBasicFacility = ({selectedBasicFacilityIds, basicFacilities}: {
    selectedBasicFacilityIds: number[],
    basicFacilities: any,
}) => {
    const basicFacilityMap = _.groupBy(basicFacilities, (b) => b.type)

    return (
        <Space direction={'vertical'}>
            <BasicFacilityType
                label={'주변'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['주변'] || []}
            />
            <BasicFacilityType
                label={'침실'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['침실'] || []}
            />
            <BasicFacilityType
                label={'주방'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['주방'] || []}
            />
            <BasicFacilityType
                label={'욕실'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['욕실'] || []}
            />
            <BasicFacilityType
                label={'냉/난방'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['냉/난방'] || []}
            />
            <BasicFacilityType
                label={'엔터테인먼트'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['엔터테인먼트'] || []}
            />
            <BasicFacilityType
                label={'주차'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['주차'] || []}
            />
            <BasicFacilityType
                label={'기타'}
                selectedBasicFacilityIds={selectedBasicFacilityIds}
                facilities={basicFacilityMap['기타'] || []}
            />
        </Space>
    )
}

interface BasicFacility {
    id: number,
    name: string,
}

const BasicFacilityType = ({label, facilities, selectedBasicFacilityIds}: {
    label: string,
    facilities: BasicFacility[],
    selectedBasicFacilityIds: number[]
}) => {
    return (
        <Space direction={'vertical'} style={{marginTop: 12}}>
            <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 500}}>{label}</Typography.Text>
            <Row style={{width: '100%'}}>
                <Col xs={24} xl={24}>
                    {facilities.map(facility => {
                        const checked = selectedBasicFacilityIds.includes(facility.id)
                        return (
                            <Checkbox
                                disabled
                                key={facility.id}
                                checked={checked}
                                style={{
                                    width: isMobile ? 140 : 200,
                                    marginLeft: 0,
                                    marginRight: 20,
                                    justifyContent: 'flex-start',
                                    overflow: 'visible',
                                }}
                            >
                                <TextLiner
                                    textStyle={{...TextStyle, fontSize: 16, fontWeight: 500, color: Color.darkTertiary}}
                                    enabled={checked} value={facility.name}/>
                            </Checkbox>
                        )
                    })}
                </Col>
            </Row>
        </Space>
    );
}

export default AccommodationBasicFacility
