import React, {useEffect, useRef, useState} from "react";
import './RegisterAccommodationScreen.css'
import TopNavigationBar from "../../../components/TopNavigationBar";
import {
    Button,
    Col,
    Dropdown,
    Input,
    MenuProps,
    message,
    Row,
    Typography,
    Card,
    Upload,
    UploadFile,
    UploadProps, notification, Space, Image
} from "antd";
import {DownOutlined} from "@ant-design/icons";
import {Color, TextStyle} from "../../../constants/style";
import {RcFile} from "antd/es/upload";
import NavigationService from "../../../services/NavigationService";
import AccommodationApi from "../../../apis/AccommodationApi";
import PostCodeModal from "../../../components/PostCodeModal";
import {Address} from "react-daum-postcode";
import {BankList} from "./constant";
import CenterGrid from "../../../components/CenterGrid";
import {requestPreSignedUrl} from "../../../redux/Content";
import {useDispatch, useSelector} from "react-redux";

import ProfileIcon from '../../../assets/icon/profile.png'
import RequiredFieldMark from "../../../components/RequiredFieldMark";
import {requestAccommodations} from "../../../redux/Accommodation";
import RightMenu from "../../../components/ScreenOuter/RightMenu";
import withPolicies from "../../../components/withPolicies";
import {MANAGER_CONTACT} from "../../../constants/content";

const RegisterAccommodationScreen = () => {
    const dispatch = useDispatch();

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationStatus = useSelector(state => state.Accommodation.get('status'))
    // @ts-ignore
    const accommodationOfficePhoneNumber = useSelector(state => state.Accommodation.get('officePhoneNumber'))
    // @ts-ignore
    const accommodationBizNumber = useSelector(state => state.Accommodation.get('bizNumber'))
    // @ts-ignore
    const accommodationAddress = useSelector(state => state.Accommodation.get('address'))
    // @ts-ignore
    const accommodationAddressDetail = useSelector(state => state.Accommodation.get('addressDetail'))
    // @ts-ignore
    const accommodationBankName = useSelector(state => state.Accommodation.get('bankName'))
    // @ts-ignore
    const accommodationAccountNumber = useSelector(state => state.Accommodation.get('accountNumber'))
    // @ts-ignore
    const accommodationAccountHolder = useSelector(state => state.Accommodation.get('accountHolder'))


    const [name, setName] = useState('')
    const [completedName, setCompletedName] = useState(false)
    const [address, setAddress] = useState('')
    const [addressDetail, setAddressDetail] = useState('')
    const [completedAddress, setCompletedAddress] = useState(false)
    const [officePhoneNumber, setOfficePhoneNumber] = useState('')
    const [completedOfficePhoneNumber, setCompletedOfficePhoneNumber] = useState(false)
    const [bizNumber, setBizNumber] = useState('')
    const [completedBizNumber, setCompletedBizNumber] = useState(false)
    const [bizRegistrationFilePath, setBizRegistrationFilePath] = useState('')
    const [completedBizRegistrationFilePath, setCompletedBizRegistrationFilePath] = useState(false)
    const [bankName, setBankName] = useState('')
    const [completedBankName, setCompletedBankName] = useState(false)
    const [accountNumber, setAccountNumber] = useState('')
    const [completedAccountNumber, setCompletedAccountNumber] = useState(false)
    const [accountHolder, setAccountHolder] = useState('')
    const [completedAccountHolder, setCompletedAccountHolder] = useState(false)

    const [preSignedUrl, setPreSignedUrl] = useState('')
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isOpenPostCodeModal, setIsOpenPostCodeModal] = useState(false)

    useEffect(() => {
        if (accommodationStatus === 'PENDING') {
            setName(accommodationName)
            setAddress(accommodationAddress)
            setAddressDetail(accommodationAddressDetail)
            setBizNumber(accommodationBizNumber)
            setOfficePhoneNumber(accommodationOfficePhoneNumber)
            setBankName(accommodationBankName)
            setAccountNumber(accommodationAccountNumber)
            setAccountHolder(accommodationAccountHolder)
        } else {
            // @ts-ignore
            dispatch(requestAccommodations())
        }
    }, [accommodationStatus])

    const isPending = accommodationStatus === 'PENDING'

    useEffect(() => {
        setCompletedName(!!name && name.length > 0)
        setCompletedAddress(!!address && address.length > 0)
        setCompletedOfficePhoneNumber(!!officePhoneNumber && officePhoneNumber.length > 0)
        setCompletedBizNumber(!!bizNumber && bizNumber.length > 0)
        setCompletedBizRegistrationFilePath(!!bizRegistrationFilePath && bizRegistrationFilePath.length > 0)
        setCompletedBankName(!!bankName && bankName.length > 0)
        setCompletedAccountNumber(!!accountNumber && accountNumber.length > 0)
        setCompletedAccountHolder(!!accountHolder && accountHolder.length > 0)
    }, [name, address, officePhoneNumber, bizNumber, bizRegistrationFilePath, bankName, accountNumber, accountHolder])

    useEffect(() => {
        if (preSignedUrl) {
            handleFileUpload(preSignedUrl)
        }
    }, [preSignedUrl])

    const handleMenuClick: MenuProps['onClick'] = (e) => setBankName(e.key)
    const menuProps = {
        items: BankList,
        selectable: true,
        onClick: handleMenuClick
    };

    const validateImageType = (file: RcFile) => file && (
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg');

    const handleBeforeUpload: UploadProps['beforeUpload'] = async (file, newFileList) => {
        if (!validateImageType(file)) {
            message.error(`${file.name} 은 등록 가능한 이미지 파일 형식이 아닙니다.`);
            return false
        }

        // @ts-ignore
        const preSignedUrl = await dispatch(requestPreSignedUrl('BIZ_REGISTRATION_FILE', file.name))
        // @ts-ignore
        setPreSignedUrl(preSignedUrl)
    }

    const handleFileChanged: UploadProps['onChange'] = ({file}) => {
        if (file.status === 'removed') {
            setFileList([]);
        } else if (file.status === 'uploading') {
            setFileList([file]);
        } else if (file.status === 'done') {
            setFileList([file]);
            notification.success({message: '파일이 성공적으로 업로드되었습니다.'});
        }
    };

    // Custom xhr upload method
    const handleFileUpload = (preSignedUrl: string) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', preSignedUrl)
        xhr.onreadystatechange = async () => {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    setBizRegistrationFilePath(new URL(preSignedUrl).pathname)

                    if (uploadRef && uploadRef.current) {
                        // @ts-ignore
                        uploadRef.current.onSuccess(null, fileList[0])
                    }
                } else {
                    message.error('파일 업로드에 실패했습니다.')
                    setFileList([])
                }
            }
        };
        xhr.send(fileList[0] as any);
    };

    const handleRegisterAccommodation = async () => {
        try {
            const {response, json} = await AccommodationApi.register(
                name,
                address,
                addressDetail,
                '37.123',
                '127.123',
                officePhoneNumber,
                bizNumber.replaceAll('-', ''),
                bizRegistrationFilePath,
                bankName,
                accountNumber,
                accountHolder);

            if (response.ok) {
                // 성공 팝업 확인 누르면 페이지 이동
                notification.success({message: '숙박시설이 성공적으로 등록되었습니다!'})
                NavigationService.moveToMain()
            } else {
                // 에러 처리
            }
        } catch (e) {
            console.error(e)
        }
    }

    const completedAllInputs = (
        completedName &&
        completedAddress &&
        completedOfficePhoneNumber &&
        completedBizNumber &&
        completedBizRegistrationFilePath &&
        completedBankName &&
        completedAccountNumber &&
        completedAccountHolder)

    const uploadRef = useRef(null);

    return (
        <div style={{backgroundColor: Color.white, paddingBottom: 50}}>
            <TopNavigationBar>
                <Row>
                    <Col xs={0} xl={4}></Col>
                    <Col xs={24} xl={16}>
                        <Space style={{width: '100%', justifyContent: 'space-between'}} onClick={() => NavigationService.moveToMain()}>
                            <Typography.Title level={3} style={TextStyle}>Staytwice</Typography.Title>
                            <RightMenu containerStyle={{marginTop: 10}}/>
                        </Space>
                    </Col>
                    <Col xs={0} xl={4}></Col>
                </Row>
            </TopNavigationBar>

            <CenterGrid>
                <Typography.Title level={1}
                                  style={{
                                      ...TextStyle,
                                      color: Color.darkBlue
                                  }}>{isPending ? '숙소 등록 중' : '숙소 등록'}</Typography.Title>
                {isPending ? (
                    <Typography.Text style={{...TextStyle, color: Color.darkPrimary, fontSize: '1rem'}}>
                        최대 2-3일 소요될 수 있습니다.<br/>
                        정보를 잘못 입력하신 경우, 관리자{MANAGER_CONTACT}에게 문의해주세요.
                    </Typography.Text>
                ) : (
                    <Typography.Text style={{...TextStyle, color: Color.darkPrimary, fontSize: '1rem'}}>* 로 표시된 필수 항목을
                        모두
                        입력해 주세요.</Typography.Text>
                )}

                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24} style={colStyle}>
                        <Card style={{...cardStyle, marginTop: 0}} bodyStyle={{padding: 0}}>
                            <Typography.Title level={3}
                                              style={{...TextStyle, color: Color.darkBlue}}>숙소 이름
                                <RequiredFieldMark disabled={isPending}/></Typography.Title>
                            <Input
                                disabled={isPending}
                                style={{marginTop: 10}}
                                value={name}
                                size={"large"}
                                placeholder="숙소 이름을 입력해주세요."
                                onChange={e => setName(e.target.value)}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} xl={24} style={colStyle}>
                        <Card style={cardStyle} bodyStyle={{padding: 0}}>
                            <Typography.Title level={3}
                                              style={{
                                                  ...TextStyle,
                                                  color: Color.darkBlue
                                              }}>주소<RequiredFieldMark disabled={isPending}/></Typography.Title>
                            <Row>
                                <Col xs={16} xl={20} style={{marginTop: 10}}>
                                    <Input
                                        disabled={isPending}
                                        size={"large"}
                                        value={address}
                                        placeholder="주소를 검색해주세요."
                                        inputMode={'none'}
                                        onChange={e => null}
                                        onClick={() => setIsOpenPostCodeModal(true)}
                                    />
                                </Col>
                                <Col xs={8} xl={4} style={{paddingLeft: 16, marginTop: 10}}>
                                    <Button className="frame-155-qQ3" id="128:21"
                                            onClick={() => setIsOpenPostCodeModal(true)}>주소
                                        검색</Button>
                                </Col>
                                {address && (
                                    <Col xs={24} xl={24} style={{marginTop: 10}}>
                                        <Input
                                            disabled={isPending}
                                            size={"large"}
                                            value={addressDetail}
                                            placeholder="상세 주소를 입력해 주세요."
                                            onChange={e => setAddressDetail(e.target.value)}
                                        />
                                    </Col>
                                )}
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} xl={24} style={colStyle}>
                        <Card style={cardStyle} bodyStyle={{padding: 0}}>
                            <Typography.Title level={3}
                                              style={{...TextStyle, color: Color.darkBlue}}>사업자
                                번호<RequiredFieldMark disabled={isPending}/></Typography.Title>
                            {!isPending && (
                                <Typography.Text color={"#2c2f4e"}>※ 사업자 번호 입력과 함께 사업자 등록증 사진을
                                    첨부해 주세요.</Typography.Text>
                            )}
                            <Input
                                disabled={isPending}
                                style={{marginTop: 20}}
                                value={bizNumber}
                                size={"large"}
                                maxLength={12}
                                placeholder="사업자 번호 입력"
                                onChange={e => setBizNumber(bizNumberFormatter(e.target.value.replace(/[^\d]/g, "")))}
                            />
                            {!isPending && (
                                <Row style={{marginTop: 10}}>
                                    <Col span={24}>
                                        <Upload
                                            disabled={isPending}
                                            ref={uploadRef}
                                            accept={"image/*"}
                                            name={'file'}
                                            fileList={fileList}
                                            beforeUpload={handleBeforeUpload}
                                            onChange={handleFileChanged}
                                        >
                                            {fileList.length > 0 ? null :
                                                <Button className="frame-155-6YF">파일 첨부</Button>}
                                        </Upload>
                                    </Col>
                                </Row>
                            )}
                        </Card>
                    </Col>
                    <Col xs={24} xl={24} style={colStyle}>
                        <Card style={{height: '100%', ...cardStyle}} bodyStyle={{padding: 0}}>
                            <Typography.Title level={3}
                                              style={{...TextStyle, color: Color.darkBlue}}>입금
                                계좌<RequiredFieldMark disabled={isPending}/></Typography.Title>
                            <Row>
                                <Col xs={24} xl={8}>
                                    <Dropdown
                                        disabled={isPending}
                                        overlayStyle={dropdownOverlayStyle}
                                        menu={menuProps}
                                    >
                                        <Button style={{
                                            width: '95%',
                                            display: "flex",
                                            justifyContent: "space-between",
                                        }} size='large'>
                                            {bankName || '은행 선택'}
                                            <DownOutlined style={{marginTop: 5}}/>
                                        </Button>
                                    </Dropdown>
                                </Col>
                                <Col xs={24} xl={16}>
                                    <Input
                                        disabled={isPending}
                                        size={"large"}
                                        value={accountNumber}
                                        type={'number'}
                                        placeholder="계좌번호 입력"
                                        onChange={e => setAccountNumber(e.target.value.replace(/[^\d|-]/g, ""))}
                                    />
                                </Col>
                            </Row>

                            <Input
                                disabled={isPending}
                                style={{marginTop: 10}}
                                value={accountHolder}
                                size={"large"}
                                placeholder="예금주 입력"
                                onChange={e => setAccountHolder(e.target.value)}
                            />
                        </Card>
                    </Col>
                    <Col xs={24} xl={24} style={colStyle}>
                        <Card style={cardStyle} bodyStyle={{padding: 0}}>
                            <Typography.Title
                                level={3}
                                style={{...TextStyle, color: Color.darkBlue}}>
                                대표 전화번호<RequiredFieldMark disabled={isPending}/>
                            </Typography.Title>
                            <Input
                                disabled={isPending}
                                style={{marginTop: 10, marginBottom: 20}}
                                value={officePhoneNumber}
                                maxLength={13}
                                size={"large"}
                                type={'number'}
                                placeholder="대표 전화번호 입력 (‘-’ 제외)"
                                onChange={e => setOfficePhoneNumber(e.target.value.replaceAll('-', ''))}
                            />
                            <Typography.Text style={{...TextStyle, color: Color.darkPrimary}}>
                                ※ 입력 예시 <br/>
                                &nbsp;&nbsp;- 유선전화번호 : 031-456-7890 <br/>
                                &nbsp;&nbsp;- 이동통신 전화번호 : 010-1234-5678 <br/>
                                &nbsp;&nbsp;- 대표 전화번호 : 1588-1234 <br/>
                                &nbsp;&nbsp;- 공통서비스 식별 번호 : 070-1234-5678 <br/>
                                &nbsp;&nbsp;- 최소 8자리 ~ 최대 11자리까지의 발신번호 입력 가능
                            </Typography.Text>
                        </Card>
                    </Col>
                </Row>
                {!isPending && (
                    <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                        <Button
                            style={completedAllInputs ? enabledRegisterButtonStyle : disabledRegisterButtonStyle}
                            disabled={!completedAllInputs}
                            onClick={handleRegisterAccommodation}
                        >
                            등록 신청
                        </Button>
                    </Space>
                )}
            </CenterGrid>
            <PostCodeModal
                isOpenModal={isOpenPostCodeModal}
                setIsOpenModal={setIsOpenPostCodeModal}
                onComplete={(data: Address) => setAddress(data.address)}
            />
        </div>
    );
}

const colStyle = {padding: 0}
const cardStyle = {
    marginTop: 20,
    borderRadius: 0,
    borderWidth: 0,
}
const enabledRegisterButtonStyle = {
    alignItems: 'center',
    backgroundColor: Color.darkBlue,
    borderColor: Color.darkBlue,
    borderRadius: 20,
    marginTop: 20,
    ...TextStyle,
    fontSize: 18,
    fontWeight: 700,
    color: Color.white,
    width: 162,
    height: 52,
}
const disabledRegisterButtonStyle = {
    alignItems: 'center',
    backgroundColor: Color.lightGrey,
    borderColor: Color.lightGrey,
    borderRadius: 20,
    marginTop: 20,
    ...TextStyle,
    fontSize: 18,
    fontWeight: 700,
    color: Color.textGrey,
    width: 162,
    height: 52,
}
const dropdownOverlayStyle = {
    maxHeight: 250,
    overflow: 'auto',
    padding: 1,
    borderRadius: 8,
    backgroundColor: Color.greyBorder
}

function bizNumberFormatter(bizNumber: string) {
    let formatNum = '';
    if (bizNumber.length === 10) {
        formatNum = bizNumber.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');
    } else {
        formatNum = bizNumber;
    }

    return formatNum;
}

export default RegisterAccommodationScreen;
