import React, {ReactComponentElement} from 'react';
import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AppBase from "./screens/AppBase";
import RegisterAccommodationScreen from "./screens/Accommodation/RegisterAccommodationScreen";
import ReservationScreen from "./screens/Reservation/ReservationScreen";
import AccommodationScreen from "./screens/Accommodation/AccommodationScreen";
import EditAccommodationTimeScreen from "./screens/Accommodation/EditAccommodationTimeScreen";
import EditAccommodationDefaultInfoScreen from "./screens/Accommodation/EditAccommodationDefaultInfoScreen";
import RegisterAccommodationSeasonScreen from "./screens/Accommodation/RegisterAccommodationSeasonScreen";

import NavigationService from "./services/NavigationService";
import CookieService from "./services/CookieService";
import RegisterAccommodationPriceScreen from "./screens/Accommodation/RegisterAccommodationPriceScreen";
import EditAccommodationBasicFacilityScreen from "./screens/Accommodation/EditAccommodationBasicFacilityScreen";
import RegisterAccommodationPaidFacilityScreen from "./screens/Accommodation/RegisterAccommodationPaidFacilityScreen";
import EditAccommodationUsagePolicyScreen from "./screens/Accommodation/EditAccommodationUsagePolicyScreen";
import EditAccommodationRefundPolicyScreen from "./screens/Accommodation/EditAccommodationRefundPolicyScreen";
import EditAccommodationPaidFacilityScreen from "./screens/Accommodation/EditAccommodationPaidFacilityScreen";
import EditAccommodationSeasonScreen from "./screens/Accommodation/EditAccommodationSeasonScreen";
import EditAccommodationPriceScreen from "./screens/Accommodation/EditAccommodationPriceScreen";
import RoomMainScreen from "./screens/Room/RoomMainScreen";
import RegisterRoomTypeScreen from "./screens/Room/RegisterRoomTypeScreen";
import RoomTypeScreen from "./screens/Room/RoomTypeScreen";
import RegisterRoomScreen from "./screens/Room/RegisterRoomScreen";
import EditRoomTypeScreen from "./screens/Room/EditRoomTypeScreen";
import RoomDetailScreen from "./screens/Room/RoomDetailScreen";
import MyPageScreen from "./screens/MyPageScreen";
import EditRoomDetailScreen from "./screens/Room/EditRoomDetailScreen";
import ReservationDetailScreen from "./screens/Reservation/ReservationDetailScreen";
import TokenScreen from "./screens/Authentication/TokenScreen";
import LoginScreen from "./screens/Auth/LoginScreen";

export const PageRoutes = {
    REGISTER_ACCOMMODATIONS: '/accommodations/register',

    // Reservations
    RESERVATIONS: '/reservations',
    RESERVATIONS_DETAIL: '/reservations/:id',

    // Accommodations
    ACCOMMODATIONS: '/accommodations',
    EDIT_ACCOMMODATION_TIME: '/accommodations/edit-time',
    EDIT_ACCOMMODATION_DEFAULT: '/accommodations/edit-default',
    EDIT_ACCOMMODATION_BASIC_FACILITY: '/accommodations/edit-basic-facility',
    EDIT_ACCOMMODATION_PAID_FACILITY: '/accommodations/edit-paid-facility',
    EDIT_ACCOMMODATION_USAGE_POLICY: '/accommodations/edit-usage-policy',
    EDIT_ACCOMMODATION_REFUND_POLICY: '/accommodations/edit-refund-policy',
    EDIT_ACCOMMODATION_SEASON: '/accommodations/edit-season',
    EDIT_ACCOMMODATION_PRICE: '/accommodations/edit-price',
    REGISTER_ACCOMMODATION_SEASON: '/accommodations/register-season',
    REGISTER_ACCOMMODATION_PRICE: '/accommodations/register-price',
    REGISTER_ACCOMMODATION_PAID_FACILITY: '/accommodations/register-paid-facility',

    // Rooms
    ROOMS: '/rooms',
    ROOM_DETAIL: '/rooms/:id',
    EDIT_ROOM_DETAIL: '/rooms/edit-room',
    REGISTER_ROOM: '/rooms/register-room',
    ROOM_TYPE: '/rooms/room-types/:id',
    REGISTER_ROOM_TYPE: '/rooms/register-room-type',
    EDIT_ROOM_TYPE: '/rooms/edit-room-type',

    MESSAGES: '/messages',
    STATISTICS: '/statistics',
    CUSTOMERS: '/customers',
    NOTICE: '/notice',
    ACCOUNTS: '/accounts',

    MY_PAGE: '/my-page',

    // Authentication
    TOKEN: '/token',
    LOGIN: '/login',

    // utils
    dynamicPath: (prefix: string, id: string) => `${prefix}/${id}`
}

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route index element={<AppBase/>}/>
                <Route path={PageRoutes.REGISTER_ACCOMMODATIONS}
                       element={<RegisterAccommodationScreen/>}/>

                {/* Accommodation */}
                <Route path={PageRoutes.ACCOMMODATIONS} element={<AccommodationScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_TIME}
                       element={<EditAccommodationTimeScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_DEFAULT}
                       element={<EditAccommodationDefaultInfoScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_BASIC_FACILITY}
                       element={<EditAccommodationBasicFacilityScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_PAID_FACILITY}
                       element={<EditAccommodationPaidFacilityScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_USAGE_POLICY}
                       element={<EditAccommodationUsagePolicyScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_REFUND_POLICY}
                       element={<EditAccommodationRefundPolicyScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_SEASON}
                       element={<EditAccommodationSeasonScreen/>}/>
                <Route path={PageRoutes.EDIT_ACCOMMODATION_PRICE}
                       element={<EditAccommodationPriceScreen/>}/>
                <Route path={PageRoutes.REGISTER_ACCOMMODATION_SEASON}
                       element={<RegisterAccommodationSeasonScreen/>}/>
                <Route path={PageRoutes.REGISTER_ACCOMMODATION_PRICE}
                       element={<RegisterAccommodationPriceScreen/>}/>
                <Route path={PageRoutes.REGISTER_ACCOMMODATION_PAID_FACILITY}
                       element={<RegisterAccommodationPaidFacilityScreen/>}/>

                {/* Room */}
                <Route path={PageRoutes.ROOMS} element={<RoomMainScreen/>}/>
                <Route path={PageRoutes.ROOM_DETAIL} element={<RoomDetailScreen/>}/>
                <Route path={PageRoutes.EDIT_ROOM_DETAIL} element={<EditRoomDetailScreen/>}/>
                <Route path={PageRoutes.REGISTER_ROOM_TYPE} element={<RegisterRoomTypeScreen/>}/>
                <Route path={PageRoutes.REGISTER_ROOM} element={<RegisterRoomScreen/>}/>
                <Route path={PageRoutes.ROOM_TYPE} element={<RoomTypeScreen/>}></Route>
                <Route path={PageRoutes.EDIT_ROOM_TYPE} element={<EditRoomTypeScreen/>}></Route>

                {/* Reservation */}
                <Route path={PageRoutes.RESERVATIONS} element={<ReservationScreen/>}/>
                <Route path={PageRoutes.RESERVATIONS_DETAIL} element={<ReservationDetailScreen/>}/>

                {/* MyPage */}
                <Route path={PageRoutes.MY_PAGE} element={<MyPageScreen/>}/>

                {/* Authentication */}
                <Route path={PageRoutes.TOKEN} element={<TokenScreen/>}/>
                <Route path={PageRoutes.LOGIN} element={<LoginScreen/>}/>

            </Routes>
        </BrowserRouter>
    );
}

export default App;
