import ApiService from "../../services/ApiService";

class UserApi {
    static me() {
        const path = '/users/me'
        return ApiService.get(path, true)
    }
}

export default UserApi
