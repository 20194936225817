export const onlyNumbersString = (s: string) => {
    if (!s) {
        return ''
    }

    const match = s.toString().match(/\d+/g)
    if (match) {
        return match.join('')
    }

    return ''
}
