import {Button, Col, Dropdown, MenuProps, Modal, Row, Table} from "antd";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import {TextStyle} from "../../../constants/style";
import {DownOutlined} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import _ from "lodash";
import {moneyValue} from "../../../utils/string";
import {ColumnsType} from "antd/es/table";

const AccommodationPrice = ({prices = [], onChangeData}: { prices: any, onChangeData: (prices: any[]) => void }) => {
    const [priceName, setPriceName] = useState('')
    const [priceDataSources, setPriceDataSources] = useState([])

    const pricesGroupByName = _.groupBy(prices, (price: any) => price.name)

    useEffect(() => {
        if (prices && prices.length > 0) {
            setPriceName(prices[0].name)
        }
    }, [])

    useEffect(() => {
        if (pricesGroupByName[priceName]) {
            const prices = pricesGroupByName[priceName].map((price: any) => ({
                key: `
            price -${price.id}`,
                id: price.id,
                type: price.type,
                weekday: moneyValue(price.weekday),
                weekend: moneyValue(price.weekend),
                holiday: moneyValue(price.holiday),
            }));

            // @ts-ignore
            setPriceDataSources(prices)
            onChangeData(prices)
        }
    }, [priceName])

    const handleMenuClick: MenuProps['onClick'] = (e) => setPriceName(e.key)
    const menuProps = {
        items: Object.keys(pricesGroupByName).map(priceName => ({label: priceName, key: priceName})),
        selectable: true,
        onClick: handleMenuClick
    };

    return (
        <>
            <Row style={{marginTop: 6}}>
                <Col xs={24} xl={24}>
                    <Dropdown
                        overlayStyle={CommonStyle.dropdownOverlayStyle}
                        menu={menuProps}
                    >
                        <Button style={{
                            width: 232,
                            height: 50,
                            borderRadius: 16,
                            display: "flex",
                            justifyContent: "space-between",
                            ...TextStyle,
                            alignItems: 'center',
                            justifyItems: 'center',
                        }} size='large'>
                            {priceName || '요금 선택'}
                            <DownOutlined/>
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            <Table
                style={{marginTop: 12}}
                columns={priceColumns}
                dataSource={priceDataSources}
            />
        </>
    )
}

interface PriceDataType {
    key: string;
    id: number;
    type: string;
    weekday: string;
    weekend: string;
    holiday: string;
}

const priceColumns: ColumnsType<PriceDataType> = [
    {
        title: '타입',
        dataIndex: 'type',
        key: 'type',
    },
    {
        title: '주중(일~목)',
        dataIndex: 'weekday',
        key: 'weekday',
    },
    {
        title: '주말(금~토)',
        dataIndex: 'weekend',
        key: 'weekend',
    },
    {
        title: '공휴일 전',
        dataIndex: 'holiday',
        key: 'holiday',
    },
]

export default AccommodationPrice
