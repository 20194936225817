import dayjs from "dayjs";

export const yyyymmdd = (format: string) => {
    try {
        return dayjs(format).year(dayjs().year()).format('YYYY/MM/DD');
    } catch (e) {
        return ''
    }
}

export const calculateStayDuration = (startDate: string, endDate: string) => {
    if (!startDate || !endDate) {
        return ''
    }

    if (startDate.length < 19 || endDate.length < 19) {
        startDate = '20' + startDate.replaceAll('.', '-').replace(/[^\d-]/g, '')
        endDate = '20' + endDate.replaceAll('.', '-').replace(/[^\d-]/g, '')
    }

    const startDay = dayjs(startDate).startOf('day');
    const endDay = dayjs(endDate).startOf('day');
    const duration = endDay.diff(startDay, 'day');

    if (duration <= 0 || isNaN(duration)) {
        return '';
    }

    const nights = duration
    const days = endDay.diff(startDay, 'day') + 1

    return `${nights}박 ${days}일`;
};

