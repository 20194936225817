import {Col, Row} from "antd";
import React from "react";

const CenterGrid = ({...props}) => {
    const innerStyle = {
        paddingTop: '1.5rem',
        ...props.innerStyle
    }

    return (
        <Row style={{maxWidth: 1300}}>
            <Col xs={1} xl={1}></Col>
            <Col xs={22} xl={20}>
                <div style={innerStyle}>
                    {props.children}
                </div>
            </Col>
            <Col xs={1} xl={3}></Col>
        </Row>
    )
}

export default CenterGrid;
