import './TopNavigationBar.css'

const TopNavigationBar = ({mobile, ...props}: { mobile?: boolean, children: any }) => {
    const style = mobile ? {
        height: 100,
    } : {}

    return (
        <div className="top-navigation-bar" style={style} id="128:3">
            {props.children}
        </div>
    )
}

export default TopNavigationBar;
