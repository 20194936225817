// @ts-nocheck

import React, {CSSProperties, ReactNode, useEffect, useState} from "react";
import ScreenOuter from "../../../components/ScreenOuter";
import withPolicies from "../../../components/withPolicies";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {requestChangePaymentStatus, requestReservationDetail} from "../../../redux/Reservation";
import {Button, Col, Divider, Dropdown, Image, Modal, Row, Space, Typography} from "antd";
import ScreenGrid from "../../../components/ScreenGrid";
import {Color, PcSizeStyle, TextStyle} from "../../../constants/style";
import dayjs from "dayjs";
import {moneyValue} from "../../../utils/string";
import YellowAddIcon from '../../../assets/icon/yellowAdd.png'
import YellowEqualIcon from '../../../assets/icon/yellowEqual.png'
import {calculateStayDuration} from "../../../utils/date";
import TextLiner from "../../../components/TextLiner";
import EmptySpace from "../../../components/EmptySpace";

import './style.css'
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import {DownOutlined} from "@ant-design/icons";

const ReservationDetailScreen = () => {
    const dispatch = useDispatch()
    const {id: reservationId} = useParams()
    const [modal, contextHolder] = Modal.useModal()
    const navigate = useNavigate()

    const accommodationName = useSelector(state => state.Accommodation.get('name'))

    useEffect(() => {
        requestReservation()
    }, [])

    const [reservation, setReservation] = useState({})

    const requestReservation = async () => {
        const result = await dispatch(requestReservationDetail(reservationId))

        if (result.success) {
            setReservation(result.reservation)
        } else {
            modal.error({
                title: '예약 내용을 불러오지 못했습니다.',
                content: `${result?.error?.message || ''}`,
                okText: '확인',
                onOk: () => navigate(-1)
            })
        }
    }

    const ReservationStatusText = () => (
        <Space direction={'vertical'} style={{rowGap: 0}}>
            <TextLiner enabled textStyle={{fontSize: 18, fontWeight: 700}} value={reservation.status}/>
            {reservation.status !== '예약 확정' && (
                <Typography.Text
                    style={{
                        ...TextStyle,
                        fontStyle: 'italic',
                        fontSize: 12,
                        fontWeight: 400,
                        color: Color.darkSecondary
                    }}>
                    결제가 완료되면 예약이 확정됩니다.
                </Typography.Text>
            )}
        </Space>
    )

    const PaymentStatusDropdown = ({paymentStatus}: { paymentStatus: '결제 대기' | '결제 완료' | '결제 취소' }) => {
        const onRequestChangePaymentStatus = async (paymentStatusKey: string) => {
            const result = await dispatch(requestChangePaymentStatus(reservationId, paymentStatusKey))

            if (result.success) {
                navigate(0)
            } else {
                modal.error({
                    title: `결제 상태 변경에 실패했습니다.`,
                    content: `${result?.error?.message || ''}`,
                    okText: '확인',
                })
            }
        }

        const menuProps = {
            items: [
                // '결제 취소' 는 환불 요청이 완료되었을 때, 서버에서 설정된 값으로만 사용하기에 FE 에서 변경할 수 없음
                {key: 'PENDING', label: '결제 대기'},
                {key: 'COMPLETED', label: '결제 완료'},
            ],
            onClick: (e) => {
                modal.confirm({
                    title: '결제 상태를 변경하시겠습니까?',
                    content: '매출 내역에 반영되므로 입금 확인을 완료하신 후 결제 상태를 변경하셔야 합니다.',
                    okText: '결제 상태 변경',
                    cancelText: '취소',
                    onOk: () => onRequestChangePaymentStatus(e.key),
                })
            }
        }

        let border
        let textColor
        let backgroundColor

        if (paymentStatus === '결제 대기') {
            border = '1px solid #5266FF'
            textColor = Color.b40
            backgroundColor = Color.b3
        } else if (paymentStatus === '결제 완료') {
            border = '1px solid #47B869'
            textColor = Color.g50
            backgroundColor = Color.g3
        } else if (paymentStatus === '결제 취소') {
            border = '1px solid #FF6157'
            textColor = Color.r40
            backgroundColor = Color.r3
        }

        return (
            <Dropdown
                disabled={paymentStatus === '결제 취소'}
                menu={menuProps}
            >
                <Space
                    style={{
                        width: 130,
                        height: 50,
                        border: border,
                        backgroundColor: backgroundColor,
                        borderRadius: 16,
                        alignItems: 'center',
                        justifyContent: 'space-evenly'
                    }}
                >
                    <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 500, color: textColor}}>
                        {paymentStatus}
                    </Typography.Text>
                    <DownOutlined/>
                </Space>
            </Dropdown>
        )
    }

    const CustomerLabelText = () => {
        return (
            <Space style={{alignItems: 'center', justifyContent: 'center'}}>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 16,
                    fontWeight: 400
                }}>{reservation.name} / {reservation.phoneNumber}</Typography.Text>
                <Button
                    style={{
                        width: 50,
                        height: 40,
                        backgroundColor: Color.y40,
                        border: 0,
                        borderRadius: 8,
                        textAlign: 'center',
                        padding: 0,
                        ...TextStyle,
                        fontSize: 14,
                        fontWeight: 500,
                    }}
                    onClick={() => modal.info({
                        title: '정식 버전을 기다려주세요!',
                        content: `문자 보내기 기능은 정식 버전에서 제공될 예정입니다.`
                    })}
                >문자</Button>
            </Space>
        )
    }

    const stayDurationText = `${dayjs(reservation.startDate).format('YY.MM.DD')}
        ~ ${dayjs(reservation.endDate).format('YY.MM.DD')}
        (${calculateStayDuration(reservation.startDate, reservation.endDate)})`
    const customerText = `${reservation.adultCount + reservation.childCount + reservation.babyCount}명
        (성인 ${reservation.adultCount} / 아동 ${reservation.childCount} / 유아 ${reservation.babyCount})`
    const totalPriceText = <TextLiner enabled value={`${moneyValue(reservation.totalPrice)}원`}/>

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Header/>

                <RowLabelText marginTop={40} label={reservation.code}
                              text={<ReservationStatusText/>}
                              labelTextStyle={{fontSize: 20, fontWeight: 700}}
                              textBoxStyle={{alignItems: 'flex-end'}}/>
                <RowLabelText marginTop={40} label={'결제 상태'}
                              text={<PaymentStatusDropdown paymentStatus={reservation.paymentStatus}/>}
                              textBoxStyle={{alignItems: 'flex-end'}}/>
                <RowLabelText label={'예약자'} text={<CustomerLabelText/>}/>
                <RowLabelText label={'신청 일시'} text={dayjs(reservation.createdDate).format('YYYY.MM.DD HH:mm:ss')}/>
                <RowLabelText label={'결제 방법'} text={reservation.paymentType}/>
                <PriceTable reservation={reservation}/>

                <Row>
                    <Typography.Text
                        style={{marginTop: 40, ...TextStyle, fontSize: 20, fontWeight: 700}}>🏠 객실</Typography.Text>
                </Row>

                <RowLabelText marginTop={20} label={'객실 이름'} text={reservation.roomName}/>
                <RowLabelText label={'숙박 기간'} text={stayDurationText}/>
                <RowLabelText label={'인원'} text={customerText}/>
                <RowLabelText label={'요금 타입'} text={''}/>
                <Divider style={{maxWidth: PcSizeStyle.maxWidth, minWidth: 0}}/>

                <RowLabelText label={'총 요금'} text={totalPriceText}/>
                <RowLabelText label={'ㄴ 숙박 요금'} labelTextStyle={{color: Color.darkTertiary, paddingLeft: 5}}
                              text={`${moneyValue(reservation.roomPrice)}원`} textStyle={{color: Color.darkTertiary}}/>
                <RowLabelText label={'ㄴ 추가 인원 요금'} labelTextStyle={{color: Color.darkTertiary, paddingLeft: 5}}
                              text={`${moneyValue(reservation.additionalCustomerPrice)}원`}
                              textStyle={{color: Color.darkTertiary}}/>

                <RowLabelText label={'유료 시설 요금'}
                              text={`${moneyValue(reservation.totalOptionPrice)}원`}/>

                {reservation.options?.map((option, index) => {
                    return (
                        <RowLabelText key={index} label={`ㄴ ${option.optionName} X ${option.count}`}
                                      labelTextStyle={{color: Color.darkTertiary, paddingLeft: 5}}
                                      text={`${moneyValue(option.optionPrice * option.count)}원`}
                                      textStyle={{color: Color.darkTertiary}}/>
                    )
                })}
                <EmptySpace size={50}/>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const Header = ({}) => {
    return (
        <Space style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={2} style={{...TextStyle}}>예약 상세</Typography.Title>
        </Space>
    )
}

const RowLabelText = ({marginTop = 15, label, text, labelTextStyle = {}, textStyle = {}, textBoxStyle = {}}: {
    marginTop?: number,
    label: string,
    text: string | ReactNode,
    labelTextStyle?: CSSProperties,
    textStyle?: CSSProperties,
    textBoxStyle?: CSSProperties,
}) => {
    const isNotReactNode = typeof text === 'string'
    return (
        <Row style={{marginTop: marginTop, maxWidth: PcSizeStyle.maxWidth}}>
            <Col xs={9} xl={12}>
                <Typography.Text
                    style={{...TextStyle, fontSize: 16, fontWeight: 500, ...labelTextStyle}}>{label}</Typography.Text>
            </Col>
            <Col xs={15} xl={12} style={{textAlign: 'right', ...textBoxStyle}}>
                {isNotReactNode ? (
                    <Typography.Text style={{
                        ...TextStyle,
                        fontSize: 16,
                        fontWeight: 400,
                        ...textStyle,
                    }}>{text}</Typography.Text>
                ) : (text)}
            </Col>
        </Row>
    )
}

const PriceTable = ({reservation}) => {
    return (
        <Space className={'price-table'} style={{
            padding: 20,
            marginTop: 40,
            width: '100%',
            height: 120,
            maxWidth: PcSizeStyle.maxWidth,
            borderRadius: 16,
            backgroundColor: Color.darkPrimary,
            alignItems: 'center',
            justifyContent: 'space-evenly',
            textAlign: 'center',
        }}>
            <Space direction={'vertical'} style={{alignItems: 'center', justifyContent: 'center'}}>
                <Typography.Text style={{...TextStyle, fontSize: 16, color: Color.white}}>객실 요금 (인원 추가 요금
                    포함)</Typography.Text>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 18,
                    fontWeight: 500,
                    color: Color.white
                }}>{`${moneyValue(reservation.roomPrice + reservation.additionalCustomerPrice)}원`}</Typography.Text>
            </Space>
            <Image preview={false} src={YellowAddIcon} style={{width: 24, height: 24}}/>
            <Space direction={'vertical'} style={{alignItems: 'center', justifyContent: 'center'}}>
                <Typography.Text style={{...TextStyle, fontSize: 16, color: Color.white}}>옵션
                    요금</Typography.Text>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 18,
                    fontWeight: 500,
                    color: Color.white
                }}>{`${moneyValue(reservation.totalOptionPrice)}원`}</Typography.Text>
            </Space>
            <Image preview={false} src={YellowEqualIcon} style={{width: 24, height: 24}}/>
            <Space direction={'vertical'} style={{alignItems: 'center', justifyContent: 'center'}}>
                <Typography.Text
                    style={{...TextStyle, fontSize: 16, fontWeight: 700, color: Color.y40}}>총
                    요금</Typography.Text>
                <Typography.Text style={{
                    ...TextStyle,
                    fontSize: 18,
                    fontWeight: 700,
                    color: Color.y40
                }}>{`${moneyValue(reservation.totalPrice)}원`}</Typography.Text>
            </Space>
        </Space>
    )
}

export default withPolicies(ReservationDetailScreen)