import {Map} from 'immutable';
import AccommodationApi from "../../apis/AccommodationApi";
import {SET_IS_LOADING_ACCOMMODATION, setIsLoading} from "../Loading";
import ContentApi from "../../apis/ContentApi";

// action types
const SAVE_ACCOMMODATION = 'Accommodation/SAVE_ACCOMMODATION'
const SAVE_ACCOMMODATION_CONTENTS = 'Accommodation/SAVE_ACCOMMODATION_CONTENTS'

// action creators
const saveAccommodation = (accommodation: any) => ({
    type: SAVE_ACCOMMODATION,
    payload: {
        accommodation,
    },
})

const saveAccommodationContents = (contents: any) => ({
    type: SAVE_ACCOMMODATION_CONTENTS,
    payload: {
        contents,
    }
})

// initialState
const initialState = Map({
    id: null,
    status: null,
    name: null,
    officePhoneNumber: null,
    description: null,
    checkInTime: null,
    checkOutTime: null,
    bizNumber: null,
    address: null,
    addressDetail: null,
    latitude: null,
    longitude: null,
    bankName: null,
    accountNumber: null,
    accountHolder: null,
    usagePolicy: null,
    refundPolicy: null,
    refundPercentages: [],
    rooms: [],
    seasons: [],
    prices: [],
    basicFacilities: [],
    paidFacilities: [],
    contents: [],
});

// reducer
export const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SAVE_ACCOMMODATION:
            return state.set('id', action.payload.accommodation.id)
                .set('status', action.payload.accommodation.status)
                .set('name', action.payload.accommodation.name)
                .set('description', action.payload.accommodation.description)
                .set('checkInTime', action.payload.accommodation.checkInTime)
                .set('checkOutTime', action.payload.accommodation.checkOutTime)
                .set('officePhoneNumber', action.payload.accommodation.officePhoneNumber)
                .set('bizNumber', action.payload.accommodation.bizNumber)
                .set('address', action.payload.accommodation.address)
                .set('addressDetail', action.payload.accommodation.addressDetail)
                .set('latitude', action.payload.accommodation.latitude)
                .set('longitude', action.payload.accommodation.longitude)
                .set('bankName', action.payload.accommodation.bankName)
                .set('accountNumber', action.payload.accommodation.accountNumber)
                .set('accountHolder', action.payload.accommodation.accountHolder)
                .set('usagePolicy', action.payload.accommodation.usagePolicy)
                .set('refundPolicy', action.payload.accommodation.refundPolicy?.policy || null)
                .set('refundPercentages', action.payload.accommodation.refundPolicy?.percentages || [])
                .set('rooms', action.payload.accommodation.rooms)
                .set('seasons', action.payload.accommodation.seasons)
                .set('prices', action.payload.accommodation.prices)
                .set('basicFacilities', action.payload.accommodation.basicFacilities)
                .set('paidFacilities', action.payload.accommodation.paidFacilities)
        case SAVE_ACCOMMODATION_CONTENTS:
            return state.set('contents', action.payload.contents)
        default:
            return state;
    }
};

export const requestAccommodations = () => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {response, json} = await AccommodationApi.get();

        if (response.ok) {
            dispatch(saveAccommodation(json.data?.accommodations[0] || {}))
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            // TODO: 에러처리
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
        // TODO: 에러처리
    }
};

export const requestUpdateAccommodation = (
    accommodationId: number,
    name: string,
    description: string,
    checkInTime: string,
    checkOutTime: string,
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response,
            json
        } = await AccommodationApi.update(accommodationId, name, description, checkInTime, checkOutTime);

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve(true)
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            // TODO: 에러처리
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
        // TODO: 에러처리
    }
}

export const requestRegisterSeason = (
    accommodationId: number,
    name: string,
    type: string,
    startDate: string,
    endDate: string
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {response, json} = await AccommodationApi.createSeason(accommodationId, name, type, startDate, endDate)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestUpdateSeason = (
    accommodationId: number,
    seasonId: number,
    name: string,
    type: string,
    startDate: string,
    endDate: string
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response,
            json
        } = await AccommodationApi.updateSeason(accommodationId, seasonId, name, type, startDate, endDate)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestDeleteSeason = (
    accommodationId: number,
    seasonId: number,
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response, json
        } = await AccommodationApi.deleteSeason(accommodationId, seasonId)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestRegisterPrice = (
    accommodationId: number,
    prices: any
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {response, json} = await AccommodationApi.createPrices(accommodationId, prices)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestUpdatePrice = (
    accommodationId: number,
    prices: any
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {response, json} = await AccommodationApi.updatePrices(accommodationId, prices)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestDeletePrice = (
    accommodationId: number,
    priceName: string
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {response, json} = await AccommodationApi.deletePrices(accommodationId, priceName)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestRegisterBasicFacilities = (
    accommodationId: number,
    basicFacilities: any
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {response, json} = await AccommodationApi.createBasicFacilities(accommodationId, basicFacilities)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestRegisterPaidFacility = (
    accommodationId: number,
    name: string,
    description: string,
    price: string,
    isMultiple: boolean,
    isDisplay: boolean,
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response, json
        } = await AccommodationApi.createPaidFacility(accommodationId, name, description, price, isMultiple, isDisplay)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}


export const requestUpdatePaidFacility = (
    accommodationId: number,
    paidFacilityId: number,
    name: string,
    description: string,
    price: string,
    isMultiple: boolean,
    isDisplay: boolean,
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response, json
        } = await AccommodationApi.updatePaidFacility(accommodationId, paidFacilityId, name, description, price, isMultiple, isDisplay)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}


export const requestUpdateUsagePolicy = (
    accommodationId: number,
    policy: string,
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response, json
        } = await AccommodationApi.updateUsagePolicy(accommodationId, policy)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestUpdateRefundPolicy = (accommodationId: number, updateRefundPolicy: any) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response, json
        } = await AccommodationApi.updateRefundPolicy(accommodationId, updateRefundPolicy)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestDeletePaidFacility = (accommodationId: number, paidFacilityId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, true))

    try {
        const {
            response, json
        } = await AccommodationApi.deletePaidFacility(accommodationId, paidFacilityId)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ACCOMMODATION, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestCreateAccommodationContent = async (accommodationId: number, filePath: string) => {
    try {
        const {
            response, json
        } = await ContentApi.createAccommodationContent('IMAGE', accommodationId, filePath)

        if (response.ok) {
            return Promise.resolve({success: true})
        } else {
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        return Promise.resolve({success: false, error: e})
    }
}

export const requestAccommodationContents = (accommodationId: number) => async (dispatch: any, getState: any) => {
    try {
        const {
            response, json
        } = await ContentApi.getAccommodationContents(accommodationId)

        if (response.ok) {
            // @ts-ignore
            dispatch(saveAccommodationContents(json.data))
            return Promise.resolve({success: true})
        } else {
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        return Promise.resolve({success: false, error: e})
    }
}

export const requestDeleteContent = (contentId: number) => async (dispatch: any, getState: any) => {
    try {
        const {
            response, json
        } = await ContentApi.deleteContent(contentId)

        if (response.ok) {
            return Promise.resolve({success: true})
        } else {
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        return Promise.resolve({success: false, error: e})
    }
}
