import {Map} from 'immutable';
import UserApi from "../../apis/UserApi";

// action types
const SAVE_USER = 'User/SAVE_USER'

// action creators
const saveUser = (user: any) => ({
    type: SAVE_USER,
    payload: {
        user,
    }
})

// initialState
const initialState = Map({
    user: null,
});

// reducer
const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SAVE_USER:
            return state.set('user', action.payload.user)
        default:
            return state;
    }
};

export const requestUserMeApi = () => async (dispatch: any, getState: any) => {
    try {
        const {response, json} = await UserApi.me()

        if (response.ok) {
            dispatch(saveUser(json?.data || {}))
            return Promise.resolve({success: true, user: json?.data || {}});
        } else {
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        return Promise.resolve({success: false, error: e});
    }
}


export {
    reducer,
};