import CookieService from "../CookieService";
import {API_URL} from "../../constants/environment";


class ApiService {
    static get baseURL() {
        return API_URL;
    }

    static async get(path: string, needToken: boolean = false, baseUrl?: string) {
        const url = baseUrl ? baseUrl + path : this.baseURL + path;
        const params: any = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        };

        if (needToken) {
            params.headers['Authorization'] = `Bearer ${CookieService.getAccessToken()}`;
        }

        const response = await fetch(url, params);
        const json = await response.json();

        return {
            response: response,
            json: json,
        };
    }

    static async post(path: string, body: any, needToken: boolean = false, baseUrl?: string) {
        const url = baseUrl ? baseUrl + path : this.baseURL + path;
        const params: any = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            credentials: 'include',
        };

        if (needToken) {
            params.headers['Authorization'] = `Bearer ${CookieService.getAccessToken()}`;
        }

        const response = await fetch(url, params);
        const json = await response.json();

        return {
            response: response,
            json: json,
        };
    }

    static async patch(path: string, body: any, needToken: boolean = false) {
        const url = this.baseURL + path;
        const params: any = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body),
            credentials: 'include',
        };

        if (needToken) {
            params.headers['Authorization'] = `Bearer ${CookieService.getAccessToken()}`;
        }

        const response = await fetch(url, params);
        const json = await response.json();

        return {
            response: response,
            json: json,
        };
    }

    static async delete(path: string, needToken: boolean = false) {
        const url = this.baseURL + path;
        const params: any = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
            },
            credentials: 'include',
        };

        if (needToken) {
            params.headers['Authorization'] = `Bearer ${CookieService.getAccessToken()}`;
        }

        const response = await fetch(url, params);
        const json = await response.json();

        return {
            response: response,
            json: json,
        };
    }
}

export default ApiService;