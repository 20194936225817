import {Button} from "antd";
import React, {ReactNode} from "react";
import {TextStyle} from "../../constants/style";

const NoBorderButton = ({style, value, onClick, icon, rightIcon, ...buttonProps}: {
    style?: any,
    value: string,
    onClick: () => void,
    icon?: ReactNode,
    rightIcon?: ReactNode,
}) => {
    rightIcon = rightIcon || null
    const noBorderStyle = {
        ...TextStyle,
        fontSize: 16,
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        margin: 0,
        padding: 0,
        boxShadow: 'none',
    }

    return (
        <Button
            style={{...noBorderStyle, ...style}}
            onClick={onClick}
            icon={icon || null}
            {...buttonProps}
        >
            {value}
            {rightIcon}
        </Button>
    )
}

export default NoBorderButton
