import React, {useEffect, useState} from "react";

import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {Col, Input, message, Modal, notification, Radio, Row, Space, Typography} from "antd";
import {TextStyle} from "../../../constants/style";
import RequiredFieldMark from "../../../components/RequiredFieldMark";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import AccommodationPrice from "./AccommodationPrice";
import {requestAccommodations} from "../../../redux/Accommodation";
import {useDispatch, useSelector} from "react-redux";
import AccommodationBasicFacility from "./AccommodationBasicFacility";
import AccommodationPaidFacility from "./AccommodationPaidFacility";
import RegisterButton from "../../../components/RegisterButton";
import {PlusOutlined} from "@ant-design/icons";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import {requestRegisterRoomType} from "../../../redux/Room";
import {moneyValue} from "../../../utils/string";
import {onlyNumbersString} from "../../../utils/number";
import TextLiner from "../../../components/TextLiner";
import withPolicies from "../../../components/withPolicies";


export type RoomType = 'PENSION' | 'POOLVILLA' | 'CONDO' | 'CARAVAN' | 'GLAMPING' | 'CAMPING'

const RegisterRoomTypeScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    const [typeName, setTypeName] = useState('')
    const [typeCategory, setTypeCategory] = useState<RoomType | ''>('')

    const [standardNumberOfPeople, setStandardNumberOfPeople] = useState('')
    const [maxNumberOfPeople, setMaxNumberOfPeople] = useState('')

    const [additionalFeeAdult, setAdditionalFeeAdult] = useState('')
    const [additionalFeeChild, setAdditionalFeeChild] = useState('')
    const [additionalFeeBaby, setAdditionalFeeBaby] = useState('')

    const [roomSize, setRoomSize] = useState('')
    const [numberOfLivingRoom, setNumberOfLivingRoom] = useState('')
    const [numberOfRoom, setNumberOfRoom] = useState('')
    const [numberOfBathroom, setNumberOfBathroom] = useState('')
    const [roomDescription, setRoomDescription] = useState('')

    const [selectedPriceIds, setSelectedPriceIds] = useState<number[]>([])
    const [selectedBasicFacilityIds, setSelectedBasicFacilityIds] = useState<number[]>([])
    const [selectedPaidFacilityIds, setSelectedPaidFacilityIds] = useState<number[]>([])

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationPrices = useSelector(state => state.Accommodation.get('prices'))
    // @ts-ignore
    const accommodationBasicFacilities = useSelector(state => state.Accommodation.get('basicFacilities'))
    // @ts-ignore
    const accommodationPaidFacilities = useSelector(state => state.Accommodation.get('paidFacilities'))

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    const distinct = (array: any[]) => array.filter(function (item, pos) {
        return array.indexOf(item) === pos;
    })

    const register = async () => {
        const result = await dispatch(
            // @ts-ignore
            requestRegisterRoomType(
                accommodationId,
                typeName,
                typeCategory,
                onlyNumbersString(standardNumberOfPeople),
                onlyNumbersString(maxNumberOfPeople),
                additionalFeeAdult.replaceAll(",", ""),
                additionalFeeChild.replaceAll(",", ""),
                additionalFeeBaby.replaceAll(",", ""),
                onlyNumbersString(roomSize),
                onlyNumbersString(numberOfLivingRoom),
                onlyNumbersString(numberOfRoom),
                onlyNumbersString(numberOfBathroom),
                roomDescription,
                distinct(selectedPriceIds),
                distinct(selectedBasicFacilityIds),
                distinct(selectedPaidFacilityIds),
            ))

        if (result.success) {
            notification.success({
                message: '객실타입 추가에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ROOMS),
            })
        } else {
            modal.error({
                title: `객실타입 추가에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    const validateRegister: (
        skipBasicFacility?: boolean,
        skipPaidFacility?: boolean,
    ) => void = (skipBasicFacility = false, skipPaidFacility = false) => {
        if (selectedBasicFacilityIds.length < 1 && !skipBasicFacility) {
            modal.confirm({
                title: '기본 시설을 선택하지 않았습니다!',
                content: '고객이 객실을 예약할 때, 기본 시설 정보를 확인할 수 없게 됩니다.\n정말 이대로 등록하시겠습니까?',
                onOk: () => validateRegister(true),
            })
            return
        }

        if (selectedPaidFacilityIds.length < 1 && !skipPaidFacility) {
            modal.confirm({
                title: '유료 시설을 선택하지 않았습니다!',
                content: '고객이 객실을 예약할 때, 유료 시설 정보를 확인할 수 없게 됩니다.\n정말 이대로 등록하시겠습니까?',
                onOk: () => validateRegister(true, true),
            })
            return
        }

        register()
    }

    return (
        <ScreenOuter>
            <ScreenGrid>
                <Header/>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                타입 이름 <RequiredFieldMark/>
                            </Typography.Text>
                            <Input
                                style={CommonStyle.enabledInputStyle}
                                placeholder={'예시. 풀빌라+복층 A'}
                                value={typeName}
                                onChange={e => setTypeName(e.target.value)}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                카테고리 <RequiredFieldMark/>
                            </Typography.Text>
                            <Radio.Group onChange={e => setTypeCategory(e.target.value)} value={typeCategory}>
                                <Radio value={'PENSION'}>
                                    <TextLiner
                                        enabled={typeCategory === 'PENSION'}
                                        value={'펜션'}/>
                                </Radio>
                                <Radio value={'POOLVILLA'}>
                                    <TextLiner
                                        enabled={typeCategory === 'POOLVILLA'}
                                        value={'풀빌라'}/>
                                </Radio>
                                <Radio value={'CONDO'}>
                                    <TextLiner
                                        enabled={typeCategory === 'CONDO'}
                                        value={'콘도'}/>
                                </Radio>
                                <Radio value={'CARAVAN'}>
                                    <TextLiner
                                        enabled={typeCategory === 'CARAVAN'}
                                        value={'카라반'}/>
                                </Radio>
                                <Radio value={'GLAMPING'}>
                                    <TextLiner
                                        enabled={typeCategory === 'GLAMPING'}
                                        value={'글램핑'}/>
                                </Radio>
                                <Radio value={'CAMPING'}>
                                    <TextLiner
                                        enabled={typeCategory === 'CAMPING'}
                                        value={'캠핑'}/>
                                </Radio>
                            </Radio.Group>
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            인원 <RequiredFieldMark/>
                        </Typography.Text>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                기준 인원 (추가요금 없이 입실 가능한 인원)
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={standardNumberOfPeople}
                                placeholder={'4'}
                                onChange={e => setStandardNumberOfPeople(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                최대 인원 (최대 입실 가능한 인원)
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={maxNumberOfPeople}
                                placeholder={'6'}
                                onChange={e => setMaxNumberOfPeople(e.target.value)}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            추가 인원 요금 <RequiredFieldMark/>
                        </Typography.Text>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                성인
                            </Typography.Text>
                            <Input
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={moneyValue(additionalFeeAdult)}
                                placeholder={'20,000'}
                                onChange={e => setAdditionalFeeAdult(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                아동/청소년
                            </Typography.Text>
                            <Input
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={moneyValue(additionalFeeChild)}
                                placeholder={'10,000'}
                                onChange={e => setAdditionalFeeChild(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={8}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                유아
                            </Typography.Text>
                            <Input
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={moneyValue(additionalFeeBaby)}
                                placeholder={'10,000'}
                                onChange={e => setAdditionalFeeBaby(e.target.value)}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            객실 구성 <RequiredFieldMark/>
                        </Typography.Text>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                평형 (ex, 30평)
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={roomSize}
                                placeholder={'30'}
                                onChange={e => setRoomSize(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                거실 수
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={numberOfLivingRoom}
                                placeholder={'1'}
                                onChange={e => setNumberOfLivingRoom(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                방 수
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={numberOfRoom}
                                placeholder={'3'}
                                onChange={e => setNumberOfRoom(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={6}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                욕실 수
                            </Typography.Text>
                            <Input
                                type={'number'}
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={numberOfBathroom}
                                placeholder={'2'}
                                onChange={e => setNumberOfBathroom(e.target.value)}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                객실 설명
                            </Typography.Text>
                            <Input.TextArea
                                style={{...CommonStyle.enabledInputStyle, height: 120}}
                                placeholder={'추가로 설명하고 싶은 내용을 입력하세요.'}
                                value={roomDescription}
                                onChange={e => setRoomDescription(e.target.value)}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                숙박 요금
                            </Typography.Text>
                            <AccommodationPrice prices={accommodationPrices}
                                                // @ts-ignore
                                                onChangeData={prices => setSelectedPriceIds(prices.map(price => price.id))}/>
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                기본 시설
                            </Typography.Text>
                            <AccommodationBasicFacility
                                selectedBasicFacilityIds={selectedBasicFacilityIds}
                                onUpdate={(newItem: number[]) => setSelectedBasicFacilityIds(newItem)}
                                basicFacilities={accommodationBasicFacilities}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} xl={24}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                유료 시설
                            </Typography.Text>
                            <AccommodationPaidFacility
                                selectedPaidFacilityIds={selectedPaidFacilityIds}
                                onUpdate={(newItem: number[]) => setSelectedPaidFacilityIds(newItem)}
                                paidFacilities={accommodationPaidFacilities}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row style={CommonStyle.registerButtonContainerStyle}>
                    <RegisterButton
                        disabled={(!typeName
                            || !typeCategory
                            || !standardNumberOfPeople
                            || !maxNumberOfPeople
                            || !additionalFeeAdult
                            || !additionalFeeChild
                            || !additionalFeeBaby
                            || !roomSize
                            || !numberOfLivingRoom
                            || !numberOfRoom
                            || !numberOfBathroom)}
                        icon={<PlusOutlined/>}
                        label={'등록하기'}
                        onClick={() => validateRegister(false, false)}
                    />
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const Header = ({}) => {
    return (
        <Space direction={'vertical'} style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={2} style={{...TextStyle}}>객실 타입 등록</Typography.Title>
            <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 400}}><RequiredFieldMark/> 로 표시된 필수 항목을 모두
                입력해 주세요.</Typography.Text>
        </Space>
    )
}

export default withPolicies(RegisterRoomTypeScreen)
