import ScreenOuter from "../../../components/ScreenOuter";
import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    requestAccommodations,
    requestRegisterBasicFacilities,
    requestUpdateAccommodation
} from "../../../redux/Accommodation";
import ScreenGrid from "../../../components/ScreenGrid";
import {Button, Col, Input, message, Modal, notification, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {BasicFacilityType, FacilityLabel} from "../AccommodationScreen";
import {CommonStyle} from "../AccommodationScreen/style";

import '../AccommodationScreen/AccommodationScreen.css'
import EmptySpace from "../../../components/EmptySpace";
import RegisterButton from "../../../components/RegisterButton";
import {PlusOutlined} from "@ant-design/icons";
import _ from "lodash";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import {Simulate} from "react-dom/test-utils";
import error = Simulate.error;
import withPolicies from "../../../components/withPolicies";

const EditAccommodationBasicFacilityScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationBasicFacilities = useSelector(state => state.Accommodation.get('basicFacilities'))

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    const basicFacilityMap = _.groupBy(accommodationBasicFacilities, (b) => b.type)

    const [surrounding, setSurrounding] = useState([])
    const [bedroom, setBedroom] = useState([])
    const [kitchen, setKitchen] = useState([])
    const [bathroom, setBathroom] = useState([])
    const [airConditioning, setAirConditioning] = useState([])
    const [entertainment, setEntertainment] = useState([])
    const [parking, setParking] = useState([])
    const [etc, setEtc] = useState([])

    useEffect(() => {
        if (basicFacilityMap) {
            // @ts-ignore
            setSurrounding(basicFacilityMap['주변']?.map(b => b.name) || [])
            // @ts-ignore
            setBedroom(basicFacilityMap['침실']?.map(b => b.name) || [])
            // @ts-ignore
            setKitchen(basicFacilityMap['주방']?.map(b => b.name) || [])
            // @ts-ignore
            setBathroom(basicFacilityMap['욕실']?.map(b => b.name) || [])
            // @ts-ignore
            setAirConditioning(basicFacilityMap['냉/난방']?.map(b => b.name) || [])
            // @ts-ignore
            setEntertainment(basicFacilityMap['엔터테인먼트']?.map(b => b.name) || [])
            // @ts-ignore
            setParking(basicFacilityMap['주차']?.map(b => b.name) || [])
            // @ts-ignore
            setEtc(basicFacilityMap['기타']?.map(b => b.name) || [])
        }
    }, [accommodationBasicFacilities])

    const onPressUpdate = async () => {
        const basicFacilities = [
            {type: 'SURROUNDING', labels: surrounding},
            {type: 'BEDROOM', labels: bedroom},
            {type: 'KITCHEN', labels: kitchen},
            {type: 'BATHROOM', labels: bathroom},
            {type: 'AIR_CONDITIONING', labels: airConditioning},
            {type: 'ENTERTAINMENT', labels: entertainment},
            {type: 'PARKING', labels: parking},
            {type: 'ETC', labels: etc},
        ]
        // @ts-ignore
        const result = await dispatch(requestRegisterBasicFacilities(
            accommodationId,
            basicFacilities))

        if (result.success) {
            notification.success({
                message: '기본 시설 수정에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        } else {
            modal.error({
                title: `기본 수정에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>기본 시설 수정</Typography.Title>
                </Space>

                <BasicFacilityInput
                    type={'주변'}
                    labels={surrounding}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setSurrounding(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newSurrounding = surrounding.filter(s => s !== label)
                        setSurrounding(newSurrounding)
                    }}
                />
                <BasicFacilityInput
                    type={'침실'}
                    labels={bedroom}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setBedroom(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newBedroom = bedroom.filter(s => s !== label)
                        setBedroom(newBedroom)
                    }}
                />
                <BasicFacilityInput
                    type={'주방'}
                    labels={kitchen}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setKitchen(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newKitchen = kitchen.filter(s => s !== label)
                        setKitchen(newKitchen)
                    }}
                />
                <BasicFacilityInput
                    type={'욕실'}
                    labels={bathroom}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setBathroom(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newBathroom = bathroom.filter(s => s !== label)
                        setBathroom(newBathroom)
                    }}
                />
                <BasicFacilityInput
                    type={'냉/난방'}
                    labels={airConditioning}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setAirConditioning(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newAirConditioning = airConditioning.filter(s => s !== label)
                        setAirConditioning(newAirConditioning)
                    }}
                />
                <BasicFacilityInput
                    type={'엔터테인먼트'}
                    labels={entertainment}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setEntertainment(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newEntertainment = entertainment.filter(s => s !== label)
                        setEntertainment(newEntertainment)
                    }}
                />
                <BasicFacilityInput
                    type={'주차'}
                    labels={parking}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setParking(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newParking = parking.filter(s => s !== label)
                        setParking(newParking)
                    }}
                />
                <BasicFacilityInput
                    type={'기타'}
                    labels={etc}
                    // @ts-ignore
                    onPressAddButton={(label: string) => setEtc(prevState => [...prevState, label])}
                    onPressDeleteButton={(label: string) => {
                        const newEtc = etc.filter(s => s !== label)
                        setEtc(newEtc)
                    }}
                />

                <Row style={{...CommonStyle.registerButtonContainerStyle, marginTop: 80}}>
                    <RegisterButton
                        onClick={onPressUpdate}
                        icon={<PlusOutlined/>}
                        label={'완료'}
                    />
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const BasicFacilityInput = ({type, labels, onPressAddButton, onPressDeleteButton}: {
    type: BasicFacilityType,
    labels: string[],
    onPressAddButton: (label: string) => void,
    onPressDeleteButton: (label: string) => void
}) => {
    const [label, setLabel] = useState('')
    const buttonStyle = {
        marginTop: 10,
        width: '100%',
        maxWidth: 100,
        height: 50,
        borderRadius: 16,
        backgroundColor: Color.darkPrimary,
        ...TextStyle,
        fontSize: 16,
        fontWeight: 700,
        color: Color.white,
    }

    const onClickButton = () => {
        if (!label) {
            return
        }

        onPressAddButton(label);
        setLabel('')
    }

    return (
        <>
            <EmptySpace size={20}/>
            <Typography.Title level={4} style={{...TextStyle}}>{type}</Typography.Title>
            <Row>
                <Col xs={20} xl={20}>
                    <Input
                        placeholder={'시설 이름 입력'}
                        value={label}
                        maxLength={32}
                        style={{...CommonStyle.enabledInputStyle, width: '98%'}}
                        onChange={(e) => setLabel(e.target.value)}
                    />
                </Col>
                <Col xs={4} xl={4}>
                    <Button
                        style={buttonStyle}
                        onClick={onClickButton}
                    >추가</Button>
                </Col>

                <Space className={'basic-type'} style={{width: '100%', marginTop: 20}}>
                    {labels.map(label => <FacilityLabel
                        removable
                        label={label}
                        onPressDeleteButton={() => onPressDeleteButton(label)}
                    />)}
                </Space>
            </Row>
        </>
    )
}

export default withPolicies(EditAccommodationBasicFacilityScreen)
