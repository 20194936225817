import {Button, Row, Space, Table, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import React from "react";
import {RoomType} from "../RegisterRoomTypeScreen";
import {moneyValue} from "../../../utils/string";
import {useNavigate} from "react-router-dom";

const RoomTypeInfo = ({roomTypes = []}: { roomTypes: any[] }) => {
    const navigate = useNavigate()

    if (roomTypes.length < 1) {
        return (
            <Space direction="vertical"
                   style={{width: '100%', marginTop: 120, alignItems: 'center', justifyContent: 'center'}}>
                <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 400}}>
                    등록한 객실 타입이 없어요.
                </Typography.Text>
                <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 400}}>
                    지금 등록하세요!
                </Typography.Text>
                <RegisterRoomTypeButton/>
            </Space>
        );
    }

    // antd Table 컴포넌트 코드
    const columns = [
        {
            title: '객실 타입',
            dataIndex: 'type',
            key: 'type',
            render: (type: string) => <Typography.Text
                style={{...TextStyle, fontSize: 16, fontWeight: 500}}>{type}</Typography.Text>
        },
        {
            title: '이름',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '기준 인원',
            dataIndex: 'standardNumberOfPeople',
            key: 'standardNumberOfPeople',
        },
        {
            title: '최대 인원',
            dataIndex: 'maxNumberOfPeople',
            key: 'maxNumberOfPeople',
        },
        {
            title: '평 수',
            dataIndex: 'roomSize',
            key: 'roomSize',
        },
        {
            title: '거실 수',
            dataIndex: 'numberOfLivingRoom',
            key: 'numberOfLivingRoom',
        },
        {
            title: '방 수',
            dataIndex: 'numberOfRoom',
            key: 'numberOfRoom',
        },
        {
            title: '욕실 수',
            dataIndex: 'numberOfBathroom',
            key: 'numberOfBathroom',
        },
        {
            title: '숙박 요금/박',
            dataIndex: 'prices', // 가격 데이터가 배열 안에 있는 경우
            key: 'prices',
            render: (prices: any[]) => {
                const priceLabels: number[] = []
                prices.forEach(price => {
                    priceLabels.push(price.weekday, price.weekend, price.holiday)
                })
                const price = `${moneyValue(Math.min(...priceLabels))} ~ ${moneyValue(Math.max(...priceLabels))}`
                return <span>{price} 원</span>
            },
        },
    ];

    interface RoomTypeDataType {
        key: string;
        id: number;
        name: string;
        type: string;
        standardNumberOfPeople: string;
        maxNumberOfPeople: string;
        roomSize: string;
        numberOfLivingRoom: string;
        numberOfRoom: string;
        numberOfBathroom: string;
        prices: any[];
    }

    const roomTypeDataSources: RoomTypeDataType[] = roomTypes.map((roomType, index) => {
        return {
            key: `${roomType.id}-${index}`,
            id: roomType.id as number,
            name: roomType.name as string,
            type: matchStringRoomType(roomType.type as RoomType),
            standardNumberOfPeople: roomType.standardNumberOfPeople as string,
            maxNumberOfPeople: roomType.maxNumberOfPeople as string,
            roomSize: roomType.roomSize as string,
            numberOfLivingRoom: roomType.numberOfLivingRoom as string,
            numberOfRoom: roomType.numberOfRoom as string,
            numberOfBathroom: roomType.numberOfBathroom as string,
            prices: roomType.prices,
        } as RoomTypeDataType
    });

    return (
        <Space direction="vertical" style={{width: '100%'}}>
            <Table
                columns={columns}
                dataSource={roomTypeDataSources}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            navigate(PageRoutes.dynamicPath("/rooms/room-types", record.id.toString()),
                                {state: record})
                        },
                    };
                }}
            />

            <Row style={CommonStyle.registerButtonContainerStyle}>
                <RegisterRoomTypeButton/>
            </Row>
        </Space>
    );
};

const RegisterRoomTypeButton = () => {
    return (
        <Button
            style={{
                marginTop: 16,
                display: 'flex',
                height: 50,
                alignItems: 'center',
                justifyContent: 'center',
                padding: '15px 20px',
                gap: 4,
                backgroundColor: Color.y40,
                borderColor: Color.y40,
                borderRadius: 16,
                ...TextStyle,
                fontSize: 16,
                fontWeight: 700,
            }}
            onClick={() => NavigationService.moveTo(PageRoutes.REGISTER_ROOM_TYPE)}
        >객실 타입 등록하기</Button>
    )
}

const matchStringRoomType = (roomType: RoomType) => {
    switch (roomType) {
        case "POOLVILLA":
            return '풀빌라'
        case "CONDO":
            return '콘도'
        case "PENSION":
            return '펜션'
        case "CARAVAN":
            return '카라반'
        case "GLAMPING":
            return '글램핑'
        case "CAMPING":
            return '캠핑'
        default:
            return ''
    }
}

export default RoomTypeInfo;
