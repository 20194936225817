// @ts-nocheck

import {Button, DatePicker, Input, message, Pagination, Row, Space, Table, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import React, {CSSProperties, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import dayjs from "dayjs";
import EmptySpace from "../../../components/EmptySpace";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import {formatPhoneNumber, isNumeric} from "../../../utils/string";
import {SearchReservationParam} from "../../../redux/Reservation";
import LocalStorageService from "../../../services/LocalStorageService";
import {PageRoutes} from "../../../App";
import {useNavigate} from "react-router-dom";

export const ReservationList = ({pageSize, onSearchReservationList}: {
    pageSize: number,
    onSearchReservationList: (page: number, size: number, param: SearchReservationParam) => void
}) => {
    const reservations = useSelector(state => state.Reservation.get('reservations'))
    const reservationTotalPages = useSelector(state => state.Reservation.get('reservationTotalPages'));
    const reservationTotalElements = useSelector(state => state.Reservation.get('reservationTotalElements'));
    const reservationCurrentPage = useSelector(state => state.Reservation.get('reservationCurrentPage'));

    const page = {
        size: pageSize,
        totalPages: reservationTotalPages,
        totalElements: reservationTotalElements,
        currentPage: reservationCurrentPage,
    }

    const initialParam = LocalStorageService.findSearchReservationParam()

    const [startDateParam, setStartDateParam] = useState(initialParam.startDate || '')
    const [endDateParam, setEndDateParam] = useState(initialParam.endDate || '')
    const [createdDateParam, setCreatedDateParam] = useState(initialParam.createdDate || '')
    const [nameOrPhoneNumberParam, setNameOrPhoneNumberParam] = useState(initialParam.nameOrPhoneNumber || '')

    return (
        <Row style={{marginTop: 20}}>
            <Space direction={'vertical'} style={{rowGap: 4, width: 150, marginRight: 10}}>
                <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.darkSecondary}}>
                    입실 일자
                </Typography.Text>
                <DatePicker
                    style={{...CommonStyle.enabledInputStyle, height: 40, marginTop: 0}}
                    placeholder={'입실 일자'}
                    value={startDateParam ? dayjs(startDateParam) : null}
                    onChange={(startDate) => {
                        if (!startDate) {
                            setStartDateParam('')
                            return
                        }

                        setStartDateParam(startDate.format('YYYY-MM-DDTHH:mm:ss'))
                    }}
                />
            </Space>
            <Space direction={'vertical'} style={{rowGap: 4, width: 150, marginRight: 10}}>
                <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.darkSecondary}}>
                    퇴실 일자
                </Typography.Text>
                <DatePicker
                    style={{...CommonStyle.enabledInputStyle, height: 40, marginTop: 0}}
                    placeholder={'퇴실 일자'}
                    value={endDateParam ? dayjs(endDateParam) : null}
                    onChange={(endDate) => {
                        if (!endDate) {
                            setEndDateParam('')
                            return
                        }

                        const startDate = startDateParam && dayjs(startDateParam);
                        if (startDate && endDate.isBefore(startDate)) {
                            message.error({content: '입실 일자보다 나중 일자로 선택해야 합니다.'})
                            setEndDateParam('')
                            return
                        }

                        setEndDateParam(endDate.format('YYYY-MM-DDTHH:mm:ss'))
                    }}
                />
            </Space>
            <Space direction={'vertical'} style={{rowGap: 4, width: 150, marginRight: 10}}>
                <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.darkSecondary}}>
                    예약 신청일
                </Typography.Text>
                <DatePicker
                    style={{...CommonStyle.enabledInputStyle, height: 40, marginTop: 0}}
                    placeholder={'예약 신청일'}
                    value={createdDateParam ? dayjs(createdDateParam) : null}
                    onChange={(createdDate) => {
                        if (!createdDate) {
                            setCreatedDateParam('')
                            setEndDateParam('')
                            return
                        }

                        setCreatedDateParam(createdDate.format('YYYY-MM-DDTHH:mm:ss'))
                    }}
                />
            </Space>
            <Space direction={'vertical'} style={{rowGap: 4, width: 200, marginRight: 10}}>
                <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 400, color: Color.darkSecondary}}>
                    예약자
                </Typography.Text>
                <Input
                    style={{
                        ...CommonStyle.enabledInputStyle,
                        width: 200,
                        height: 40,
                        marginTop: 0,
                        ...TextStyle,
                        fontSize: 16,
                        fontWeight: 400
                    }}
                    placeholder={'이름 또는 연락처'}
                    value={nameOrPhoneNumberParam}
                    onChange={(e) => {
                        const value = isNumeric(e.target.value) ? formatPhoneNumber(e.target.value) : e.target.value
                        setNameOrPhoneNumberParam(value)
                    }}
                />
            </Space>
            <Space direction={'vertical'}
                   style={{rowGap: 4, alignItems: 'center', justifyContent: 'center', marginRight: 10}}>
                <Button
                    style={{
                        borderRadius: 16,
                        height: 40,
                        marginTop: 25,
                        backgroundColor: Color.darkPrimary, ...TextStyle,
                        fontSize: 14,
                        fontWeight: 700,
                        color: Color.white,
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => {
                        onSearchReservationList(0, pageSize, {
                            startDate: startDateParam,
                            endDate: endDateParam,
                            createdDate: createdDateParam,
                            nameOrPhoneNumber: nameOrPhoneNumberParam
                        })
                    }}
                >검색</Button>
            </Space>
            <Space direction={'vertical'} style={{rowGap: 4, alignItems: 'center', justifyContent: 'center'}}>
                <Button
                    style={{
                        borderRadius: 16,
                        height: 40,
                        marginTop: 25,
                        backgroundColor: Color.darkPrimary, ...TextStyle,
                        fontSize: 14,
                        fontWeight: 700,
                        color: Color.white,
                        textAlign: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                    onClick={() => {
                        setStartDateParam('')
                        setEndDateParam('')
                        setNameOrPhoneNumberParam('')
                        onSearchReservationList(0, 10, {
                            startDate: null,
                            endDate: null,
                            createdDate: null,
                            nameOrPhoneNumber: null,
                        })
                    }}
                >필터 초기화</Button>
            </Space>

            <ReservationTable
                page={page}
                reservations={reservations}
                onSearchTodayCreated={() => onSearchReservationList(0, 10, {
                    startDate: null,
                    endDate: null,
                    createdDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
                    nameOrPhoneNumber: null,
                })}
                onSearchTodayStarted={() => onSearchReservationList(0, 10, {
                    startDate: dayjs().format('YYYY-MM-DDTHH:mm:ss'),
                    endDate: null,
                    createdDate: null,
                    nameOrPhoneNumber: null,
                })}
                onChangePage={(page: number, size: number, param?: SearchReservationParam) => onSearchReservationList(page, size, {
                    startDate: startDateParam,
                    endDate: endDateParam,
                    createdDate: createdDateParam,
                    nameOrPhoneNumber: nameOrPhoneNumberParam
                })}
            />
        </Row>
    )
}

const ReservationTable = ({page, reservations, onSearchTodayCreated, onSearchTodayStarted, onChangePage}: {
    page: {
        size: number,
        totalPages: number,
        totalElements: number,
        currentPage: number,
    },
    reservations: any[],
    onSearchTodayCreated: () => void,
    onSearchTodayStarted: () => void,
    onChangePage: (page: number, size: number, param?: SearchReservationParam) => void,
}) => {
    const navigate = useNavigate()

    interface ReservationDataType {
        key: string;
        id: number;
        code: string;
        createdDate: string;
        roomName: string;
        name: string;
        phoneNumber: string;
        startDate: string;
        endDate: string;
        paymentStatus: string;
    }

    const Column = ({value, bold = '400', width = 'auto'}: {
        value: string,
        bold?: string,
        width?: number | string
    }) => {
        return (
            <Space style={{rowGap: 0, columnGap: 0, width: width, minWidth: width}}>
                <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: bold}}>{value}</Typography.Text>
            </Space>
        )
    }

    const columns = [
        {
            title: '예약 번호',
            dataIndex: 'code',
            key: 'code',
            render: (code: string) => <Column value={code} width={85} bold={'700'}/>
        },
        {
            title: '결제 상태',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            render: (createdDate: string) => <Column value={createdDate} width={80}/>
        },
        {
            title: '객실',
            dataIndex: 'roomName',
            key: 'roomName',
            render: (value: string) => <Column value={value} width={150}/>
        },
        {
            title: '예약자',
            dataIndex: 'name',
            key: 'name',
            render: (value: string) => <Column value={value} width={80}/>
        },
        {
            title: '휴대폰번호',
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            render: (createdDate: string) => <Column value={createdDate} width={110}/>
        },
        {
            title: '입실 일자',
            dataIndex: 'startDate',
            key: 'startDate',
            render: (createdDate: string) => <Column value={dayjs(createdDate).format('YY.MM.DD')} width={60}/>
        },
        {
            title: '퇴실 일자',
            dataIndex: 'endDate',
            key: 'endDate',
            render: (createdDate: string) => <Column value={dayjs(createdDate).format('YY.MM.DD')} width={60}/>
        },
        {
            title: '예약 신청일',
            dataIndex: 'createdDate',
            key: 'createdDate',
            render: (createdDate: string) => <Column value={dayjs(createdDate).format('YYYY-MM-DD HH:mm:ss')}
                                                     width={150}/>
        },
    ]
    const reservationDataSources: ReservationDataType[] = reservations.map((reservation, index) => {
        return {
            key: `${reservation.id}-${index}`,
            id: reservation.id as number,
            code: reservation.code,
            createdDate: reservation.createdDate,
            roomName: reservation.roomName,
            name: reservation.name,
            phoneNumber: reservation.phoneNumber,
            startDate: reservation.startDate,
            endDate: reservation.endDate,
            paymentStatus: reservation.paymentStatus,
        }
    })

    const frequentlyButtonStyle: CSSProperties = {
        width: 'auto',
        height: 30,
        borderRadius: 8,
        backgroundColor: Color.b3,
        borderColor: Color.b3,
        ...TextStyle,
        fontSize: 12,
        fontWeight: 500,
        color: Color.darkPrimary,
    }

    return (
        <Space direction={'vertical'} style={{marginTop: 10}}>
            <Space>
                <Button
                    style={frequentlyButtonStyle}
                    onClick={onSearchTodayCreated}
                >오늘 신청된 예약</Button>
                <Button
                    style={frequentlyButtonStyle}
                    onClick={onSearchTodayStarted}
                >오늘 입실</Button>
            </Space>

            <Table
                style={{marginTop: 20, width: '100%'}}
                columns={columns}
                dataSource={reservationDataSources}
                pagination={false}
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => {
                            navigate(PageRoutes.dynamicPath("/reservations", record.id.toString()),
                                {state: record})
                        },
                    };
                }}
            />
            <Pagination
                style={{marginTop: 10}}
                showSizeChanger={false}
                pageSize={page.size}
                current={page.currentPage + 1}
                total={page.totalElements}
                onChange={(page, pageSize) => onChangePage(page - 1, pageSize)}
            />
        </Space>
    )
}
