import _ from "lodash";
import {Checkbox, Col, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {isMobile} from "react-device-detect";

interface PaidFacility {
    id: number,
    name: string,
}

const AccommodationPaidFacility = ({selectedPaidFacilityIds, paidFacilities, onUpdate}: {
    selectedPaidFacilityIds: number[],
    paidFacilities: PaidFacility[],
    onUpdate: any
}) => {
    const updateSelectedFacilityIds = (checked: boolean, facility: PaidFacility) => {
        if (checked) {
            onUpdate([...selectedPaidFacilityIds, facility.id]);
            return
        }

        onUpdate([...selectedPaidFacilityIds.filter(id => id !== facility.id)])
   }

    return (
        <Space direction={'vertical'} style={{marginTop: 12}}>
            <Row style={{width: '100%'}}>
                <Col xs={24} xl={24}>
                    {paidFacilities.map(facility => (
                        <Checkbox
                            key={facility.id}
                            onChange={(e) => updateSelectedFacilityIds(e.target.checked, facility)}
                            style={{
                                width: isMobile ? 140 : 200,
                                marginLeft: 0,
                                marginRight: 20,
                                justifyContent: 'flex-start',
                                overflow: 'visible',
                                ...TextStyle,
                                fontSize: 18,
                                fontWeight: 500,
                                color: Color.darkTertiary,
                            }}
                        >
                            {facility.name}
                        </Checkbox>
                    ))}
                </Col>
            </Row>
        </Space>
    )
}

export default AccommodationPaidFacility
