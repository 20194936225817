import DaumPostcodeEmbed, {Address} from 'react-daum-postcode';
import {Modal} from "antd";

const PostCodeModal = ({isOpenModal, setIsOpenModal, onComplete}: {
    isOpenModal: boolean,
    setIsOpenModal: any,
    onComplete: any
}) => {
    const onToggleModal = () => {
        setIsOpenModal((isOpenModal: boolean) => !isOpenModal);
    };

    const handleComplete = (data: Address) => {
        onComplete(data)
        setIsOpenModal((isOpenModal: boolean) => !isOpenModal);
    };

    return (
        isOpenModal ? (
            <Modal
                open={true}
                onOk={onToggleModal}
                onCancel={onToggleModal}
                cancelText={'닫기'}
                okText={'확인'}
            >
                <DaumPostcodeEmbed onComplete={handleComplete}/>
            </Modal>
        ) : null
    )
}

export default PostCodeModal
