// @ts-nocheck

import {Calendar, Image, Modal, Row, Space, Typography} from "antd";
import LeftButtonIcon from '../../../assets/icon/leftButton.png'
import RightButtonIcon from '../../../assets/icon/rightButton.png'
import {Color, TextStyle} from "../../../constants/style";
import {useEffect, useState} from "react";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween'
import EmptySpace from "../../../components/EmptySpace";
import {moneyValue} from "../../../utils/string";
import {useDispatch} from "react-redux";
import {requestMonthlyStatistics} from "../../../redux/Reservation";

dayjs.extend(isBetween)


export const MonthlyStatistics = ({}) => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()
    const [dateValue, setDateValue] = useState(dayjs())
    const [monthlyStatistics, setMonthlyStatistics] = useState([])

    useEffect(() => {
        requestMonthlyStatisticsApi()
    }, [dateValue])

    const requestMonthlyStatisticsApi = async () => {
        const result = await dispatch(requestMonthlyStatistics(
            dateValue.subtract(14, 'day').format('YYYY-MM-DD'),
            dateValue.add(1, 'month').format('YYYY-MM-DD')
        ))

        if (result.success) {
            setMonthlyStatistics(result.monthlyStatistics)
        } else {
            modal.error({
                title: `월별 현황 데이터를 불러오지 못했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <Space direction={'vertical'}>
            <YearMonthSelector value={dateValue} onChange={value => setDateValue(value)}/>
            <ColorLabelGroup/>
            <EmptySpace size={20}/>
            <Calendar
                fullscreen
                style={{minWidth: 700}}
                value={dateValue}
                onPanelChange={(v) => setDateValue(v)}
                fullCellRender={(date, info) => {
                    let calendarRow = [];
                    for (let i = 0; i < monthlyStatistics.length; i++) {
                        let hasReservation = false
                        const reservations = monthlyStatistics[i]?.reservations

                        const roomNameLimitedLength = monthlyStatistics[i]?.roomName

                        for (let j = 0; j < reservations.length; j++) {
                            const startDate = dayjs(reservations[j].startDate)
                            const endDate = dayjs(reservations[j].endDate)
                            const isStartDate = date.isSame(startDate, 'day')
                            const isEndDate = date.isSame(endDate, 'day')
                            hasReservation = date.isBetween(startDate, endDate) || isStartDate || isEndDate
                            if (hasReservation) {
                                const label = `${monthlyStatistics[i]?.roomName} / ${reservations[j].customerName}`
                                calendarRow.push(
                                    <div
                                        key={i}
                                        style={{
                                            display: 'flex',
                                            margin: 5,
                                            padding: 5,
                                            width: isStartDate ? '95%' : '100%',
                                            height: 25,
                                            borderTopLeftRadius: isStartDate ? 8 : 0,
                                            borderBottomLeftRadius: isStartDate ? 8 : 0,
                                            borderTopRightRadius: isEndDate ? 8 : 0,
                                            borderBottomRightRadius: isEndDate ? 8 : 0,
                                            alignItems: 'center',
                                            justifyContent: 'flex-start',
                                            backgroundColor: reservations[j].paymentStatus === '결제 대기' ? Color.b40 : Color.g40,
                                        }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            modal.info({
                                                title: '예약 정보',
                                                content: <ReservationInfoLabelGroup
                                                    roomName={monthlyStatistics[i]?.roomName}
                                                    reservation={reservations[j]}/>

                                            })
                                        }}
                                    >
                                        {isStartDate && (
                                            <Typography.Text ellipsis style={{
                                                ...TextStyle,
                                                fontSize: 14,
                                                fontWeight: 500,
                                                color: Color.white,
                                            }}>{label}</Typography.Text>
                                        )}
                                    </div>
                                )
                                break;
                            }
                        }
                        if (!hasReservation) {
                            calendarRow.push(
                                <div
                                    key={i}
                                    style={{
                                        display: 'flex',
                                        margin: 5,
                                        padding: 5,
                                        width: '95%',
                                        height: 25,
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        backgroundColor: Color.black3,
                                    }}
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        // modal.confirm({
                                        //     title: '방막기(관리자예약)',
                                        //     content: `${dateValue.format('YY.MM.DD')} ${MOCK_MONTHLY_STATISTICS[i].roomName}에 방막기(관리자예약) 처리를 하시겠습니까?`
                                        // })
                                    }}
                                >
                                    <Typography.Text
                                        ellipsis
                                        style={{
                                            ...TextStyle,
                                            fontSize: 14,
                                            fontWeight: 500,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                        }}>{monthlyStatistics[i]?.roomName}</Typography.Text>
                                </div>
                            )
                        }
                    }

                    return (
                        <div style={{
                            marginTop: 10,
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%',
                            height: 'auto',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                        }}>
                            <Typography.Text style={{
                                paddingRight: 10,
                                ...TextStyle,
                                fontSize: 14,
                                fontWeight: 500,
                            }}>{date.format('MM.DD')}</Typography.Text>
                            {calendarRow}
                        </div>
                    )
                }}
                headerRender={() => null}
            />
            {contextHolder}
        </Space>
    )
}

const YearMonthSelector = ({value, onChange}) => {
    const [year, setYear] = useState(dayjs().year())
    const [month, setMonth] = useState(dayjs().month() + 1)

    useEffect(() => {
        setYear(value.year())
        setMonth(value.month() + 1)
    }, [value])

    useEffect(() => {
        onChange(dayjs(`${year}-${month}-01`))
    }, [year, month])

    const onClickMinus = () => {
        if (month === 1) {
            setYear(year - 1);
            setMonth(12);
        } else {
            setMonth(month - 1)
        }
    }

    const onClickPlus = () => {
        if (month === 12) {
            setYear(year + 1);
            setMonth(1);
        } else {
            setMonth(month + 1)
        }
    }

    const monthToText = () => month.toString().length <= 1 ? `0${month}` : month

    return (
        <Space style={{marginTop: 40, marginBottom: 60, width: '100%', justifyContent: 'center'}}>
            <Space style={{width: 300, height: 40, alignItems: 'center', justifyContent: 'space-between'}}>
                <Image preview={false} src={LeftButtonIcon} style={{width: 34, height: 34}} onClick={onClickMinus}/>
                <Typography.Text style={{...TextStyle, fontSize: 26, fontWeight: 700, color: Color.darkPrimary}}>
                    {`${year}. ${monthToText()}`}
                </Typography.Text>
                <Image preview={false} src={RightButtonIcon} style={{width: 34, height: 34}} onClick={onClickPlus}/>

                <Typography.Text
                    style={{marginLeft: 10, ...TextStyle, fontSize: 12, fontWeight: 400, color: Color.darkSecondary}}
                    onClick={() => {
                        setYear(dayjs().year())
                        setMonth(dayjs().month() + 1)
                    }}
                >이번 달</Typography.Text>
            </Space>
        </Space>
    )
}

const ColorLabelGroup = () => {
    return (
        <Space style={{width: '100%', alignItems: 'center', justifyContent: 'end'}}>
            <ColorLabel color={Color.black3} label={'예약 가능'}/>
            <ColorLabel color={Color.b40} label={'결제 대기'}/>
            <ColorLabel color={Color.g40} label={'예약 확정'}/>
            <ColorLabel color={Color.r3} enableBorder={true} label={'방막기(관리자예약)'}/>
        </Space>
    )
}

const ColorLabel = ({color, enableBorder, label}) => {
    return (
        <Space style={{alignItems: 'center', justifyContent: 'center', paddingLeft: 10, rowGap: 0, columnGap: 4}}>
            <div style={{
                width: 20,
                height: 20,
                borderRadius: 4,
                border: enableBorder ? '1px solid #FF6157' : null,
                backgroundColor: color
            }}/>
            <Typography.Text style={{...TextStyle, fontSize: 14, fontWeight: 500, color: Color.darkSecondary}}>
                {label}
            </Typography.Text>
        </Space>
    )
}

const ReservationInfoLabelGroup = ({roomName, reservation}) => (
    <Space direction={'vertical'} style={{
        marginTop: 10,
        marginBottom: 10,
        width: '100%',
    }}>
        <ReservationInfoLabel label={'예약 코드'}
                              text={reservation?.reservationCode}/>
        <ReservationInfoLabel label={'객실'}
                              text={roomName}/>
        <ReservationInfoLabel label={'고객명'}
                              text={reservation?.customerName}/>
        <ReservationInfoLabel label={'휴대폰번호'}
                              text={reservation?.customerPhoneNumber}/>
        <ReservationInfoLabel label={'예약 시작 일자'}
                              text={reservation?.startDate}/>
        <ReservationInfoLabel label={'예약 종료 일자'}
                              text={reservation?.endDate}/>
        <ReservationInfoLabel label={'총 요금'}
                              text={`${moneyValue(reservation?.totalPrice)}원`}/>
    </Space>
)

const ReservationInfoLabel = ({label, text}) => {
    return (
        <Space style={{
            width: '100%',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            textAlign: 'left',
        }}>
            <Typography.Text
                style={{...TextStyle, fontSize: 14, fontWeight: 500}}>
                {label}
            </Typography.Text>
            <Typography.Text
                style={{...TextStyle, fontSize: 14, fontWeight: 400}}>
                {text}
            </Typography.Text>
        </Space>
    )
}
