import React from "react";
import {Color} from "../../constants/style";
import {Typography} from "antd";

const RequiredFieldMark = ({disabled = false}: { disabled?: boolean }) => {
    if (disabled) {
        return null
    }

    return <Typography.Text strong style={{fontSize: 18, color: Color.r50, marginLeft: 5}}>*</Typography.Text>;
}

export default RequiredFieldMark
