import {Color, TextStyle} from "../../../constants/style";

const labelStyle = {
    ...TextStyle,
    fontWeight: 700,
    fontSize: 18,
}
const inputStyle = {
    marginTop: 12,
    width: '100%',
    height: 50,
    backgroundColor: Color.black3,
    borderColor: Color.black10,
    borderRadius: 16,
    ...TextStyle,
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 16,
    fontWeight: 400,
    fontSize: 18,
    color: '#60606C',
}
const enabledInputStyle = {
    ...inputStyle,
    backgroundColor: Color.white,
}
const innerLabelStyle = {
    ...TextStyle,
    fontWeight: 500,
    fontSize: 16,
    color: Color.darkTertiary,
}
const dropdownOverlayStyle = {
    maxHeight: 250,
    overflow: 'auto',
    padding: 1,
    borderRadius: 8,
    backgroundColor: Color.greyBorder,
}
const registerButtonContainerStyle = {
    marginTop: 80,
    marginBottom: 20,
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
}
const smallDisabledButtonStyle = {
    width: 110,
    height: 64,
    borderRadius: 40,
    backgroundColor: Color.black5,
    borderColor: Color.black5,
    ...TextStyle,
    fontSize: 18,
    fontWeight: 700,
}
const smallEnabledButtonStyle = {
    width: 110,
    height: 64,
    borderRadius: 40,
    backgroundColor: Color.y40,
    borderColor: Color.y40,
    ...TextStyle,
    fontSize: 18,
    fontWeight: 700,
}

export const CommonStyle = {
    labelStyle,
    inputStyle,
    enabledInputStyle,
    innerLabelStyle,
    dropdownOverlayStyle,
    registerButtonContainerStyle,
    smallDisabledButtonStyle,
    smallEnabledButtonStyle,
}
