import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {requestAccommodations, requestUpdateUsagePolicy} from "../../../redux/Accommodation";
import {Button, Col, Input, message, Modal, notification, Row, Space, Typography} from "antd";
import {TextStyle} from "../../../constants/style";
import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {CommonStyle} from "../AccommodationScreen/style";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import withPolicies from "../../../components/withPolicies";

const EditAccommodationUsagePolicyScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationUsagePolicy = useSelector(state => state.Accommodation.get('usagePolicy'))

    const [usagePolicy, setUsagePolicy] = useState('')

    useEffect(() => {
        if (accommodationUsagePolicy) {
            setUsagePolicy(accommodationUsagePolicy)
        }
    }, [accommodationUsagePolicy])

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    const onPressUpdate = async () => {
        const result = await dispatch(
            // @ts-ignore
            requestUpdateUsagePolicy(accommodationId, usagePolicy))

        if (result.success) {
            notification.success({
                message: '이용 정책 수정에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        } else {
            modal.error({
                title: `이용 정책 수정에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>이용 정책 수정</Typography.Title>
                </Space>
                <Row style={{marginTop: 20}}>
                    <Col xs={24} xl={24}>
                        <Input.TextArea
                            autoSize
                            style={CommonStyle.enabledInputStyle}
                            value={usagePolicy}
                            onChange={e => setUsagePolicy(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row>
                    <Space style={{width: '100%', marginTop: 20, justifyContent: 'flex-end'}}>
                        <Button
                            style={CommonStyle.smallDisabledButtonStyle}
                            onClick={() => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS)}
                        >
                            취소
                        </Button>
                        <Button
                            style={CommonStyle.smallEnabledButtonStyle}
                            onClick={onPressUpdate}
                        >
                            완료
                        </Button>
                    </Space>
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

export default withPolicies(EditAccommodationUsagePolicyScreen)
