import React, {CSSProperties} from "react";
import {Layout, Menu, MenuProps, Modal, Typography} from "antd";
import {
    CalendarOutlined, HomeOutlined, LineChartOutlined, MessageOutlined, NotificationOutlined,
    PlusSquareOutlined, TeamOutlined, UserAddOutlined,
} from "@ant-design/icons";
import {TextStyle} from "../../constants/style";
import NavigationService from "../../services/NavigationService";
import {PageRoutes} from "../../App";

type MenuItem = Required<MenuProps>['items'][number];

const SideMenu = ({containerStyle, menuStyle, titleIndent = 0, mode}: {
    containerStyle?: CSSProperties,
    menuStyle?: CSSProperties,
    titleIndent?: number,
    mode: any
}) => {
    const moveTo: (path: string) => any = (path: string) => NavigationService.moveTo(path)
    const [modal, contextHolder] = Modal.useModal()
    const toBeContinued = (label: string) => modal.info({
        title: '정식 버전을 기다려주세요!',
        content: `${label} 기능은 정식 버전에서 제공될 예정입니다.`
    })

    const items: MenuItem[] = [
        getItem('예약 현황', '1', () => moveTo(PageRoutes.RESERVATIONS), <CalendarOutlined/>),
        getItem('숙소 관리', '2', () => moveTo(PageRoutes.ACCOMMODATIONS), <HomeOutlined/>),
        getItem('객실 관리', '3', () => moveTo(PageRoutes.ROOMS), <PlusSquareOutlined/>),
        getItem('메시지 관리', '4', () => toBeContinued('메세지 관리'), <MessageOutlined/>),
        getItem('매출 통계', '5', () => toBeContinued('매출 통계'), <LineChartOutlined/>),
        getItem('고객 관리', '6', () => toBeContinued('고객 관리'), <TeamOutlined/>),
        getItem('공지 관리', '7', () => toBeContinued('공지 관리'), <NotificationOutlined/>),
        getItem('계정 관리', '8', () => toBeContinued('계정 관리'), <UserAddOutlined/>),
    ];

    return (
        <Layout.Sider width={200} style={containerStyle}>
            <Typography.Title level={4} style={{...TextStyle, marginLeft: titleIndent}}>메뉴</Typography.Title>
            <Menu
                style={menuStyle}
                mode={mode}
                items={items}
            />
            {contextHolder}
        </Layout.Sider>
    );
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    onClick: () => any,
    icon?: React.ReactNode,
    children?: MenuItem[],
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        onClick,
    } as MenuItem;
}

export default SideMenu;
