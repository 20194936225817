import {Map} from 'immutable';
import {SET_IS_LOADING_ROOM, setIsLoading} from "../Loading";
import RoomApi from "../../apis/RoomApi";
import {PagingAndSortingParam} from "../../apis/ApiUtil";
import roomInfo from "../../screens/Room/RoomMainScreen/RoomInfo";
import {Content} from "../../apis/ContentApi";

// action types
const SAVE_ROOM_TYPES = 'Room/SAVE_ROOM_TYPES'
const SAVE_ROOM_TYPE = 'Room/SAVE_ROOM_TYPE'
const SAVE_ROOM_TYPE_NAMES = 'Room/SAVE_ROOM_TYPE_NAMES'
const SAVE_ROOMS = 'Room/SAVE_ROOMS'
const SAVE_ROOM_DETAIL = 'Room/SAVE_ROOM_DETAIL'

// action creators
const saveRoomTypes = (roomTypes: any[]) => ({
    type: SAVE_ROOM_TYPES,
    payload: {
        roomTypes,
    },
});

const saveRoomTypeDetail = (roomTypeDetail: any[]) => ({
    type: SAVE_ROOM_TYPE,
    payload: {
        roomTypeDetail,
    }
})

const saveRoomTypeNames = (roomTypeNames: any[]) => ({
    type: SAVE_ROOM_TYPE_NAMES,
    payload: {
        roomTypeNames,
    }
})

const saveRooms = (roomTotalPages: number, roomTotalElements: number, roomCurrentPage: number, rooms: any[]) => ({
    type: SAVE_ROOMS,
    payload: {
        roomTotalPages,
        roomTotalElements,
        roomCurrentPage,
        rooms,
    }
})

const saveRoomDetail = (roomDetail: any) => ({
    type: SAVE_ROOM_DETAIL,
    payload: {
        roomDetail,
    }
})

// initialState
const initialState = Map({
    roomTypes: [],
    roomTypeNames: [],
    rooms: [],
    roomDetail: null,
    roomTotalPages: 0,
    roomTotalElements: 0,
    roomCurrentPage: 0,
    roomTypeDetail: null,
});

// reducer
export const reducer = (state = initialState, action: any) => {
    switch (action.type) {
        case SAVE_ROOM_TYPES:
            return state.set('roomTypes', action.payload.roomTypes)
        case SAVE_ROOM_TYPE_NAMES:
            return state.set('roomTypeNames', action.payload.roomTypeNames)
        case SAVE_ROOMS:
            return state.set('rooms', action.payload.rooms)
                .set('roomTotalPages', action.payload.roomTotalPages)
                .set('roomTotalElements', action.payload.roomTotalElements)
                .set('roomCurrentPage', action.payload.roomCurrentPage)
        case SAVE_ROOM_DETAIL:
            return state.set('roomDetail', action.payload.roomDetail)
        case SAVE_ROOM_TYPE:
            return state.set('roomTypeDetail', action.payload.roomTypeDetail)
        default:
            return state;
    }
};

export const requestRegisterRoomType = (
    accommodationId: number,
    typeName: string,
    typeCategory: string,
    standardNumberOfPeople: string,
    maxNumberOfPeople: string,
    additionalFeeAdult: string,
    additionalFeeChild: string,
    additionalFeeBaby: string,
    roomSize: string,
    numberOfLivingRoom: string,
    numberOfRoom: string,
    numberOfBathroom: string,
    roomDescription: string,
    selectedPriceIds: number[],
    selectedBasicFacilityIds: number[],
    selectedPaidFacilityIds: number[]
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {
            response, json
        } = await RoomApi.createRoomType(
            accommodationId,
            typeName,
            typeCategory,
            standardNumberOfPeople,
            maxNumberOfPeople,
            additionalFeeAdult,
            additionalFeeChild,
            additionalFeeBaby,
            roomSize,
            numberOfLivingRoom,
            numberOfRoom,
            numberOfBathroom,
            roomDescription,
            selectedPriceIds,
            selectedBasicFacilityIds,
            selectedPaidFacilityIds
        )

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestUpdateRoomType = (
    roomTypeId: number,
    typeName: string,
    typeCategory: string,
    standardNumberOfPeople: string,
    maxNumberOfPeople: string,
    additionalFeeAdult: string,
    additionalFeeChild: string,
    additionalFeeBaby: string,
    roomSize: string,
    numberOfLivingRoom: string,
    numberOfRoom: string,
    numberOfBathroom: string,
    roomDescription: string,
    selectedPriceIds: number[],
    selectedBasicFacilityIds: number[],
    selectedPaidFacilityIds: number[]
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {
            response, json
        } = await RoomApi.updateRoomType(
            roomTypeId,
            typeName,
            typeCategory,
            standardNumberOfPeople,
            maxNumberOfPeople,
            additionalFeeAdult,
            additionalFeeChild,
            additionalFeeBaby,
            roomSize,
            numberOfLivingRoom,
            numberOfRoom,
            numberOfBathroom,
            roomDescription,
            selectedPriceIds,
            selectedBasicFacilityIds,
            selectedPaidFacilityIds
        )

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestRoomTypeList = (params?: PagingAndSortingParam) => async (dispatch: any, getState: any) => {
    if (!params) {
        params = {
            page: 0,
            size: 10
        };
    }

    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.getRoomTypes(params)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            dispatch(saveRoomTypes(json?.data?.content))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
        return Promise.resolve({success: false, error: e})
    }
};

export const requestRoomTypeDetail = (roomTypeId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.getRoomType(roomTypeId)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            dispatch(saveRoomTypeDetail(json?.data))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
        return Promise.resolve({success: false, error: e})
    }
}

export const requestRoomTypeNames = () => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.getRoomTypeNames();

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            dispatch(saveRoomTypeNames(json?.data || []))
            return Promise.resolve({success: true});
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e});
    }
};

export const requestRegisterRoom = (
    accommodationId: number,
    roomName: string,
    roomTypeId: number,
    isDisplay: boolean,
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {
            response, json
        } = await RoomApi.createRoom(
            accommodationId,
            roomName,
            roomTypeId,
            isDisplay)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            return Promise.resolve({success: true, roomId: json?.data?.id})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestUpdateRoom = (
    roomId: number,
    roomName: string,
    roomTypeId: number,
    isDisplay: boolean,
    contents: Content[],
) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {
            response, json
        } = await RoomApi.updateRoom(
            roomId,
            roomName,
            roomTypeId,
            isDisplay,
            contents)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false))
            return Promise.resolve({success: true, roomId: json?.data?.id})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e})
    }
}

export const requestRooms = (roomTypeId: number, pagingAndSortingParam: PagingAndSortingParam) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.getRooms(pagingAndSortingParam, roomTypeId);

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            dispatch(saveRooms(json?.data?.totalPages, json?.data?.totalElements, json?.data?.number, json?.data?.content || []))
            return Promise.resolve({success: true});
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e});
    }
};

export const requestRoomDetail = (roomId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.getRoomDetail(roomId);

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            dispatch(saveRoomDetail(json?.data || {}))
            return Promise.resolve({success: true});
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e});
    }
}

export const requestDeleteRoomType = (roomTypeId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.deleteRoomType(roomTypeId);

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: true});
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e});
    }
}

export const requestDeleteRoom = (roomId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_ROOM, true))

    try {
        const {response, json} = await RoomApi.deleteRoom(roomId);

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: true});
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_ROOM, false));
        return Promise.resolve({success: false, error: e});
    }
}
