import {Navigate} from "react-router-dom";
import React from "react";
import {PageRoutes} from "../../App";
import withPolicies from "../../components/withPolicies";

const AppBase = () => {
    return <Navigate to={PageRoutes.RESERVATIONS}/>
};

export default withPolicies(AppBase);
