import ScreenOuter from "../../../components/ScreenOuter"
import ScreenGrid from "../../../components/ScreenGrid";
import {
    Button,
    Col,
    Dropdown,
    Image,
    Input,
    MenuProps,
    message,
    Modal,
    notification,
    Radio,
    Row,
    Space,
    Typography
} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import RequiredFieldMark from "../../../components/RequiredFieldMark";
import React, {CSSProperties, useEffect, useState} from "react";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import {DeleteFilled, DownOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useDispatch, useSelector} from "react-redux";
import {requestRegisterRoom, requestRoomTypeNames, requestUpdateRoom} from "../../../redux/Room";
import PresignedUrlImageRegistration from "../../../components/PresignedUrlImageRegistration";
import {CDN_URL} from "../../../constants/content";
import RegisterButton from "../../../components/RegisterButton";
import {requestAccommodations} from "../../../redux/Accommodation";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import {useLocation} from "react-router-dom";
import withPolicies from "../../../components/withPolicies";

const EditRoomDetailScreen = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const roomDetail = location?.state
    const imageUrls: string[] = roomDetail?.roomContents?.map((image: any) => new URL(image.url).pathname)

    const [roomName, setRoomName] = useState(roomDetail?.name || '')
    const [selectedRoomTypeId, setSelectedRoomTypeId] = useState(roomDetail?.roomTypeId || 0)
    const [selectedRoomTypeName, setSelectedRoomTypeName] = useState(roomDetail?.roomTypeName || '')
    const [isDisplay, setIsDisplay] = useState(roomDetail?.isDisplay || false)
    const [imagePathList, setImagePathList] = useState(imageUrls || [])
    const [dropdownRoomTypeNames, setDropdownRoomTypeNames] = useState([])

    const [modal, contextHolder] = Modal.useModal()
    const [enabledTab, setEnabledTab] = useState('picture')

    // @ts-ignore
    const roomTypeNames = useSelector(state => state.Room.get('roomTypeNames'))

    useEffect(() => {
        // @ts-ignore
        dispatch(requestRoomTypeNames())
    }, [])


    useEffect(() => {
        if (roomTypeNames && roomTypeNames.length > 0) {
            setDropdownRoomTypeNames(
                roomTypeNames.map((roomTypeName: any) => ({
                    key: roomTypeName.name,
                    label: roomTypeName.name,
                }))
            )
        }
    }, [roomTypeNames])

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        // @ts-ignore
        setSelectedRoomTypeId(roomTypeNames.find(roomTypeName => roomTypeName.name === e.key)?.id || 0)
        // @ts-ignore
        setSelectedRoomTypeName(e.key)
    }
    const menuProps = {
        items: dropdownRoomTypeNames,
        selectable: true,
        onClick: handleMenuClick
    };

    const enableTabStyle = (type: string) => enabledTab === type ? innerTopTabStyle : {
        ...innerTopTabStyle,
        backgroundColor: Color.white,
        borderColor: '#E5E5E7',
    }

    const pictureWidth = 280
    const pictureHeight = 193
    const pictureBoxStyle = {
        margin: 10,
        borderRadius: 16,
        backgroundColor: Color.white,
        borderColor: Color.white,
        width: pictureWidth,
        height: pictureHeight + 40,
    }

    const onDeletePicture = (deleteImagePath: string) => {
        setImagePathList(imagePathList.filter(imagePath => imagePath !== deleteImagePath))
    }

    const onPressUpdateButton = async () => {
        const result = await dispatch(
            // @ts-ignore
            requestUpdateRoom(
                roomDetail?.id,
                roomName,
                selectedRoomTypeId,
                isDisplay,
                imagePathList.map(imagePath => ({
                    type: 'IMAGE',
                    filePath: imagePath,
                }))))

        if (result.success) {
            notification.success({
                message: '객실 수정에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ROOMS),
            })
        } else {
            modal.error({
                title: `객실 수정에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <ScreenOuter>
            <ScreenGrid>
                <Header/>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            기본 정보 <RequiredFieldMark/>
                        </Typography.Text>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                객실 이름
                            </Typography.Text>
                            <Input
                                style={{...CommonStyle.enabledInputStyle, marginTop: 0}}
                                value={roomName}
                                placeholder={'예시. A동, 101동'}
                                onChange={e => setRoomName(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={24} xl={12}>
                        <Space direction={"vertical"} style={{width: '95%', marginTop: 12}}>
                            <Typography.Text style={CommonStyle.innerLabelStyle}>
                                객실 타입
                            </Typography.Text>
                            <Dropdown
                                overlayStyle={CommonStyle.dropdownOverlayStyle}
                                menu={menuProps}
                            >
                                <Button style={{
                                    width: '100%',
                                    height: 50,
                                    borderRadius: 16,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    ...TextStyle,
                                    alignItems: 'center',
                                    justifyItems: 'center',
                                }} size='large'>
                                    {selectedRoomTypeName || '객실 타입 선택'}
                                    <DownOutlined/>
                                </Button>
                            </Dropdown>
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            고객이 예약 가능하도록 고객 사이트에 노출할까요?
                        </Typography.Text>
                        <Radio.Group onChange={e => setIsDisplay(e.target.value)} value={isDisplay}>
                            <Radio
                                style={{...TextStyle, fontSize: 18, fontWeight: 500}}
                                value={true}
                            >예</Radio>
                            <Radio
                                style={{...TextStyle, fontSize: 18, fontWeight: 500}}
                                value={false}
                            >아니오</Radio>
                        </Radio.Group>
                    </Space>
                </Row>
                <Row>
                    <Col xs={12} xl={12}>
                        <Space style={{marginTop: 40}}>
                            <Button
                                style={enableTabStyle('picture')}
                                onClick={() => setEnabledTab('picture')}
                            >사진</Button>
                            <Button
                                style={enableTabStyle('video')}
                                onClick={() => modal.info({title: '동영상 업로드 기능은 곧 제공될 예정입니다.'})}
                            >동영상</Button>
                        </Space>
                    </Col>
                    <Col xs={12} xl={12}>
                        <Space style={{width: '100%', justifyContent: 'flex-end'}}>
                            <PresignedUrlImageRegistration
                                imageType={'ACCOMMODATION_IMAGES'}
                                registerImagePath={(imagePath: string) => {
                                    // @ts-ignore
                                    setImagePathList(prevState => [...prevState, imagePath])
                                    return Promise.resolve({response: {ok: true}})
                                }}
                                onCompletedUpload={() => null}
                            />
                        </Space>
                    </Col>
                </Row>
                {enabledTab === 'picture' ? (
                    <>
                        <Row>
                            <Col xs={24} xl={24} style={{marginTop: 40}}>
                                {imagePathList.length > 0 ? (
                                    imagePathList.map((imagePath: any, index: number) => {
                                        return (
                                            <Space key={index} direction={'vertical'} style={{...pictureBoxStyle}}>
                                                <Image
                                                    preview={true}
                                                    src={`${CDN_URL}${imagePath}`}
                                                    width={pictureWidth}
                                                    height={pictureHeight}
                                                    style={{
                                                        borderRadius: 16,
                                                    }}
                                                />
                                                <Button
                                                    style={{
                                                        gap: 0,
                                                        padding: 0,
                                                        marginTop: 0,
                                                        width: pictureWidth,
                                                        height: 40,
                                                        color: Color.white,
                                                        backgroundColor: Color.darkPrimary,
                                                        borderColor: Color.black5,
                                                    }}
                                                    icon={<DeleteFilled/>}
                                                    onClick={() => modal.confirm({
                                                        title: '사진을 삭제하시겠습니까?',
                                                        okText: '삭제',
                                                        cancelText: '취소',
                                                        onOk: () => onDeletePicture(imagePath),
                                                    })}
                                                />
                                            </Space>
                                        )
                                    })
                                ) : (
                                    <Row>
                                        <Col xs={24} xl={24}>
                                            <EmptyImageLabel/>
                                        </Col>
                                    </Row>
                                )}
                            </Col>
                        </Row>
                        <Row style={CommonStyle.registerButtonContainerStyle}>
                            <RegisterButton
                                disabled={(!roomName || !selectedRoomTypeId)}
                                icon={<EditOutlined/>}
                                label={'객실 수정하기'}
                                onClick={onPressUpdateButton}
                            />
                        </Row>
                    </>
                ) : (
                    <>
                        {/*TODO: 동영상 등록 기능*/}
                    </>
                )}
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const Header = ({}) => {
    return (
        <Space direction={'vertical'} style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={2} style={{...TextStyle}}>객실 수정</Typography.Title>
            <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 400}}><RequiredFieldMark/> 로 표시된 필수 항목을 모두
                입력해 주세요.</Typography.Text>
        </Space>
    )
}

const EmptyImageLabel = () => (
    <Space
        style={{
            marginTop: 32,
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
        <Typography.Text
            style={{
                ...TextStyle,
                fontSize: 18,
            }}>
            사진/동영상을 등록해주세요.
        </Typography.Text>
    </Space>
)

const innerTopTabStyle: CSSProperties = {
    width: 58,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Color.y40,
    borderColor: Color.y40,
    borderRadius: 40,
    ...TextStyle,
    fontWeight: 500,
    fontSize: 16,
    color: Color.darkPrimary,
}

export default withPolicies(EditRoomDetailScreen)
