import {Col, Input, message, Modal, notification, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import React, {useEffect, useState} from "react";
import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {useDispatch, useSelector} from "react-redux";
import {requestAccommodations, requestRegisterPrice} from "../../../redux/Accommodation";
import {Navigate} from "react-router-dom";
import {PageRoutes} from "../../../App";
import {CommonStyle} from "../AccommodationScreen/style";
import RequiredFieldMark from "../../../components/RequiredFieldMark";
import RegisterButton from "../../../components/RegisterButton";
import {PlusOutlined} from "@ant-design/icons";
import NavigationService from "../../../services/NavigationService";
import {moneyValue} from "../../../utils/string";
import {onlyNumbersString} from "../../../utils/number";
import withPolicies from "../../../components/withPolicies";

const RegisterAccommodationPriceScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationStatus = useSelector(state => state.Accommodation.get('status'))

    const [priceName, setPriceName] = useState('')
    const [basicWeekdayPrice, setBasicWeekdayPrice] = useState('')
    const [basicWeekendPrice, setBasicWeekendPrice] = useState('')
    const [basicHolidayPrice, setBasicHolidayPrice] = useState('')

    const [highWeekdayPrice, setHighWeekdayPrice] = useState('')
    const [highWeekendPrice, setHighWeekendPrice] = useState('')
    const [highHolidayPrice, setHighHolidayPrice] = useState('')

    const [middleWeekdayPrice, setMiddleWeekdayPrice] = useState('')
    const [middleWeekendPrice, setMiddleWeekendPrice] = useState('')
    const [middleHolidayPrice, setMiddleHolidayPrice] = useState('')

    const [lowWeekdayPrice, setLowWeekdayPrice] = useState('')
    const [lowWeekendPrice, setLowWeekendPrice] = useState('')
    const [lowHolidayPrice, setLowHolidayPrice] = useState('')

    const [specialWeekdayPrice, setSpecialWeekdayPrice] = useState('')
    const [specialWeekendPrice, setSpecialWeekendPrice] = useState('')
    const [specialHolidayPrice, setSpecialHolidayPrice] = useState('')

    const [discountWeekdayPrice, setDiscountWeekdayPrice] = useState('')
    const [discountWeekendPrice, setDiscountWeekendPrice] = useState('')
    const [discountHolidayPrice, setDiscountHolidayPrice] = useState('')

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    if (accommodationStatus === 'PENDING') {
        return <Navigate to={PageRoutes.REGISTER_ACCOMMODATIONS}/>
    }

    const onPressRegisterButton = async () => {
        const prices = [
            {
                "priceName": priceName,
                "type": "BASIC",
                "weekday": onlyNumbersString(basicWeekdayPrice),
                "weekend": onlyNumbersString(basicWeekendPrice.replaceAll(",", "")),
                "holiday": onlyNumbersString(basicHolidayPrice.replaceAll(",", "")),
            },
            {
                "priceName": priceName,
                "type": "HIGH",
                "weekday": onlyNumbersString(highWeekdayPrice.replaceAll(",", "")),
                "weekend": onlyNumbersString(highWeekendPrice.replaceAll(",", "")),
                "holiday": onlyNumbersString(highHolidayPrice.replaceAll(",", "")),
            },
            {
                "priceName": priceName,
                "type": "MIDDLE",
                "weekday": onlyNumbersString(middleWeekdayPrice.replaceAll(",", "")),
                "weekend": onlyNumbersString(middleWeekendPrice.replaceAll(",", "")),
                "holiday": onlyNumbersString(middleHolidayPrice.replaceAll(",", "")),
            },
            {
                "priceName": priceName,
                "type": "LOW",
                "weekday": onlyNumbersString(lowWeekdayPrice.replaceAll(",", "")),
                "weekend": onlyNumbersString(lowWeekendPrice.replaceAll(",", "")),
                "holiday": onlyNumbersString(lowHolidayPrice.replaceAll(",", "")),
            },
            {
                "priceName": priceName,
                "type": "SPECIAL",
                "weekday": onlyNumbersString(specialWeekdayPrice.replaceAll(",", "")),
                "weekend": onlyNumbersString(specialWeekendPrice.replaceAll(",", "")),
                "holiday": onlyNumbersString(specialHolidayPrice.replaceAll(",", "")),
            },
            {
                "priceName": priceName,
                "type": "DISCOUNT",
                "weekday": onlyNumbersString(discountWeekdayPrice.replaceAll(",", "")),
                "weekend": onlyNumbersString(discountWeekendPrice.replaceAll(",", "")),
                "holiday": onlyNumbersString(discountHolidayPrice.replaceAll(",", "")),
            }
        ]

        // @ts-ignore
        const result = await dispatch(requestRegisterPrice(accommodationId, prices))

        if (result.success) {
            notification.success({
                message: '요금 추가에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        } else {
            modal.error({
                title: `요금 추가에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>요금 추가</Typography.Title>
                </Space>
                <Row>
                    <Space direction={"vertical"} style={{width: '95%', marginTop: 40}}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            요금 이름 <RequiredFieldMark/>
                        </Typography.Text>
                        <Input
                            style={CommonStyle.enabledInputStyle}
                            placeholder={'예시: 작은방, 큰방, A타입, B타입, 풀빌라, 애견가능룸'}
                            value={priceName}
                            onChange={e => setPriceName(e.target.value)}
                        />
                    </Space>
                </Row>

                <Row style={{marginTop: 40, width: '100%'}}>
                    <Typography.Text style={CommonStyle.labelStyle}>
                        시즌 별 요금
                    </Typography.Text>
                </Row>

                <SeasonPrice
                    label={'기본'}
                    weekdayPrice={basicWeekdayPrice}
                    weekendPrice={basicWeekendPrice}
                    holidayPrice={basicHolidayPrice}
                    onChangeWeekdayPrice={(value) => setBasicWeekdayPrice(value)}
                    onChangeWeekendPrice={(value) => setBasicWeekendPrice(value)}
                    onChangeHolidayPrice={(value) => setBasicHolidayPrice(value)}
                />
                <SeasonPrice
                    label={'성수기'}
                    weekdayPrice={highWeekdayPrice}
                    weekendPrice={highWeekendPrice}
                    holidayPrice={highHolidayPrice}
                    onChangeWeekdayPrice={(value) => setHighWeekdayPrice(value)}
                    onChangeWeekendPrice={(value) => setHighWeekendPrice(value)}
                    onChangeHolidayPrice={(value) => setHighHolidayPrice(value)}
                />
                <SeasonPrice
                    label={'준성수기'}
                    weekdayPrice={middleWeekdayPrice}
                    weekendPrice={middleWeekendPrice}
                    holidayPrice={middleHolidayPrice}
                    onChangeWeekdayPrice={(value) => setMiddleWeekdayPrice(value)}
                    onChangeWeekendPrice={(value) => setMiddleWeekendPrice(value)}
                    onChangeHolidayPrice={(value) => setMiddleHolidayPrice(value)}
                />
                <SeasonPrice
                    label={'비수기'}
                    weekdayPrice={lowWeekdayPrice}
                    weekendPrice={lowWeekendPrice}
                    holidayPrice={lowHolidayPrice}
                    onChangeWeekdayPrice={(value) => setLowWeekdayPrice(value)}
                    onChangeWeekendPrice={(value) => setLowWeekendPrice(value)}
                    onChangeHolidayPrice={(value) => setLowHolidayPrice(value)}
                />
                <SeasonPrice
                    label={'특별 요금 기간'}
                    weekdayPrice={specialWeekdayPrice}
                    weekendPrice={specialWeekendPrice}
                    holidayPrice={specialHolidayPrice}
                    onChangeWeekdayPrice={(value) => setSpecialWeekdayPrice(value)}
                    onChangeWeekendPrice={(value) => setSpecialWeekendPrice(value)}
                    onChangeHolidayPrice={(value) => setSpecialHolidayPrice(value)}
                />
                <SeasonPrice
                    label={'할인 요금 기간'}
                    weekdayPrice={discountWeekdayPrice}
                    weekendPrice={discountWeekendPrice}
                    holidayPrice={discountHolidayPrice}
                    onChangeWeekdayPrice={(value) => setDiscountWeekdayPrice(value)}
                    onChangeWeekendPrice={(value) => setDiscountWeekendPrice(value)}
                    onChangeHolidayPrice={(value) => setDiscountHolidayPrice(value)}
                />

                <Row style={CommonStyle.registerButtonContainerStyle}>
                    <RegisterButton
                        disabled={(
                            !priceName || !basicWeekdayPrice || !basicWeekendPrice || !basicHolidayPrice ||
                            !highWeekdayPrice || !highWeekendPrice || !highHolidayPrice ||
                            !middleWeekdayPrice || !middleWeekendPrice || !middleHolidayPrice ||
                            !lowWeekdayPrice || !lowWeekendPrice || !lowHolidayPrice ||
                            !specialWeekdayPrice || !specialWeekendPrice || !specialHolidayPrice ||
                            !discountWeekdayPrice || !discountWeekendPrice || !discountHolidayPrice)}
                        icon={<PlusOutlined/>}
                        label={'추가하기'}
                        onClick={onPressRegisterButton}
                    />
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const SeasonPrice = (
    {
        label,
        weekdayPrice,
        weekendPrice,
        holidayPrice,
        onChangeWeekdayPrice,
        onChangeWeekendPrice,
        onChangeHolidayPrice
    }: {
        label: string,
        weekdayPrice: string,
        weekendPrice: string,
        holidayPrice: string,
        onChangeWeekdayPrice: (price: string) => void,
        onChangeWeekendPrice: (price: string) => void,
        onChangeHolidayPrice: (price: string) => void,
    }) => {

    return (
        <Row style={{marginTop: 20, width: '100%'}}>
            <Col xs={24} xl={24}>
                <Typography.Title style={{...CommonStyle.labelStyle, fontSize: 16}}>
                    {label}
                </Typography.Title>
            </Col>
            <Col xs={24} xl={8} style={{marginTop: 6}}>
                <Space direction={"vertical"} style={{width: '95%', marginTop: 3}}>
                    <Typography.Text style={{
                        ...CommonStyle.labelStyle,
                        fontSize: 16,
                        color: Color.darkTertiary
                    }}>
                        주중 (일 ~ 목)
                    </Typography.Text>
                    <Input
                        style={{...CommonStyle.enabledInputStyle, marginTop: 3}}
                        placeholder={'0'}
                        value={moneyValue(weekdayPrice)}
                        onChange={e => onChangeWeekdayPrice(e.target.value)}
                    />
                </Space>
            </Col>
            <Col xs={24} xl={8} style={{marginTop: 6}}>
                <Space direction={"vertical"} style={{width: '95%', marginTop: 3}}>
                    <Typography.Text style={{
                        ...CommonStyle.labelStyle,
                        fontSize: 16,
                        color: Color.darkTertiary
                    }}>
                        주말 (금 ~ 토)
                    </Typography.Text>
                    <Input
                        style={{...CommonStyle.enabledInputStyle, marginTop: 3}}
                        placeholder={'0'}
                        value={moneyValue(weekendPrice)}
                        onChange={e => onChangeWeekendPrice(e.target.value)}
                    />
                </Space>
            </Col>
            <Col xs={24} xl={8} style={{marginTop: 6}}>
                <Space direction={"vertical"} style={{width: '95%', marginTop: 3}}>
                    <Typography.Text style={{
                        ...CommonStyle.labelStyle,
                        fontSize: 16,
                        color: Color.darkTertiary
                    }}>
                        공휴일 전
                    </Typography.Text>
                    <Input
                        style={{...CommonStyle.enabledInputStyle, marginTop: 3}}
                        placeholder={'0'}
                        value={moneyValue(holidayPrice)}
                        onChange={e => onChangeHolidayPrice(e.target.value)}
                    />
                </Space>
            </Col>
        </Row>
    )
}

export default withPolicies(RegisterAccommodationPriceScreen)
