// @ts-nocheck

import {Map} from 'immutable';
import AuthApi from "../../apis/AuthApi";

// action types

// action creators

// initialState
const initialState = Map({});

// reducer
const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        default:
            return state;
    }
};

export const requestLoginApi = (username: string, password: string) => async (dispatch: any, getState: any) => {
    try {
        const {response, json} = await AuthApi.login({username, password})

        if (response.ok) {
            return Promise.resolve({success: true, data: json?.data || {}});
        } else {
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        return Promise.resolve({success: false, error: e});
    }
}


export {
    reducer,
};