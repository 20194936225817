export type PagingAndSortingParam = {
    page: number;
    size: number;
    sortField?: string | null;
    sortOrder?: string | null;
};

export const pagingQuery = (param: PagingAndSortingParam) => {
    let queryParams = `?page=${param.page}&size=${param.size}`;

    if (param.sortField) {
        queryParams += `&sort=${param.sortField}`

        if (param.sortOrder) {
            queryParams += `,${param.sortOrder}`
        }
    }

    return queryParams;
}

