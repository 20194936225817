import React, {useEffect, useState} from "react";
import ScreenOuter from "../../../components/ScreenOuter";
import {useDispatch, useSelector} from "react-redux";
import {requestAccommodations, requestUpdateAccommodation} from "../../../redux/Accommodation";
import {Navigate} from "react-router-dom";
import ScreenGrid from "../../../components/ScreenGrid";
import {notification, Space, Typography} from "antd";
import {TextStyle} from "../../../constants/style";
import {TimeInfo} from "../AccommodationScreen";
import {PageRoutes} from "../../../App";
import NavigationService from "../../../services/NavigationService";
import withPolicies from "../../../components/withPolicies";

const EditAccommodationTimeScreen = () => {
    const dispatch = useDispatch()
    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))
    // @ts-ignore
    const accommodationStatus = useSelector(state => state.Accommodation.get('status'))
    // @ts-ignore
    const accommodationCheckInTime = useSelector(state => state.Accommodation.get('checkInTime'))
    // @ts-ignore
    const accommodationCheckOutTime = useSelector(state => state.Accommodation.get('checkOutTime'))

    // @ts-ignore
    const accommodationDescription = useSelector(state => state.Accommodation.get('description'))

    const [checkInTime, setCheckInTime] = useState('')
    const [checkOutTime, setCheckOutTime] = useState('')

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])


    useEffect(() => {
        setCheckInTime(accommodationCheckInTime)
        setCheckOutTime(accommodationCheckOutTime)
    }, [accommodationCheckInTime, accommodationCheckOutTime])

    if (accommodationStatus === 'PENDING') {
        return <Navigate to={PageRoutes.REGISTER_ACCOMMODATIONS}/>
    }

    const onPressUpdate = async () => {
        // @ts-ignore
        const result = await dispatch(requestUpdateAccommodation(
            accommodationId,
            accommodationName,
            accommodationDescription,
            checkInTime,
            checkOutTime))

        if (result) {
            notification.success({
                message: '입실/퇴실 수정에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>입실/퇴실 수정</Typography.Title>
                </Space>
                <TimeInfo
                    checkInTime={checkInTime}
                    checkOutTime={checkOutTime}
                    onChangeCheckInTime={dateString => setCheckInTime(dateString)}
                    onChangeCheckOutTime={dateString => setCheckOutTime(dateString)}
                    onPress={onPressUpdate}
                />
            </ScreenGrid>
        </ScreenOuter>
    )
}

export default withPolicies(EditAccommodationTimeScreen)
