import React, {useEffect, useState} from "react";

import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {Checkbox, Col, Empty, Image, Input, Modal, notification, Row, Space, Typography} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import NoBorderButton from "../../../components/NoBorderButton";
import {requestDeleteRoom, requestRoomDetail} from "../../../redux/Room";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import RoundTabs from "../../../components/Tabs";
import {FALLBACK_IMAGE} from "../../../constants/content";
import withPolicies from "../../../components/withPolicies";


const RoomDetailScreen = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modal, contextHolder] = Modal.useModal()
    const location = useLocation()
    const roomId = location?.state?.id

    const [enabledContentTabKey, setEnabledContentTabKey] = useState('PICTURE')

    // @ts-ignore
    const roomDetail = useSelector(state => state.Room.get('roomDetail'))

    useEffect(() => {
        // @ts-ignore
        dispatch(requestRoomDetail(roomId))
    }, [])

    const topButtonStyle = {
        backgroundColor: Color.r5, color: Color.r40, width: 66, height: 50, borderRadius: 16, fontWeight: 700,
    }

    const onPressDeleteRoom = async () => {
        modal.confirm({
            title: '객실을 삭제하시겠습니까?',
            okText: '삭제',
            cancelText: '취소',
            onOk: deleteRoom,
        })
    }

    const deleteRoom = async () => {
        // @ts-ignore
        const result = await dispatch(requestDeleteRoom(roomId))

        if (result.success) {
            notification.success({
                message: '객실 삭제에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ROOMS),
            })
        } else {
            modal.error({
                title: '객실을 삭제할 수 없습니다.',
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    const onPressUpdateRoom = () => {
        navigate(PageRoutes.EDIT_ROOM_DETAIL, {
            state: {
                ...roomDetail,
            }
        })
    }

    return (
        <ScreenOuter>
            <ScreenGrid>
                <Header/>
                <Row style={{marginTop: 40}}>
                    <Col xs={24} xl={24}>
                        <Space style={{position: 'absolute', right: 0, alignItems: 'center', justifyItems: 'center'}}>
                            <NoBorderButton style={topButtonStyle} value={'삭제'} onClick={onPressDeleteRoom}/>
                            <NoBorderButton style={{...topButtonStyle, color: Color.darkPrimary}} value={'수정'}
                                            onClick={onPressUpdateRoom}/>
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Col xs={12} xl={12}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={{...CommonStyle.labelStyle, color: Color.darkTertiary}}>
                                객실 이름
                            </Typography.Text>
                            <Typography.Text style={{...TextStyle, fontSize: 18, fontWeight: 500}}>
                                {roomDetail?.name}
                            </Typography.Text>
                        </Space>
                    </Col>
                    <Col xs={12} xl={12}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={{...CommonStyle.labelStyle, color: Color.darkTertiary}}>
                                객실 타입
                            </Typography.Text>
                            <NoBorderButton
                                style={{...TextStyle, color: Color.b40, fontSize: 18, fontWeight: 500}}
                                value={roomDetail?.roomTypeName}
                                onClick={() => navigate(PageRoutes.dynamicPath('/rooms/room-types', roomDetail?.roomTypeId?.toString()),
                                    {state: {id: roomDetail?.roomTypeId}})}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <RoundTabs
                        tabs={[{key: 'PICTURE', label: '사진'}, {key: 'MOVIE', label: '동영상'}]}
                        enabledTabKey={enabledContentTabKey}
                        onChangeTab={(key) => {
                            if (key === 'MOVIE') {
                                modal.info({title: '동영상 업로드 기능은 곧 제공될 예정입니다.'})
                                return
                            }

                            setEnabledContentTabKey(key)
                        }}
                    />
                    <Col xs={24} xl={24}>
                        {
                            roomDetail?.roomContents?.length > 0 ?
                                (
                                    <Space style={{
                                        maxWidth: '100%',
                                        overflowX: 'auto',
                                        overflowY: 'hidden',
                                        marginTop: 16
                                    }}>
                                        {roomDetail?.roomContents?.map((roomContent: any, index: number) => {
                                            return roomContent.type === 'IMAGE' ? (
                                                <Image
                                                    key={index}
                                                    preview
                                                    src={roomContent.url}
                                                    width={174}
                                                    height={120}
                                                    style={{borderRadius: 8, flexShrink: 0}}
                                                    fallback={FALLBACK_IMAGE}
                                                />
                                            ) : (
                                                // TODO: 동영상
                                                <></>
                                            )
                                        })}
                                    </Space>
                                ) : (
                                    <Space
                                        style={{
                                            marginTop: 32,
                                            marginBottom: 32,
                                            width: '100%',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                        }}>
                                        <Empty description={'등록된 사진/동영상이 없습니다.'}/>
                                    </Space>
                                )
                        }
                    </Col>
                </Row>
                <Row style={{marginTop: 40}}>
                    <Checkbox checked={roomDetail?.isDisplay} value={''}>
                        <Typography.Text style={{...TextStyle, fontSize: 16, fontWeight: 500}}>
                            노출 여부 {roomDetail?.isDisplay ? '(현재 고객 사이트에 노출되고 있습니다.)' : '(현재 고객 사이트에 노출되지 않고 있습니다.)'}
                        </Typography.Text>
                    </Checkbox>
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

const Header = ({}) => {
    return (
        <Space direction={'vertical'} style={{width: '100%', justifyContent: 'space-between'}}>
            <Typography.Title level={2} style={{...TextStyle}}>객실 상세</Typography.Title>
        </Space>
    )
}

export default withPolicies(RoomDetailScreen)
