// @ts-nocheck

import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {Spin} from "antd";
import {Navigate, useNavigate} from "react-router-dom";

import {PageRoutes} from "../App";
import CookieService from "../services/CookieService";
import {requestUserMeApi} from "../redux/User";

type ComponentProps = {};

function hasAccessToken() {
    const accessToken = CookieService.getAccessToken();
    return !!accessToken;
}

const withPolicies = <P extends ComponentProps>(WrappedComponent: React.ComponentType<P>) => {
    return (props: JSX.IntrinsicAttributes) => {
        const dispatch = useDispatch()
        const navigate = useNavigate()

        const [isLoading, setIsLoading] = useState(true);
        const [accommodationStatus, setAccommodationStatus] = useState(null)


        useEffect(() => {
            requestUserMe()
        }, [])

        async function requestUserMe() {
            const {success, user, error} = await dispatch(requestUserMeApi())

            if (success) {
                setAccommodationStatus(user?.accommodationStatus || null);
            }

            setIsLoading(false)
        }

        if (!hasAccessToken()) {
            navigate(PageRoutes.LOGIN)
            return null;
        }

        if (isLoading) {
            return <Spin style={{alignSelf: 'center'}}/>
        }

        if (!accommodationStatus || accommodationStatus === 'PENDING') {
            return <Navigate to={PageRoutes.REGISTER_ACCOMMODATIONS}/>
        }

        // @ts-ignore
        return <WrappedComponent {...props}/>
    }
}

export default withPolicies
