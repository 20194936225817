import {Checkbox, Col, Row, Space} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {isMobile} from "react-device-detect";
import TextLiner from "../../../components/TextLiner";

interface PaidFacility {
    id: number,
    name: string,
}

const AccommodationPaidFacility = ({selectedPaidFacilityIds, paidFacilities}: {
    selectedPaidFacilityIds: number[],
    paidFacilities: PaidFacility[],
}) => {
    return (
        <Space direction={'vertical'} style={{marginTop: 12}}>
            <Row style={{width: '100%'}}>
                <Col xs={24} xl={24}>
                    {paidFacilities.map(facility => {
                        const checked = selectedPaidFacilityIds.includes(facility.id)
                        return (
                            <Checkbox
                                disabled
                                key={facility.id}
                                checked={checked}
                                style={{
                                    width: isMobile ? 140 : 200,
                                    marginLeft: 0,
                                    marginRight: 20,
                                    justifyContent: 'flex-start',
                                    overflow: 'visible',
                                    ...TextStyle,
                                }}
                            >
                                <TextLiner
                                    textStyle={{...TextStyle, fontSize: 16, fontWeight: 500, color: Color.darkTertiary}}
                                    enabled={checked} value={facility.name}/>
                            </Checkbox>
                        )
                    })}
                </Col>
            </Row>
        </Space>
    )
}

export default AccommodationPaidFacility
