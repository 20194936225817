// @ts-nocheck

import {Map} from 'immutable';
import {SET_IS_LOADING_RESERVATION, SET_IS_LOADING_ROOM, setIsLoading} from "../Loading";
import ReservationApi from "../../apis/ReservationApi";
import {PagingAndSortingParam} from "../../apis/ApiUtil";

// action types
export const SAVE_RESERVATIONS = 'Reservation/SAVE_RESERVATIONS'

// action creators
export const saveReservations = (totalPages: number, totalElements: number, currentPage: number, reservations: any[]) => ({
    type: SAVE_RESERVATIONS,
    payload: {
        totalPages,
        totalElements,
        currentPage,
        reservations,
    }
})

// initialState
const initialState = Map({
    reservations: [],
    reservationTotalPages: 0,
    reservationTotalElements: 0,
    reservationCurrentPage: 0,
});

// reducer
export const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        case SAVE_RESERVATIONS:
            return state.set('reservations', action.payload.reservations)
                .set('reservationTotalPages', action.payload.totalPages)
                .set('reservationTotalElements', action.payload.totalElements)
                .set('reservationCurrentPage', action.payload.currentPage)
        default:
            return state;
    }
};


export interface SearchReservationParam {
    startDate: string;
    endDate: string;
    createdDate: string;
    nameOrPhoneNumber: string;
}

export const requestReservations = (pagingAndSortingParam: PagingAndSortingParam, param: SearchReservationParam) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, true))

    try {
        const {response, json} = await ReservationApi.search(pagingAndSortingParam, param)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            dispatch(saveReservations(json?.data?.totalPages, json?.data?.totalElements, json?.data?.number, json?.data?.content || []))
            return Promise.resolve({success: true});
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
        return Promise.resolve({success: false, error: e});
    }
}

export const requestReservationDetail = (reservationId: number) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, true))

    try {
        const {response, json} = await ReservationApi.searchDetail(reservationId)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: true, reservation: json?.data || {}});
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: false, error: json?.error});
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
        return Promise.resolve({success: false, error: e});
    }
}

export const requestChangePaymentStatus = (reservationId: number, paymentStatus: string) => async (dispatch: any, getState: any) => {
    dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, true))

    try {
        const {response, json} = await ReservationApi.changePaymentStatus(reservationId, paymentStatus)

        if (response.ok) {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: true})
        } else {
            dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
        return Promise.resolve({success: false, error: e});
    }
}

export const requestMonthlyStatistics = (startDate: string, endDate: string) => async (dispatch, getState) => {
    // dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, true))

    try {
        const {response, json} = await ReservationApi.getMonthlyStatistics(startDate, endDate)

        if (response.ok) {
            // dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: true, monthlyStatistics: json?.data})
        } else {
            // dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
            return Promise.resolve({success: false, error: json?.error})
        }
    } catch (e) {
        // dispatch(setIsLoading(SET_IS_LOADING_RESERVATION, false))
        return Promise.resolve({success: false, error: e});
    }
}

