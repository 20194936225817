// @ts-nocheck
import React, {useEffect, useRef, useState} from "react";
import {requestLoginApi} from "../../../redux/Auth";
import CookieService from "../../../services/CookieService";
import NavigationService from "../../../services/NavigationService";
import {useDispatch} from "react-redux";
import {Col, Input, Row, Space, Typography, Button, Modal, Form, Image} from "antd";
import {Color, TextStyle} from "../../../constants/style";
import {CommonStyle} from "../../Accommodation/AccommodationScreen/style";
import {useNavigate} from "react-router-dom";
import {PageRoutes} from "../../../App";
import NoBorderButton from "../../../components/NoBorderButton";

const LoginScreen = ({}) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [modal, contextHolder] = Modal.useModal()

    const [username, setUsername] = useState(null)
    const [password, setPassword] = useState(null)

    const [form] = Form.useForm();
    const usernameInputRef = useRef(null);
    const passwordInputRef = useRef(null);

    const requestLogin = async () => {
        const result = await dispatch(requestLoginApi(username, password))
        if (result.success) {
            CookieService.setAccessToken(result.data.token)
            CookieService.setRefreshToken(result.data.refreshToken)

            navigate(PageRoutes.RESERVATIONS)
        } else {
            modal.error({
                title: '로그인에 실패했습니다.',
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    const handleInputErrors = (errorFields: any[]) => {
        if (errorFields.length > 0) {
            const firstErrorField = errorFields[0].name[0];
            if (firstErrorField === 'username') {
                usernameInputRef?.current?.focus()
            } else if (firstErrorField === 'password') {
                passwordInputRef?.current?.focus()
            }
        }
    }

    const setInputField = (type: 'username' | 'password', value) => {
        if (type === 'username') {
            setUsername(value)
        } else {
            setPassword(value)
        }
    }

    return (
        <LoginCenterGrid>
            <Space direction={'vertical'}
                   style={{paddingBottom: 30, width: '100%', alignItems: 'center', justifyContent: 'center'}}>
                <Typography.Text style={{...TextStyle, fontSize: 36, fontWeight: 'bold'}}>Staytwice</Typography.Text>

                <Form
                    form={form}
                    style={{width: 300, justifyContent: 'center', gap: 0}}
                    onFinishFailed={(errorInfo) => handleInputErrors(errorInfo.errorFields)}
                    onFinish={requestLogin}
                >
                    <Form.Item name={"username"} style={{width: 300, height: 55, gap: 0}}
                               rules={[{required: true, message: <ErrorMessage message={'아이디를 입력해주세요.'}/>}]}>
                        <Input
                            ref={usernameInputRef}
                            placeholder={'아이디'}
                            value={username}
                            maxLength={32}
                            style={{...CommonStyle.enabledInputStyle, width: 300}}
                            onChange={(e) => setInputField('username', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item name={"password"} style={{width: 300, height: 55, gap: 0}}
                               rules={[{required: true, message: <ErrorMessage message={'비밀번호를 입력해주세요.'}/>}]}>
                        <Input
                            ref={passwordInputRef}
                            placeholder={'비밀번호'}
                            type={'password'}
                            value={password}
                            maxLength={32}
                            style={{...CommonStyle.enabledInputStyle, width: 300, marginTop: 5}}
                            onChange={(e) => setInputField('password', e.target.value)}
                        />
                    </Form.Item>
                    <Form.Item name={"button"}>
                        <Button
                            htmlType={"submit"}
                            style={{
                                width: 300,
                                height: 50,
                                borderRadius: 16,
                                backgroundColor: Color.y40,
                                ...TextStyle,
                                fontSize: 16,
                                fontWeight: 'bold',
                                border: 'none',
                                margin: 0,
                                padding: 0,
                                boxShadow: 'none',
                            }}
                        >이메일로 로그인하기</Button>
                    </Form.Item>
                </Form>

                <NoBorderButton
                    value={'네이버로 시작하기'}
                    style={{
                        marginTop: 40,
                        width: 300,
                        height: 50,
                        borderRadius: 16,
                        backgroundColor: '#03C75A',
                        ...TextStyle,
                        fontSize: 16,
                        fontWeight: 'bold',
                        color: Color.white,
                    }}
                    onClick={() => NavigationService.moveToNaverLoginPage()}
                />
                <NoBorderButton
                    value={'카카오로 시작하기'}
                    style={{
                        width: 300,
                        height: 50,
                        borderRadius: 16,
                        backgroundColor: '#FEE500',
                        ...TextStyle,
                        fontSize: 16,
                        fontWeight: 'bold',
                    }}
                    onClick={() => NavigationService.moveToKakaoLoginPage()}
                />
            </Space>
            {contextHolder}
        </LoginCenterGrid>
    )
}

const LoginCenterGrid = ({...props}) => {
    return (
        <div style={{maxWidth: '100%', overflow: 'hidden', width: '100%', height: '100%'}}>
            <Row style={{marginTop: 80}}>
                <Col xs={4} xl={8}></Col>
                <Col xs={16} xl={8}>
                    {props.children}
                </Col>
                <Col xs={4} xl={8}></Col>
            </Row>
        </div>
    )
}

const ErrorMessage = ({message}: { message: string }) => {
    return (
        <Typography.Text style={{
            ...TextStyle,
            marginLeft: 5,
            fontSize: 14,
            fontWeight: '700',
            color: Color.r40
        }}>{message}</Typography.Text>
    )
}

export default LoginScreen;
