import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {requestAccommodations, requestRegisterPaidFacility, requestRegisterPrice} from "../../../redux/Accommodation";
import ScreenOuter from "../../../components/ScreenOuter";
import ScreenGrid from "../../../components/ScreenGrid";
import {Col, Input, message, Modal, notification, Radio, Row, Space, Typography} from "antd";
import {TextStyle} from "../../../constants/style";
import {CommonStyle} from "../AccommodationScreen/style";
import RequiredFieldMark from "../../../components/RequiredFieldMark";
import RegisterButton from "../../../components/RegisterButton";
import {PlusOutlined} from "@ant-design/icons";
import {moneyValue} from "../../../utils/string";
import NavigationService from "../../../services/NavigationService";
import {PageRoutes} from "../../../App";
import withPolicies from "../../../components/withPolicies";

const RegisterAccommodationPaidFacilityScreen = () => {
    const dispatch = useDispatch()
    const [modal, contextHolder] = Modal.useModal()

    // @ts-ignore
    const accommodationId = useSelector(state => state.Accommodation.get('id'))
    // @ts-ignore
    const accommodationName = useSelector(state => state.Accommodation.get('name'))

    useEffect(() => {
        // @ts-ignore
        dispatch(requestAccommodations())
    }, [])

    const [name, setName] = useState('')
    const [price, setPrice] = useState('')
    const [description, setDescription] = useState('')
    const [isMultiple, setIsMultiple] = useState(null)
    const [isDisplay, setIsDisplay] = useState(false)

    const onPressRegisterButton = async () => {
        const result = await dispatch(
            // @ts-ignore
            requestRegisterPaidFacility(
                accommodationId,
                name,
                description,
                price.replaceAll(",", ""),
                !!isMultiple,
                isDisplay))

        if (result.success) {
            notification.success({
                message: '유료 이용 시설 추가에 성공했습니다!',
                duration: 1,
                onClose: () => NavigationService.moveTo(PageRoutes.ACCOMMODATIONS),
            })
        } else {
            modal.error({
                title: `유료 이용 시설 추가에 실패했습니다.`,
                content: `${result?.error?.message || ''}`,
                okText: '확인',
            })
        }
    }

    return (
        <ScreenOuter accommodationName={accommodationName}>
            <ScreenGrid>
                <Space style={{width: '100%'}}>
                    <Typography.Title level={2} style={{...TextStyle}}>유료 시설 추가</Typography.Title>
                </Space>
                <Row>
                    <Col xs={24} xl={11}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                시설 이름 <RequiredFieldMark/>
                            </Typography.Text>
                            <Input
                                style={CommonStyle.enabledInputStyle}
                                placeholder={'바베큐, 수영장 등'}
                                value={name}
                                onChange={e => setName(e.target.value)}
                            />
                        </Space>
                    </Col>
                    <Col xs={0} xl={2}/>
                    <Col xs={24} xl={11}>
                        <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                            <Typography.Text style={CommonStyle.labelStyle}>
                                가격 <RequiredFieldMark/>
                            </Typography.Text>
                            <Input
                                style={CommonStyle.enabledInputStyle}
                                placeholder={'30,000'}
                                value={moneyValue(price)}
                                onChange={e => setPrice(e.target.value)}
                            />
                        </Space>
                    </Col>
                </Row>
                <Row>
                    <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            시설 설명 <RequiredFieldMark/>
                        </Typography.Text>
                        <Input.TextArea
                            autoSize
                            style={{...CommonStyle.enabledInputStyle, minHeight: 120}}
                            placeholder={'시설 구성 요소 또는 유의사항\n' +
                                '(예시. 인원수에 맞춰 수량 입력하세요. / 오후 10시까지 이용 가능합니다. / ...)'}
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </Space>
                </Row>
                <Row>
                    <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            2개 이상 신청(구매) 가능한가요? <RequiredFieldMark/>
                        </Typography.Text>
                        <Radio.Group onChange={e => setIsMultiple(e.target.value)} value={isMultiple}>
                            <Radio
                                style={{...TextStyle, fontSize: 18, fontWeight: 500}}
                                value={true}
                            >예</Radio>
                            <Radio
                                style={{...TextStyle, fontSize: 18, fontWeight: 500}}
                                value={false}
                            >아니오</Radio>
                        </Radio.Group>
                    </Space>
                </Row>
                <Row>
                    <Space direction={"vertical"} style={{width: '100%', marginTop: 40}}>
                        <Typography.Text style={CommonStyle.labelStyle}>
                            예약 시 신청(구매) 가능하도록 고객 사이트에 노출할까요?
                        </Typography.Text>
                        <Radio.Group onChange={e => setIsDisplay(e.target.value)} value={isDisplay}>
                            <Radio
                                style={{...TextStyle, fontSize: 18, fontWeight: 500}}
                                value={true}
                            >예</Radio>
                            <Radio
                                style={{...TextStyle, fontSize: 18, fontWeight: 500}}
                                value={false}
                            >아니오</Radio>
                        </Radio.Group>
                    </Space>
                </Row>
                <Row style={CommonStyle.registerButtonContainerStyle}>
                    <RegisterButton
                        disabled={(!name || !price || !description || (isMultiple === null))}
                        icon={<PlusOutlined/>}
                        label={'추가하기'}
                        onClick={onPressRegisterButton}
                    />
                </Row>
                {contextHolder}
            </ScreenGrid>
        </ScreenOuter>
    )
}

export default withPolicies(RegisterAccommodationPaidFacilityScreen)
