import ApiService from "../../services/ApiService";
import {PagingAndSortingParam, pagingQuery} from "../ApiUtil";
import {SearchReservationParam} from "../../redux/Reservation";
import dayjs from "dayjs";


class ReservationApi {
    static search(pagingAndSortingParam: PagingAndSortingParam, param: SearchReservationParam) {
        const queryParams = pagingQuery(pagingAndSortingParam)
        let path = `/reservations${queryParams}`;
        if (param.startDate) {
            path += `&startDate=${dayjs(param.startDate).format('YYYY-MM-DD')}`
        }
        if (param.endDate) {
            path += `&endDate=${dayjs(param.endDate).format('YYYY-MM-DD')}`
        }
        if (param.createdDate) {
            path += `&createdDate=${dayjs(param.createdDate).format('YYYY-MM-DD')}`
        }
        if (param.nameOrPhoneNumber) {
            path += `&nameOrPhoneNumber=${param.nameOrPhoneNumber}`;
        }
        return ApiService.get(path, true);
    }

    static searchDetail(id: number) {
        const path = `/reservations/${id}`
        return ApiService.get(path, true)
    }

    static changePaymentStatus(reservationId: number, paymentStatus: string) {
        const path = `/reservations/${reservationId}/change-payment-status`
        const body = {
            "paymentStatus": paymentStatus,
        }
        return ApiService.post(path, body, true)
    }

    static getMonthlyStatistics(startDate: string, endDate: string) {
        const path = `/reservations/monthly-statistics?startDate=${startDate}&endDate=${endDate}`
        return ApiService.get(path, true)
    }
}

export default ReservationApi
