import {Map} from 'immutable';
import ContentApi from "../../apis/ContentApi";
import NavigationService from "../../services/NavigationService";
import {message} from "antd";

// action types

// action creators

// initialState
const initialState = Map({});

// reducer
const reducer = (
    state: any = initialState,
    action: any,
): any => {
    switch (action.type) {
        default:
            return state;
    }
};

export type PresignedImageType = 'BIZ_REGISTRATION_FILE' | 'ACCOMMODATION_IMAGES'

const requestPreSignedUrl = (fileType: PresignedImageType, fileName: string) => async (dispatch: any, getState: any) => {
    try {
        const {response, json} = await ContentApi.preSignedUrl(fileType, fileName)

        if (response.ok) {
            return Promise.resolve(json.data);
        } else if (response.status === 401) {
            // TODO: Custom Global Modal Error 로 변경
            // eslint-disable-next-line no-restricted-globals
            confirm(json.error.message)
            NavigationService.moveToLoginPage()
            return Promise.reject()
        } else if (response.status === 422) {
            message.error(json && json.error && json.error.message)
            return Promise.reject()
        }
    } catch (e) {
        console.error(e)
        return Promise.reject()
    }
}


export {
    reducer,
    requestPreSignedUrl,
};